import { Link } from "react-router-dom";
import styles from "./style.module.scss";
import PropTypes from "prop-types";
import React from "react";
function HomeCard({
  link,
  title,
  description,
  image,
  index,
  setActiveCard,
  activeCard,
}) {
  return (
    <Link
      onMouseOver={() => setActiveCard(index)}
      to={link}
      className={styles.homeCard}
    >
      <div className={styles.cardContent}>
        <div className={styles.imageContent}>
          <img src={image} alt="card visual" />
          <div className={styles.titleWrapper}>
            <h3 className={`${activeCard === index ? styles.bold : ""}`}>
              {title}
            </h3>
          </div>
        </div>
        <p
          className={`${styles.text} ${activeCard === index ? styles.active : ""}`}
        >
          {description}
        </p>
      </div>
    </Link>
  );
}
export default HomeCard;
HomeCard.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  index: PropTypes.number,
  setActiveCard: PropTypes.func,
  activeCard: PropTypes.number,
};
