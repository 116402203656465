import React, {useEffect} from "react";
import PropTypes from "prop-types";
import TextArea from "components/ui/atoms/Inputs/Textarea";
import Input from "components/ui/atoms/Inputs/Input";
import SpecialRegionInput from "components/ui/atoms/Inputs/SpecialRegionInput";
import styles from "./style.module.scss";

export default function ProfileData({
  editing,
  changes,
  setChanges,
  setSubmittableData,
}) {

  const dateConverter = date => {
    try {
      const newDate = new Date(date);
      const year = newDate.getFullYear();
      const month = newDate.getMonth() + 1;
      const day = newDate.getDate();
      return `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
    } catch {
      return null;
    }
  };

  useEffect(() => {
    setSubmittableData({
      about: changes.about,
      area_of_focus: changes.area_of_focus,
      location: changes.location,
      birthday: changes.birthday,
      date_joined: changes.date_joined,
      show_room_link: changes.show_room_link,
      cover_page: changes.cover_page,
      email: changes.email,
      phone: changes.phone
    });
  }, [changes.about, changes.area_of_focus, changes.birthday, changes.cover_page, changes.date_joined, changes.email, changes.location, changes.phone, changes.show_room_link, setSubmittableData]);
  const focusAreaPlaceholder =
    "Frontend Development\nBackend Development\nFullstack Development";
  return (
    <div className={styles.mainContainer}>
      <div className={styles.elementContainer}>
        <TextArea
          label={"About"}
          placeholder={"Tell us about yourself"}
          required
          value={changes.about}
          onChange={e => setChanges({ ...changes, about: e.target.value })}
          disabled={!editing}
        />
      </div>

      <div className={styles.elementContainer}>
        <h3>BIO</h3>
        <p>Some information why we are collecting this information</p>
        <TextArea
          label={"Focus Area"}
          placeholder={focusAreaPlaceholder}
          required
          value={changes.area_of_focus}
          onChange={e => setChanges({ ...changes, area_of_focus: e.target.value })}
          disabled={!editing}
        />
        <SpecialRegionInput
          label={"Location"}
          required
          country={changes.country || ""}
          value={changes.location || ""}
          onChange={value => setChanges({ ...changes, location: value })}
          disabled={!editing}
        />
        <Input
          label="Birthday"
          type="date"
          required
          onChange={e => setChanges({ ...changes, birthday: e.target.value })}
          value={dateConverter(changes.birthday)}
          disabled={!editing}
        />
        <Input
          label="Date joined"
          type="date"
          required
          value={dateConverter(changes.date_joined)}
          disabled={true}
        />
      </div>

      <div className={`${styles.elementContainer} ${styles.contacts}`}>
        <h3>Your CONTACTS</h3>
        <p>Some information why we are collecting this information</p>
        <Input
          label="Show Room Link"
          placeholder={"url"}
          required={true}
          value={changes.show_room_link}
          onChange={e => setChanges({ ...changes, show_room_link: e.target.value })}
          disabled={!editing}
        />
        <Input
          label="Cover Page"
          placeholder={"url"}
          required={true}
          value={changes.cover_page}
          onChange={e => setChanges({ ...changes, cover_page: e.target.value })}
          disabled={!editing}
        />
        <Input
          label="Contact Email"
          placeholder={"Email"}
          required={true}
          value={changes.email}
          onChange={e => setChanges({ ...changes, email: e.target.value })}
          disabled={!editing}
        />
        <Input
          label="Phone Number"
          placeholder={"+123456789"}
          required={true}
          value={changes.phone}
          onChange={e => setChanges({ ...changes, phone: e.target.value })}
          disabled={!editing}
        />
      </div>
    </div>
  );
}

ProfileData.propTypes = {
  editing: PropTypes.bool,
  changes: PropTypes.any,
  setChanges: PropTypes.func,
  setSubmittableData: PropTypes.func,
};
