import Applicant from "components/ui/molecules/Applicant";
import React from "react";

export default function ApplicantPage() {
  return (
    <div>
      <Applicant />
    </div>
  );
}
