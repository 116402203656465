import React from "react";
import styles from "./style.module.scss";
import { NavLink } from "react-router-dom";
import {ReactComponent as IconTwo} from "../../../Assets/icontwo.svg";
import {ReactComponent as IconOne} from "../../../Assets/iconone.svg";


export default function index() {
  return (
    <div className={styles.SidebarFixedContainer}>
      <p className={styles.settingHeader}>Settings</p>
      <ul className={styles.linkList}>
      <NavLink to="" end className={(link) => (link.isActive ? `${styles.link} ${styles.active}` : styles.link)}>
        <IconOne />
        <p>Profile</p>
      </NavLink>
      <NavLink to="account" className={(link) => (link.isActive ? `${styles.link} ${styles.active}` : styles.link)}>
        <IconOne />
        <p>Account</p>
      </NavLink>
      <NavLink to="email-preferances" className={(link) => (link.isActive ? `${styles.link} ${styles.active}` : styles.link)}>
        <IconOne />
        <p>Email Preferances</p>
      </NavLink>
      <NavLink to="contact" className={(link) => (link.isActive ? `${styles.link} ${styles.active}` : styles.link)}>
        <IconOne />
        <p>Contact</p>
      </NavLink>
      <NavLink to="notifications" className={(link) => (link.isActive ? `${styles.link} ${styles.active}` : styles.link)}>
        <IconTwo />
        <p>Notifications</p>
      </NavLink>
      <NavLink to="billings" className={(link) => (link.isActive ? `${styles.link} ${styles.active}` : styles.link)}>
        <IconTwo />
        <p>Billing & Plans</p>
      </NavLink>
      <NavLink to="meetings" className={(link) => (link.isActive ? `${styles.link} ${styles.active}` : styles.link)}>
        <IconTwo />
        <p>Meetings</p>
      </NavLink>
      </ul>
    </div>
  );
}
