import React from "react";
import styles from "./style.module.scss";
import { TailSpin } from  "react-loader-spinner";

export function Loader(){
    return(
        <div className={styles.wrapper}>
            <TailSpin color="#EC7500" height={80} width={80} />
        </div>
    );
}

export default Loader;
