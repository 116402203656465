import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import GridView from "../GridView";
import TableView from "../TableView";
import { ReactComponent as BackButton } from "../../../../assets/images/backButton.svg";
import { useNavigate } from "react-router-dom";
import { getSharedWithMeVideos } from "../../../../redux/actions/videoActions";
import Loader from "../../atoms/Loader";
import NothingToDisplay from "../../atoms/NothingToDisplay";
// import Pagination from "../../atoms/Pagination";
import { networkErrorHandler } from "../../../helpers/networkErrorHandler";
import { selectSharedWithMeVideos } from "redux/selectors";
const Items = ({ type }) => {
  // const LIMIT = 10;
  const { isFetching } = useSelector(state => state?.video);
  const sharedWithMe = useSelector(selectSharedWithMeVideos);
  const [data, setData] = useState(null);
  const [filterArray, setFilterArray] = useState([]);
  const [asc, setAsc] = useState(false);
  const [parent, setParent] = useState(0);
  const [history, setHistory] = useState([0]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, message } = useSelector(state => state.video);

  useEffect(() => {
    dispatch(getSharedWithMeVideos());
    networkErrorHandler(error, message);
  }, []);

  const filterParentData = tableData => {
    let filter = tableData
      .map(item => ({ ...item, parent: 0 }))
      .filter(a => {
        return a.parent === parent;
      })
      .sort((a, b) => {
        const isReversed = -1;
        return isReversed * a.type.localeCompare(b.type);
      });
    setFilterArray(filter);
  };

  useEffect(() => {
    if (!isFetching && sharedWithMe && sharedWithMe.length) {
      filterParentData(sharedWithMe);
    }
  }, [parent, sharedWithMe]);

  const sortAtoZ = type => {
    let sortedArray = [...filterArray];
    setAsc(!asc);
    sortedArray.sort((a, b) => {
      const isReversed = asc ? -1 : 1;
      return isReversed * a[type].localeCompare(b[type]);
    });
    setFilterArray(sortedArray);
  };

  const filterHandler = type => {
    switch (type) {
      case "title":
      case "creeatedAt":
      case "author":
        sortAtoZ(type);
        break;
      case "size":
        let sizeArray = [...filterArray];
        setAsc(!asc);
        sizeArray.sort((a, b) => {
          if (a.size > b.size) return asc ? -1 : 1;
          if (a.size < b.size) return asc ? 1 : -1;
          return 0;
        });
        setFilterArray(sizeArray);
        break;
      default:
        return filterArray;
    }
  };

  const setPathFromChild = (index, type) => {
    if (type === "folder") {
      setParent(index);
      let historyArray = [...history, index];
      setHistory(historyArray);
    } else {
      navigate(`/video-player/${index}`);
    }
  };

  const backHandler = () => {
    history.pop();
    setParent(history[history.length - 1]);
  };

  const handleMoveToFolder = result => {
    const newArray = [...data];

    const arrayToSave = newArray.map((item, index) => {
      if (item.index === parseInt(result.draggableId)) {
        return {
          ...item,
          parent: parseInt(result.destination.droppableId),
        };
      } else {
        return item;
      }
    });

    setData(arrayToSave);

    filterParentData(arrayToSave);
  };

  return (
    <Fragment>
      {isFetching ? (
        <Loader />
      ) : (
        !isFetching &&
        sharedWithMe &&
        (sharedWithMe.length > 0 ? (
          <>
            {type === "row" ? (
              <TableView
                filterHandler={filterHandler}
                list={filterArray}
                pathHandler={setPathFromChild}
                moveToFolder={handleMoveToFolder}
                showContextMenu={false}
              />
            ) : (
              <GridView
                list={filterArray}
                pathHandler={setPathFromChild}
                showContextMenu={false}
              />
            )}
          </>
        ) : (
          <NothingToDisplay />
        ))
      )}
      {/* TODO: add pagination when backend has implementation */}
      {/* {filterArray.length > 0 && !isFetching ? (
        <Pagination httpRequest={getSharedWithMeVideos} limit={LIMIT} />
      ) : null} */}
    </Fragment>
  );
};

export default Items;
Items.propTypes = {
  type: PropTypes.string,
};
