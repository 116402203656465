import { GET_POLLS, GET_POLL } from "../actions/actionTypes";

const INITIAL_DATA = {
  data: [],
  totalPolls: 0,
  page: 1,
  perPage: 10,
  poll: null,
  loading: false,
  error: null,
};

const polls = (state = INITIAL_DATA, { type, payload }) => {
  switch (type) {
    case GET_POLLS.INIT:
      return { ...state, loading: true };

    case GET_POLLS.SUCCESS:
      return { ...state, ...payload, loading: false };

    case GET_POLLS.ERROR:
      return { ...state, error: payload, loading: false };

    case GET_POLL.INIT:
      return { ...state, loading: true };

    case GET_POLL.SUCCESS:
      return { ...state, poll: payload, loading: false };

    case GET_POLL.ERROR:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export default polls;
