import styles from "./style.module.scss";
import PropTypes from "prop-types";

import React, { useState, useRef, Fragment } from "react";
import {
  startDeleteComment,
  startEditComment,
} from "../../../../redux/actions/commentsActions";
import { useDispatch, useSelector } from "react-redux";
import DeletePopup from "components/ui/organisms/DeletePopup";
import RenamePopup from "components/ui/organisms/RenamePopup";
import useCloseMenu from "../Comments/hooks/useCloseMenu";
import Avatar from "components/ui/atoms/Avatar";
import useGetAuthenticatedUser from "../Authentication/useHooks/useGetAuthenticatedUser";

const Comment = ({ comment, showMenu }) => {
  const [menu, setMenu] = useState(false);
  const [edit, setEdit] = useState(false);
  const [deleteMenu, setDeleteMenu] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [newComment, setNewComment] = useState(comment?.comment);

  const dispatch = useDispatch();
  const menuRef = useRef();
  useCloseMenu(menuRef, setMenu);
  const handleDelete = async () => {
    // await deleteComment(dispatch, { videoId: params.id, _id: comment._id });
    await dispatch(startDeleteComment(comment._id));
  };

  const handleUpdate = async () => {
    const data = {
      comment: newComment,
      edited: true,
    };
    await dispatch(startEditComment(comment._id, data));
    setEdit(!edit);
  };

  const handleEdit = () => {
    setEdit(!edit);
    setMenu(!menu);
  };

  const user = useGetAuthenticatedUser();

  return (
    <Fragment>
      {!edit && (
        <div className={styles.comment}>
          <button
            className={styles.menuWrapper}
            onClick={() => setMenu(!menu)}
            ref={menuRef}
          >
            {showMenu ? (
              <div className={styles.dots}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            ) : null}
          </button>
          {menu && (
            <div className={styles.menu}>
              <button className={styles.button} onClick={handleEdit}>
                Edit
              </button>
              <button
                className={styles.button}
                onClick={() => {
                  setDeleteMenu(true);
                  setShowPopUp(true);
                }}
              >
                Delete
              </button>
            </div>
          )}

          {comment.edited && <span className={styles.edited}>Edited</span>}
          <div className={styles.authorInfo}>
            {/* <Avatar name={user?.author} image={null} /> */}
            <Avatar name={comment.author} image={null} />
            <div>
              <h4 className={styles.authorName}>{comment.author}</h4>
              <cite className={styles.dateTime}>
                {`${comment?.time?.split("T")[0]} /  ${comment?.time
                  ?.split("T")[1]
                  ?.split(".")[0]
                  .slice(0, 5)}`}
              </cite>
            </div>
          </div>
          <p className={styles.authorComment}>{comment.comment}</p>
        </div>
      )}

      {edit && (
        <div className={styles.editCommentWrapper}>
          <div className={styles.editComment}>
            <div className={styles.authorInfo}>
              {/* <Avatar name={user?.author} image={null} /> */}
              <Avatar name={comment.author} image={null} />
              <div>
                <h4 className={styles.authorName}>{comment.author}</h4>
                <cite className={styles.dateTime}>
                  {`${comment?.time?.split("T")[0]} /  ${comment?.time
                    ?.split("T")[1]
                    ?.split(".")[0]
                    .slice(0, 5)}`}
                </cite>
              </div>
            </div>
            <textarea
              rows={5}
              value={newComment}
              onChange={e => setNewComment(e.target.value)}
            />
          </div>

          <RenamePopup
            handleFunction={handleUpdate}
            title='Update'
            color='orange1'
            text='Are you sure you want to UPDATE the comment ?'
            firsttext='No'
            secondtext='Yes'
          />
        </div>
      )}
      {showPopUp ? (
        <DeletePopup
          handleFunction={handleDelete}
          message='Are you sure you want to DELETE this comment?'
          firsttext='No'
          secondtext='Yes'
          setDeleteMenu={setDeleteMenu}
          showPopUp={showPopUp}
        />
      ) : null}
    </Fragment>
  );
};

Comment.propTypes = {
  comment: PropTypes.object,
  showMenu: PropTypes.bool,
};

export default Comment;
