import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import PropTypes from "prop-types";
// import File from "../File/File";
import { LinkPreview } from "@dhaiwat10/react-link-preview";
import Avatar from "components/ui/atoms/Avatar";

const Post = ({ chat }) => {
  return (
    <div className={styles.post}>
      <div className={styles.left}>

        <div className={styles.personImg}>
          {chat.picture ?  <img className={styles.personImg} src={chat.picture} alt="" /> : <Avatar name={chat.name} /> }
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.personInfo}>
          <h5>{chat.name}&nbsp;</h5>
            <span>{`${new Date(chat.time).toLocaleTimeString()}`}</span>
        </div>
        <p>{chat.text}</p>
      </div>
    </div>
  );
};

Post.propTypes = {
  chat: PropTypes.shape({
    type: PropTypes.string,
    text: PropTypes.string,
    time: PropTypes.number,
    name: PropTypes.string,
    sender: PropTypes.string,
    coloredAvatar: PropTypes.string,
    picture: PropTypes.string,
    target: PropTypes.string,
    receiverDisplayName: PropTypes.string,
    senderId: PropTypes.string,
  }),
};

export default Post;
