import React from "react";
import styles from "./style.module.scss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {ReactComponent as DefUserImg} from "../../../Assets/default-user.svg";
import {ReactComponent as CameraIcon} from "../../../Assets/camera-icon.svg";

export default function FixedInteractives({name}) {
  return (
    <div className={styles.PhotoAndLogoutContainer}>
      <div className={styles.userImageContainer}>
        <div className={styles.userImg}>
          <DefUserImg />
        <div className={styles.cameraIcon}>
          <CameraIcon />
        </div>
        </div>
        <p className={styles.username}>{name || "Username"}</p>
      </div>
        <div className={styles.closeBtn}>
          <Link className={styles.closeBtn} to="/">Close Settings</Link>
      </div>
    </div>
  );
}

FixedInteractives.propTypes = {
  name: PropTypes.string,
};