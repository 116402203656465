import React from "react";
import styles from "./style.module.scss";
import PropTypes from "prop-types";
import Input from "../Inputs/SingleInput";

function SharedButton({ text }) {
    return (
        <>
            <Input placeholder="Full Name" type="text" required />
            <Input placeholder="Email" type="text" required />
            <Input placeholder="Phone" type="text" required />
            <Input placeholder="Location" type="text" required />
            <textarea className={styles.personalInfo} rows="8" >
                { text }
            </textarea>
        </>
    );
}

export default SharedButton;
SharedButton.propTypes = {
    text: PropTypes.string,
};
