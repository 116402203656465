import React, { useState, useRef } from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import { ReactComponent as ArrownDown } from "assets/images/arrow-down.svg";
import useClickOutside from "components/helpers/useClickOutside";

const TableHeadInterview = ({ selectedOption, handleSort }) => {
    const options = ["Accepted", "To review", "Invited", "Rejected"];
    const [activeMenu, setActiveMenu] = useState(false);
    const openMenu = e => {
        e.preventDefault();
        setActiveMenu(!activeMenu);
    };
    const teamRef = useRef(null);
    useClickOutside(teamRef, () => setActiveMenu(false));

    return (
        <thead>
            <tr className={styles.row} ref={teamRef}>
                <th scope="col">
                    <span>
                        Users
                    </span>
                </th>
                <th scope="col">
                    <span>
                        Rating
                        <ArrownDown />
                    </span>
                </th>
                <th scope="col">
                    <span>
                        Date Added
                    </span>
                </th>
                <th scope="col">
                    <span
                        aria-hidden={true}
                        role="button"
                        onClick={e => openMenu(e)}>
                        {selectedOption || "All"}
                        <ArrownDown />
                    </span>
                    <div className={`${styles.dropdown} ${activeMenu && styles.showDropdown}`}>
                        {options.map(option => (
                            <div
                                className={styles.buttonDropdown}
                                aria-hidden={true}
                                onClick={() => handleSort(option)}
                                onKeyDown={() => handleSort(option)}
                                role="button"
                                tabIndex={0}
                            >
                                {option}
                            </div>
                        ))}

                    </div>
                </th>
                <th scope="col">
                    <span
                        aria-hidden={true}
                        onClick={() => handleSort("organiser")}
                        onKeyDown={() => handleSort("organiser")}
                        role="button"
                        tabIndex={0}
                    >
                        Organiser
                        <ArrownDown />
                    </span>
                </th>
            </tr>
        </thead>
    );
};
export default TableHeadInterview;
TableHeadInterview.propTypes = {
    selectedOption: PropTypes.string,
    handleSort: PropTypes.func,
};
