import { combineReducers } from "redux";
import recordingReducer from "./recordingReducer";
import authReducer from "./auth";
import scheduleMeetingReducer from "./scheduleMeetingReducer";
import { VideoReducer } from "./videoReducer";
import commentReducer from "./commentReducer";
import userDataReducer from "./userDataReducer";
import universalMenuReducer from "./universalMenuReducer";
import meetingRepositoryReducer from "./meetingRepository";
import polls from "./pollsReducer";
import SearchReducer from "./searchReducer";
import profilesReducer from "./profilesReducer";

const rootReducer = combineReducers({
    video: VideoReducer,
    recordingReducer,
    authReducer,
    scheduleMeetingReducer,
    comments: commentReducer,
    userData: userDataReducer,
    universalMenu: universalMenuReducer,
    meetingRepository: meetingRepositoryReducer,
    polls,
    SearchReducer,
    profilesReducer
});

export default rootReducer;
