import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import NothingToDisplay from "components/ui/atoms/NothingToDisplay";

function Notes ({ noteHistory }) {
  console.log(noteHistory, "not his");

  const isArrayLikeThis = (arr) => {
    const emptyParagraph = "<p>&nbsp;</p>";

    // Check if all elements are either empty strings or match the emptyParagraph pattern
    const isAllEmptyOrEmptyParagraph = arr.every((item) => item === "" || item === emptyParagraph);

    // If all elements are not empty strings or emptyParagraph, return false
    if (!isAllEmptyOrEmptyParagraph) {
      return false;
    }

    // Check each element to see if it contains more than one '&nbsp;' instances in the '<p>' tags
    const hasMoreThanOneNbsp = arr.some((item) => {
      const nbspMatches = item.match(/&nbsp;/g);
      const pMatches = item.match(/<p>/g);

      return nbspMatches && nbspMatches.length > 1 && pMatches && pMatches.length === 2;
    });

    return !hasMoreThanOneNbsp;
  };


  return (
    <div className={styles.notes}>
      {noteHistory.length > 0 && !isArrayLikeThis(noteHistory) ? (
        <>
          {noteHistory.map((note, index) => (
            <div className={styles.note} key={index}>
              {" "}
              {/* Apply a CSS class */}
              <p dangerouslySetInnerHTML={{ __html: note?.content[0] }} />
            </div>
          ))}
        </>
      ) : (
        <NothingToDisplay />
      )}
    </div>
  );
}

Notes.propTypes = {
  noteHistory: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Notes;
