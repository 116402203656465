import { useEffect } from "react";

const useUpdateAllComments = (state, setAllComments) => {
    useEffect(() => {
        if (state?.length > 0) {
            setAllComments(state);
        }
    }, [setAllComments, state]);
};

export default useUpdateAllComments;
