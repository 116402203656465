import React from "react";
import styles from "./style.module.scss";
import { ReactComponent as Tick } from "../../../../assets/images/tickIcon.svg";
// import Button from "../../atoms/SharedButton";
// import { Link } from "react-router-dom";
export default function mainPopup() {
  return (
    <div className={styles.mainPopup}>
      <div className={styles.content}>
        <Tick className={styles.tick} />
        <h1>You’re all done</h1>
        <h1 className={styles.white}>
          MW Interview - <span>Sales Manager</span>
        </h1>
        <h3>Master WiZR, Norway, Oslo</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
        </p>
        <a
          className={styles.weblink}
          target="_blank"
          href="https://www.masterwizr.com/"
          rel="noreferrer"
        >
          Visit Website
        </a>
      </div>
    </div>
  );
}
