export const CREATE_COMMENT = "CREATE_COMMENT";
export const CREATE_COMMENT_ERROR = "CREATE_COMMENT_ERROR";

export const EDIT_COMMENT = "EDIT_COMMENT";
export const EDIT_COMMENT_ERROR = "EDIT_COMMENT_ERROR";

export const GET_ALL_COMMENTS = "GET_ALL_COMMENTS";
export const GET_ALL_COMMENTS_ERROR  = "GET_ALL_COMMENTS_ERROR";

export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_ERROR = "DELETE_COMMENT_ERROR";

