import MyVideo from "components/ui/atoms/MyVideoDashboard";
import { Question } from "constants/Question";
import { useState } from "react";
import Carousel from "../../molecules/Carousel";

const items = [
  {
    id: 1,
    time: "4:35",
    question: "Question 1",
    description: "How do you handle pressure?",
  },
  {
    id: 2,
    time: "4:35",
    question: "Question 2",
    description:
      "What is the most interesting thing about you that the CV is not reflecting?",
  },
  {
    id: 3,
    time: "4:35",
    question: "Question 3",
    description: "Share stories about sources of motivation...",
  },
  {
    id: 4,
    time: "4:35",
    question: "Question 4",
    description: "Explain how you have worked with your weaknesses?",
  },
  {
    id: 5,
    time: "4:35",
    question: "Question 5",
    description: "What makes you the ideal candidate?",
  },
  {
    id: 6,
    time: "4:35",
    question: "Question 6",
    description: "How do you handle pressure?",
  },
  {
    id: 7,
    time: "4:35",
    question: "Question 7",
    description: "How do you handle pressure?",
  },
  {
    id: 8,
    time: "4:35",
    question: "Question 8",
    description:
      "What is the most interesting thing about you that the CV is not reflecting?",
  },
  {
    id: 9,
    time: "4:35",
    question: "Question 9",
    description: "Share stories about sources of motivation...",
  },
  {
    id: 10,
    time: "4:35",
    question: "Question 10",
    description: "Explain how you have worked with your weaknesses?",
  },
  {
    id: 11,
    time: "4:35",
    question: "Question 11",
    description: "What makes you the ideal candidate?",
  },
  {
    id: 12,
    time: "4:35",
    question: "Question 12",
    description: "How do you handle pressure?",
  },
];

function MyVideoDashboard() {
  const [videoItem, setActiveVideo] = useState(items[0]);
  console.log(videoItem);

  return (
    <>
      <MyVideo
        name={Question.name}
        question={Question.question}
        firstdescription={Question.firstdescription}
        seconddescription={Question.seconddescription}
        videoItem={videoItem}
      />
      <Carousel items={items} setActiveVideo={setActiveVideo} />
    </>
  );
}

export default MyVideoDashboard;
