import React, { useState } from "react";

import PropTypes from "prop-types";

import styles from "./styles.module.scss";
// import avatar from "../../../../assets/images/profile-picture.webp";
import { ReactComponent as File } from "../../../../assets/images/file.svg";
import { ReactComponent as Folder } from "../../../../assets/images/folder.svg";
import DropDownMenu from "../DropDownMenu";

const TableRow = ({
  pollTopic,
  id,
  createdAt,
  created,
  organizedBy,
  type,
  pathHandler,
  activeMenu,
  setActiveMenu,
}) => {
  const [freePosition, setFreePosition] = useState();
  const handleContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveMenu(id);
    setFreePosition({
      posX: e.target.offsetLeft
    });
  };
  return (
    <tr className={styles.row} onClick={() => pathHandler(id, type)} onContextMenu={(e) => {handleContextMenu(e)}} >
      <td>
      </td>
      <td>
        <span
          className={styles.name}
          onKeyDown={() => pathHandler(id, type)}
          role="button"
          tabIndex={0}
        >
          {type === "file" ? <File /> : <Folder />} {pollTopic.trim() === "" ? "Poll" : pollTopic}
        </span>
      </td>
      {/* add data for date added and size */}
        <td>{created}</td>
        {/* <td>94.1 mb</td> */}
        <td>{organizedBy}</td>
    </tr>
  );
};

TableRow.propTypes = {
  pollTopic: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  pathHandler: PropTypes.func,
  activeMenu: PropTypes.string,
  setActiveMenu: PropTypes.func,
  createdAt: PropTypes.any,
  created: PropTypes.any,
  organizedBy: PropTypes.string,
};



export default TableRow;
