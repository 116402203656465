import React from "react";
import styles from "./style.module.scss";
import img from "../../../../assets/images/video-img.jpg";
import Button from "../../atoms/SharedButton";
export default function videoContent({
  extraClass, // for the list
  title, //title under the video
  desc, // description under the video
  listTitle, // title in the right side (list side)
  listDesc, // description for list
  list,
  unorderedList, // unordered list with two buttons
  defaultList, // list with one button
  subtitle, // under the title of list
  extraClass1, // for the whole wrapper
}) {
  return (
    <div className={`${styles.wrapper} ${styles[extraClass1]}`}>
      <div className={styles.videoHolder}>
        <div className={styles.videoContainer}>
          <img src={img} alt="video" />
        </div>
        <h2>{title}</h2>
        <p>{desc}</p>
      </div>
      <div className={`${styles.listHolder} ${styles[extraClass]}`}>
        <h2>{listTitle}</h2>
        <h3>{subtitle}</h3>
        <p>{listDesc}</p>
        <ul>
          {list.map((item, i) => {
            return <li key={i}>{item}</li>;
          })}
        </ul>
        {defaultList && <Button extraClass="button">Get Started</Button>}
        {unorderedList && (
          <div className={styles.buttons}>
            <Button extraClass="button">Share Video</Button>
            <span>Or</span>
            <Button extraClass="grayBtn1">Upload Video</Button>
          </div>
        )}
      </div>
    </div>
  );
}
