import React from "react";
import VideoPlayerContainer from "components/ui/molecules/VideoContainer/Container";
import VideoContainer from "../VideoContainer";
import CommentsContainer from "components/ui/molecules/Comments";
import { useSelector } from "react-redux";

function VideoPlayer() {
  const { isFetching } = useSelector(state => state?.video);
  const style = isFetching ? {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  }: {
    width: "100%",
  };
  return (
    <>
      <VideoContainer>
          <div style={style}>
            <VideoPlayerContainer comments={true} speedContainer={true} />
            <CommentsContainer />
          </div>

      </VideoContainer>
    </>
  );
}

export default VideoPlayer;
