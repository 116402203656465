import {
  VIDEO,
  GET_VIDEOS,
  UPDATE_VIDEO,
  ARCHIVE_VIDEO,
  FAVORITE_VIDEO,
  DELETE_VIDEO,
  DOWNLOAD_VIDEO,
  GET_ARCHIVED_VIDEOS,
  GET_FAVORITE_VIDEOS,
  GET_SHARED_LINK,
  DUPLICATE_VIDEO,
  GET_PAGINATION_DATA,
  GET_SHARED_WITH_ME_VIDEOS,
  VIDEOUUID,
} from "redux/actions/actionTypes";

const initialState = {
  videos: {
    data: [],
    totalVideos: 0,
    page: 1,
    perPage: 10,
  },
  searchText: "",
  sharedWithMe: [],
  isFetching: false,
  video: null,
  patchStatus: false,
  addStatus: false,
  download: null,
  archivedVideos: {
    data: [],
    page: 1,
    perPage: 10,
    totalArchivedVideos: 0,
  },
  favouriteVideos: {
    data: {
      videos: [],
    },
    totalFavouriteVideos: 0,
    page: 1,
    perPage: 10,
  },
  paginationData: {},
  message: "",
  downloadStatus: false,
};

export const VideoReducer = (
  state = initialState,
  { type, payload, error }
) => {
  switch (type) {
    case GET_VIDEOS.INIT:
      return { ...state, isFetching: true };

    case GET_VIDEOS.SUCCESS:
      return { ...state, videos: payload, isFetching: false };

    case GET_VIDEOS.ERROR:
      return {
        ...state,
        error: true,
        isFetching: false,
        message: payload.message,
      };

    case GET_FAVORITE_VIDEOS.INIT:
      return { ...state, isFetching: true };

    case GET_FAVORITE_VIDEOS.SUCCESS:
      console.log(payload, "payload");
      return { ...state, favouriteVideos: payload, isFetching: false };

    case GET_FAVORITE_VIDEOS.ERROR:
      return {
        ...state,
        error: true,
        isFetching: false,
        message: error.message,
      };

    case GET_ARCHIVED_VIDEOS.INIT:
      return { ...state, isFetching: true };

    case GET_ARCHIVED_VIDEOS.SUCCESS:
      return { ...state, archivedVideos: payload, isFetching: false };

    case GET_ARCHIVED_VIDEOS.ERROR:
      return {
        ...state,
        error: true,
        isFetching: false,
        message: error.message,
      };

    case VIDEO.INIT:
      return { ...state, isFetching: true };

    case VIDEO.SUCCESS:
      return { ...state, video: { ...payload }, isFetching: false };

    case VIDEO.ERROR:
      return { error: true, isFetching: false };

    case VIDEOUUID.INIT:
      return { ...state, isFetching: true };

    case VIDEOUUID.SUCCESS:
      return { ...state, video: { ...payload }, isFetching: false };

    case VIDEOUUID.ERROR:
      return { error: true, isFetching: false };

    case UPDATE_VIDEO.INIT:
      return {
        ...state,
        patchStatus: true,
      };

    case UPDATE_VIDEO.SUCCESS:
      return {
        ...state,
        patchStatus: false,
        videos: {
          ...state.videos,
          data: state.videos?.data?.map(item =>
            item._id === payload._id ? payload : item
          ),
        },
        archivedVideos: {
          ...state.archivedVideos,
          data: state.archivedVideos?.data?.map(item =>
            item._id === payload._id ? payload : item
          ),
        },
        favouriteVideos: {
          ...state.favouriteVideos,
          data: {
            videos: state.favouriteVideos?.data?.videos?.map(item =>
              item._id === payload._id ? payload : item
            ),
          },
        },
        video: payload,
      };

    case UPDATE_VIDEO.ERROR:
      return {
        ...state,
        patchStatus: false,
        error,
      };

    case GET_PAGINATION_DATA:
      return {
        ...state,
        paginationData: payload,
      };

    case ARCHIVE_VIDEO.INIT:
      return {
        ...state,
        patchStatus: true,
      };

    case ARCHIVE_VIDEO.SUCCESS:
      return {
        ...state,
        patchStatus: false,
        videos: {
          ...state.videos,
          data: state.videos.data.map(item =>
            item._id === payload._id ? { ...item, ...payload } : item
          ),
        },
        video: payload,
      };

    case ARCHIVE_VIDEO.ERROR:
      return {
        ...state,
        patchStatus: false,
        error: payload,
      };
    case FAVORITE_VIDEO.INIT:
      return {
        ...state,
        patchStatus: true,
      };

    case FAVORITE_VIDEO.SUCCESS:
      return {
        ...state,
        patchStatus: false,
        videos: {
          ...state.videos,
          data: state.videos.data.map(item =>
            item._id === payload._id ? { ...payload } : item
          ),
        },
        video: payload,
      };

    case FAVORITE_VIDEO.ERROR:
      return {
        ...state,
        patchStatus: false,
        error: payload,
      };
    case DELETE_VIDEO.INIT:
      return {
        ...state,
        patchStatus: true,
      };

    case DELETE_VIDEO.SUCCESS:
      return {
        ...state,
        patchStatus: false,
        videos: {
          ...state.videos,
          data: state.videos.data?.filter(item => item._id !== payload),
        },
        favouriteVideos: {
          ...state.favouriteVideos,
          data: {
            videos: state.favouriteVideos?.data?.videos.filter(
              item => item._id !== payload
            ),
          },
        },
        archivedVideos: {
          ...state.archivedVideos,
          data: state.archivedVideos.data.filter(item => item._id !== payload),
        },
      };

    case DELETE_VIDEO.ERROR:
      return {
        ...state,
        patchStatus: false,
        error: payload,
      };
    case DOWNLOAD_VIDEO.INIT:
      return {
        ...state,
        downloadStatus: true,
      };

    case DOWNLOAD_VIDEO.SUCCESS:
      return {
        ...state,
        downloadStatus: false,
        download: payload,
      };

    case DOWNLOAD_VIDEO.ERROR:
      return {
        ...state,
        downloadStatus: false,
        error: error,
      };

    case GET_SHARED_LINK.INIT:
      return {
        ...state,
        sharing: true,
      };

    case GET_SHARED_LINK.SUCCESS:
      return {
        ...state,
        sharing: false,
      };

    case GET_SHARED_LINK.ERROR:
      return {
        ...state,
        sharing: false,
      };

    case DUPLICATE_VIDEO.INIT:
      return {
        ...state,
        addStatus: true,
      };
    case DUPLICATE_VIDEO.ERROR:
      return {
        ...state,
        addStatus: false,
        error,
      };
    case DUPLICATE_VIDEO.SUCCESS:
      let key;
      if (payload.favourite) {
        key = "favouriteVideos";
      } else if (payload.archived) {
        key = "archivedVideos";
      } else {
        key = "videos";
      }
      // window.alert(key);
      if (key === "videos") {
        return {
          ...state,
          addStatus: false,
          videos: {
            ...state.videos,
            data: [...state.videos.data, payload],
          },
        };
      }

      return {
        ...state,
        [key]: [...state[key], payload],
        addStatus: false,
      };

    case GET_SHARED_WITH_ME_VIDEOS.INIT:
      return {
        ...state,
        isFetching: true,
      };
    case GET_SHARED_WITH_ME_VIDEOS.ERROR:
      return {
        ...state,
        isFetching: false,
        error,
      };
    case GET_SHARED_WITH_ME_VIDEOS.SUCCESS:
      return {
        ...state,
        sharedWithMe: payload,
        isFetching: false,
      };
    case "SET_SEARCH_TEXT":
      return {
        ...state,
        searchText: payload,
      };
    default:
      return state;
  }
};
