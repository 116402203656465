import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import SharedFolder from "../SharedFolder/SharedFolder";
import NothingToDisplay from "components/ui/atoms/NothingToDisplay";

function Shared ({ fileHistory, setFileAndShowPreview }) {
  return (
    <div className={styles.shared}>
      {fileHistory.length > 0 ? (
        <>
          {fileHistory.map((item, index) => (
            <SharedFolder key={index} item={item} setFileAndShowPreview={setFileAndShowPreview} />
          ))}
        </>
      ) : (
        <NothingToDisplay />
      )}
    </div>
  );
}

Shared.propTypes = {
  // fileHistory: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     peerId: PropTypes.string.isRequired,
  //     roomFile: PropTypes.shape({
  //       type: PropTypes.string.isRequired,
  //       roomFile: PropTypes.string.isRequired,
  //       fileName: PropTypes.string.isRequired,
  //       name: PropTypes.string.isRequired,
  //       sender: PropTypes.string.isRequired,
  //       picture: PropTypes.string,
  //       peerId: PropTypes.string.isRequired,
  //     }).isRequired,
  //     time: PropTypes.number.isRequired,
  //   })
  // ).isRequired,
  fileHistory: PropTypes.array,
  setFileAndShowPreview: PropTypes.func
};

export default Shared;
