import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import MembersCard from "../MembersCard";
import { Link } from "react-router-dom";
import useClickOutside from "components/helpers/useClickOutside";

function MembersCardsContainer({ invite, searchData, setOpenDashboard }) {
  const [activeMenu, setActiveMenu] = useState(false);
  const openDashboard = () => {
    setOpenDashboard(true);
  };

  const memberRef = useRef(null);
  useClickOutside(memberRef, () => setActiveMenu(false));

  return (
    <div className={styles.container} ref={memberRef}>
      {!invite && (
        <Link to="#" onClick={() => openDashboard()}>
          <MembersCard add="add" text="Invite team members" />
        </Link>
      )}
      {searchData.map((member, index) => {
        return (
          <MembersCard
            key={index}
            member={member}
            index={index}
            setActiveMenu={setActiveMenu}
            activeMenu={activeMenu}
          />
        );
      })}
    </div>
  );
}

MembersCardsContainer.propTypes = {
  searchData: PropTypes.array,
  setOpenDashboard: PropTypes.func,
  invite: PropTypes.bool,
};

export default MembersCardsContainer;
