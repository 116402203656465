import { useEffect } from "react";
import {useSelector} from "react-redux";

const useUpdateState = (totalComments, setState) => {
    const comments = useSelector((state) => state?.video?.paginationData?.data?.comments);
    useEffect(() => {
        if (totalComments > 0 && comments?.length > 0) {
            setState(comments);
        }
    }, [comments, setState, totalComments]);
};

export default useUpdateState;
