import React from "react";
import PropTypes from "prop-types";

function Icon({ extraClass }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18"
      fill="none"
      viewBox="0 0 17 18"
      className={extraClass}
    >
      <path
        fill="#9F9F9F"
        d="M16.754 15.89l-3.992-4.15a6.749 6.749 0 001.589-4.354A6.779 6.779 0 007.58.616a6.779 6.779 0 00-6.771 6.77 6.779 6.779 0 006.77 6.771 6.698 6.698 0 003.88-1.225l4.022 4.183c.168.175.394.27.636.27a.884.884 0 00.636-1.495zM7.58 2.383a5.01 5.01 0 015.004 5.004 5.01 5.01 0 01-5.004 5.005 5.01 5.01 0 01-5.005-5.005A5.01 5.01 0 017.58 2.382z"
      ></path>
    </svg>
  );
}

Icon.propTypes = {
    extraClass: PropTypes.any
};

export default Icon;
