import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import Mic from "../../../atoms/Icons/Mic";
import Stop from "../../../atoms/Icons/Record/stop";
import Pause from "../../../atoms/Icons/Pause";
import Close from "../../../atoms/Icons/Close";
import Resume from "../../../atoms/Icons/Resume";
import MutedMic from "../../../atoms/Icons/MutedMic";

function RecordTabs({
  stopRecording,
  pauseAndResumeRecording,
  paused,
  audio,
  audioActive,
  audioSettings,
  recording,
  cancelRecording,
  changeAudioSettingToTrue,
  audioStream
}) {
  console.log(audioSettings, "audioSettings");
  const [second, setSecond] = useState("00");
  const [minute, setMinute] = useState("00");
  const [isActive, setIsActive] = useState(true);
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        const computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        const computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;

        setSecond(computedSecond);
        setMinute(computedMinute);

        setCounter(counter => counter + 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  function pauseOrResumeRecording() {
    pauseAndResumeRecording();
    setIsActive(!isActive);
  }

  function audioMeter() {
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource(audioSettings || audioStream);
    const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;
    microphone.connect(analyser);
    analyser.connect(scriptProcessor);
    scriptProcessor.connect(audioContext.destination);
    scriptProcessor.onaudioprocess = function () {
      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      const arraySum = array.reduce((a, value) => a + value, 0);
      const average = arraySum / array.length;
      colorPids(average);
    };
  }

  function colorPids(vol) {
    const pid = document.querySelectorAll("#pid") || null;
    if (pid[0]) {
      pid[0].style.width = vol + "%";
    } else return;
  }

  if (recording) {
    audioMeter();
  }

  return (
    <div className={styles.containerRecordingTabs}>
      <div className={styles.recordingTabs}>
        <div>
          <span>
            {minute}:{second}
          </span>
          Time
        </div>
        <button onClick={() => stopRecording(minute, second)}>
          <Stop /> Finish
        </button>
        <button /*disabled={!audioSettings ? true : false}*/ className={styles.audio} onClick={() => {
          if (audioActive) {
            changeAudioSettingToTrue();
          }
          audio();
        }} style={!audioSettings ? { opacity: 0.7 } : null}>
          {audioActive ? <Mic /> : <MutedMic />} Audio{" "}
          {/* {audioSettings === null ?  <MutedMic /> : audioActive  ? <Mic /> : <MutedMic />  } Audio{" "} */}
          {audioActive && <div className={styles.pidWrapper}>
            <div id="pid" className={styles.pid}></div>
          </div>}
        </button>
        <button onClick={pauseOrResumeRecording}>
          {!paused ? (
            <>
              <Pause /> Pause
            </>
          ) : (
            <>
              <Resume /> Resume
            </>
          )}
        </button>
        <button onClick={cancelRecording}>
          <Close /> Cancel
        </button>
      </div>
    </div>
  );
}

RecordTabs.propTypes = {
  stopRecording: PropTypes.func,
  pauseAndResumeRecording: PropTypes.func,
  audio: PropTypes.func,
  paused: PropTypes.bool,
  audioActive: PropTypes.bool,
  audioSettings: PropTypes.shape({}),
  recording: PropTypes.bool,
  cancelRecording: PropTypes.func,
  changeAudioSettingToTrue: PropTypes.func,
  audioStream: PropTypes.any
};

export default RecordTabs;
