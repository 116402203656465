import { getAllComments } from "../../actionCreators/commentActionCreators";

const dispatchGetAllComments = (dispatch, data) => {
    if (data.total === 0) {
        return dispatch(getAllComments([]));
    } else {
        return dispatch(getAllComments(data.data.comments));
    }
};

export default dispatchGetAllComments;
