import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import { getRandomColor, createImageFromInitials } from "./utils";

/**
 *
 * @param {name} string
 * @returns
 */
export default function Avatar({ name, image }) {
  const activeUser = localStorage.getItem("activeUser");
  return (
    <div className={styles.avatar}>
      <img
        id={styles.preview}
        // src={
        //   !image ? createImageFromInitials(500, name, getRandomColor()) : image
        // }
        src={createImageFromInitials(500, activeUser, getRandomColor()) }
        alt={activeUser}
      />
    </div>
  );
}

Avatar.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
};
