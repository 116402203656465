import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import mw from "../../../../assets/images/mw.png";
import Form from "components/ui/atoms/Form";
import OptionInput from "components/ui/atoms/Inputs/OptionInput";
import SharedButton from "components/ui/atoms/SharedButton";
import { ReactComponent as Upload } from "../../../../assets/images/upload.svg";

const RoomsAvailable = ["Yes", "No"];

function SetUpInformation() {
    return (
        <div className={styles.setInfo}>
            <div className={styles.leftImg} style={{ backgroundImage: `url(${mw})` }} />
            <div className={styles.info}>
                <div className={styles.wrapper}>
                    <div className={styles.content}>
                        <h1>Set up your Information</h1>
                        <div className={styles.inputsUpload}>
                            <div className={styles.inputs}>
                                <Form text="Leave your personal information"/>
                            </div>
                            <div className={styles.uploadResume}>
                                <Upload />
                                <p>
                                    Upload Resume
                                </p>
                            </div>
                        </div>
                        <div className={styles.rightContent}>
                            <p className={styles.paragraph}>
                                Show Room Available
                            </p>
                            <OptionInput list={RoomsAvailable} extraClass="information" />
                        </div>
                        <SharedButton children="Save & Continue" />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SetUpInformation;
SetUpInformation.propTypes = {
    title: PropTypes.string,
    objectives: PropTypes.array,
    responsibilities: PropTypes.array,
    skills: PropTypes.array,
    hasVideo: PropTypes.bool,
};
