import { useEffect} from "react";
import { useSelector } from "react-redux";

const useSetState = (state, setState) => {
    const comments = useSelector((state) => state?.video?.paginationData?.data?.comments);
    useEffect(() => {
        setState(comments);
    }, [setState, state, comments]);
};

export default useSetState;
