import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import { ReactComponent as Close2 } from "assets/images/close2.svg";
import Chat1 from "assets/images/chat1.jpg";
import Chat2 from "assets/images/chat2.jpg";
import Chat3 from "assets/images/chat3.jpg";
import Comment from "./Comment";
import { ReactComponent as Rightc } from "assets/images/right-arrowc.svg";
import { ReactComponent as Happyc } from "assets/images/happyc.svg";
import { ReactComponent as ArrownDown } from "assets/images/arrow-down.svg";

const Comments = ({ close, extraClass }) => {

    const closeHandler = (e) => {
        e.preventDefault();
        close();
    };

    const first =
    {
        id: 1,
        name: "You",
        image: Chat1,
        date: "19:31 PM",
        firstdescription: "Ana Smith is a suitable for this position. Ipsum dolor sit amet, consectetur adipiscing elit.",
        seconddescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
    };

    const comments = [
        {
            id: 1,
            image: Chat1,
            name: "bakiejay",
            date: "19:32 PM",
            firstdescription: "From breaking into the Partizan Belgrade senior squad at 15 to rivalling Europe's best strikers at 21, goalscoring 'monster' @Dusan Vlahovic is in-demand.",
            online: false,
            icons:false
        },
        {
            id: 2,
            image: Chat2,
            name: "jackie",
            date: "19:32 PM",
            firstdescription: "Filip Benkovic has left Leicester City after the club and agreed to cancel the remaining.",
            online: true,
            icons:true
        },
        {
            id: 3,
            image: Chat3,
            name: "prossy",
            date: "19:32 PM",
            firstdescription: "Thanks  for this old show 💪before dropping on this ,I have struggled alot looking for these but now all my prayers have been answered ✌who else is still with us enjoying 👋",
            online: false,
            icons:false
        },
    ];

    return (
        <div className={`${styles.sidebar} ${styles[extraClass]}`}>
            <div className={styles.closeComments}>
                <p>12 Comments</p>
                <button className={styles.closen} onClick={closeHandler}><Close2 /></button>
            </div>
            <Comment item={first} notAvailable />
            <div className={styles.imgs}>
                <img src={Chat2} alt="" />
                <div className={styles.jd}><p>JD</p></div>
                <img src={Chat3} alt="" />
                <p className={styles.commentsPart}>7 comments</p>
                <p className={styles.date}>today, 10:08 AM</p>
                <ArrownDown/>
            </div>
            <div className={`${styles.load} ${styles.load1}`}>
                <div className={`${styles.load} ${styles.load2}`}>
                    <div className={`${styles.load} ${styles.load3}`}>
                        <p>Load previous comments</p>
                    </div>
                </div>
            </div>
            <div className={styles.commentsRight}>
                {comments.map((item, i) => {
                    return <Comment key={i} item={item} />;
                })}
            </div>
            <div className={styles.writeComment}>
                <div className={styles.icons}>
                    <Rightc fill="white" />
                    <input type="text" />
                    <Happyc fill="#EC7600" />
                </div>
            </div>
        </div>
    );
};

export default Comments;
Comments.propTypes = {
    close: PropTypes.func,
    extraClass: PropTypes.string
};