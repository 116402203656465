import React, { useState } from "react";
import Carousel, { consts } from "react-elastic-carousel";
import Item from "../../atoms/CarouselItem";
import styles from "./style.module.scss";
import PropTypes from "prop-types";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1024, itemsToShow: 4 },
  { width: 1440, itemsToShow: 5 },
];

export default function App({ items, setActiveVideo }) {
  function myArrow({ type, onClick, isEdge }) {
    const pointer = type === consts.PREV ? "<" : ">";
    return (
      <button
        className={styles.arrowButtons}
        onClick={onClick}
        disabled={isEdge}
      >
        {pointer}
      </button>
    );
  }

  return (
    <div className={styles.carouseWraper}>
      <Carousel
        renderArrow={myArrow}
        pagination={false}
        breakPoints={breakPoints}
      >
        {items.map((item, index) => (
          <Item
            items={items}
            key={index}
            item={item}
            setActiveVideo={setActiveVideo}
          />
        ))}
      </Carousel>
    </div>
  );
}

App.propTypes = {
  setActiveVideo: PropTypes.func,
  items: PropTypes.array,
};
