import ApplicantCard from "components/ui/atoms/ApplicantCard";
import SharedButton from "components/ui/atoms/SharedButton";
import React, {useState} from "react";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Star } from "../../../../assets/images/star.svg";
import { ReactComponent as GrayStar } from "../../../../assets/images/darkstar.svg";
import DeletePopup from "components/ui/organisms/DeletePopup";
import ArchivePopup from "components/ui/organisms/ArchivePopup";
import { ReactComponent as Comment } from "../../../../assets/images/comment.svg";
import { ReactComponent as URL } from "../../../../assets/images/url.svg";
import Comments from "components/ui/atoms/Comments";

export default function Applicant() {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/schedule-meeting`;
    navigate(path);
  };
  const titles = [
    "Country, City:",
    "Email",
    "Phone",
    "Resume submitted:",
    "Personal message",
    "Show Room avaliable:",
  ];
  const desc = [
    "Norway, Oslo",
    "johnsmith@youremail.com",
    "+001 234 567 980",
    "Yes",
    "Yes",
    "Yes",
  ];

  const [extraClass, setExtraClass] = useState("close");

  const openHandler = () => {
      setExtraClass("");
  };

  const sidebarCloseHandler = () => {
      setExtraClass("close");
  };

  return (
    <div className={styles.applicant}>
      <div className={styles.wrapper}>
        <div className={styles.info}>
          <h1>Ana Smith</h1>
          <ApplicantCard
            extraClass="applicantCard"
            titlesList={titles}
            descList={desc}
          />
        </div>
        <SharedButton clickEvent={routeChange} extraClass="grayBtn1">
          Schedule Meeting
        </SharedButton>
      </div>
      <div className={styles.data}>
        <h1>Company Review</h1>
        <div className={styles.icons}>
          <Star />
          <Star />
          <Star />
          <Star />
          <Star />
          <GrayStar />
          <GrayStar />
        </div>
        <div className={styles.buttons}>
          <SharedButton extraClass="orange1">Accept</SharedButton>
          <DeletePopup />
          <SharedButton extraClass="grayBtn1">Reject</SharedButton>
          <ArchivePopup />
        </div>
        <button className={styles.comment} onClick={openHandler} >
          <Comment />
          <p className={styles.commentPart}>Make a comment (10)</p>
        </button>
        <Comments
                  close={sidebarCloseHandler}
                  extraClass={extraClass}
          />
        <div className={styles.url}>
          <URL />
          <p>Invite for comments</p>
        </div>
      </div>
    </div>
  );
}
