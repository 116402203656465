import React, {useState, useMemo, useEffect} from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import Input from "../../../../../../atoms/Inputs/Input";
import DropdownSelect from "../../../../../../atoms/Inputs/DropdownSelect";
import countryList from "react-select-country-list";


export default function ProfileMain({ editing, changes, setChanges, setSubmittableData }) {
  const arrayOfCountries = useMemo(() => countryList().getLabels(), []);
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    if (selectedOption) {
      setChanges({ ...changes, country: selectedOption });
    }
  }, [selectedOption]);

  useEffect(() => {
    setSubmittableData({first_name: changes.first_name, last_name: changes.last_name, title: changes.title, country: changes.country});
  }, [changes.first_name, changes.last_name, changes.title, changes.country]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.nameContainer}>
        <div className={styles.firstName}>
          <Input
            label="First Name"
            placeholder={"First Name"}
            required={true}
            value={changes.first_name}
            onChange={(e) => setChanges({ ...changes, first_name: e.target.value })}
            disabled={!editing}
          />
        </div>
        <div className={styles.lastName}>
          <Input
            label="Last Name"
            placeholder={"Last Name"}
            required={true}
            value={changes.last_name}
            onChange={(e) => setChanges({ ...changes, last_name: e.target.value })}
            disabled={!editing}
          />
        </div>
      </div>
      <div className={styles.titleInput}>
        <Input
          label="Title"
          placeholder={"CEO, CTO, etc.."}
          required={true}
          value={changes.title}
          onChange={(e) => setChanges({ ...changes, title: e.target.value })}
          disabled={!editing}
        />
      </div>
      <div className={styles.countryList}>
      <DropdownSelect
      label="Country"
      list={arrayOfCountries}
      disabled={!editing}
      selected={selectedOption || changes.country || "Select a country"}
      onChange={setSelectedOption}
      />
      </div>
    </div>
  );
}

ProfileMain.propTypes = {
  editing: PropTypes.bool,
  changes: PropTypes.any,
  setChanges: PropTypes.func,
  setSubmittableData: PropTypes.func,
};
