import React from "react";

function Icon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3998 12.5H2.59978C2.19978 12.5 1.84978 12.3 1.64978 11.95C1.44978 11.6 1.49978 11.2 1.69978 10.85L2.24978 10C3.14978 8.65 3.59978 7.1 3.59978 5.5C3.59978 3.65 4.79978 1.95 6.54978 1.35C6.84978 0.8 7.39978 0.5 7.99978 0.5C8.59978 0.5 9.14978 0.8 9.44978 1.35C11.1998 1.95 12.3998 3.65 12.3998 5.5C12.3998 7.1 12.8498 8.65 13.7498 10L14.2998 10.85C14.4998 11.2 14.5498 11.6 14.3498 11.95C14.1998 12.3 13.7998 12.5 13.3998 12.5Z"
        fill="#1AD0A8"
      />
      <path
        d="M5.5498 13.5C5.7998 14.65 6.7998 15.5 7.9998 15.5C9.1998 15.5 10.1998 14.65 10.4498 13.5H5.5498Z"
        fill="#1AD0A8"
      />
    </svg>
  );
}

export default Icon;
