import PropTypes from "prop-types";
import styles from "./style.module.scss";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import Loader from "../Loader";

function MultiEmailsSharing ({
  text,
  label,
  withButton,
  placeholder,
  mailList,
  mailListSetter,
  handler,
  error,
  setError,
  sharing,
}) {
  return (
    <>
    <div className={styles.EdibleContentButton}>
      <div
        className={
          !withButton
            ? `${styles.edibleContent} ${styles.fullWidth}`
            : styles.edibleContent
        }
        style={error ? { borderColor: "#ff70008f" } : null}
      >
        <ReactMultiEmail
          className={styles.multiEmailInput}
          placeholder={placeholder}
          emails={mailList}
          onChange={_emails => {
            mailListSetter(_emails);
            setError(false);
          }}
          getLabel={(email, index, removeEmail) => {
            return (
              <div className={styles.mails} data-tag key={index}>
                {email}
                <span
                  aria-hidden
                  className={styles.mailsBtn}
                  data-tag-handle
                  onClick={() => removeEmail(index)}
                >
                  ×
                </span>
              </div>
            );
          }}
        />
      </div>
      {withButton && (
            <div
              className={styles.buttonSend}
              aria-hidden
              onClick={handler}
              pointerEvents={sharing ? "none" : "auto"}
              opacity={sharing ? 0.5 : 1}
            >
              <p>{sharing ? "Sharing..." : text}</p>
            </div>
      )}
    </div>
    </>
  );
}

export default MultiEmailsSharing;
MultiEmailsSharing.propTypes = {
  text: PropTypes.string,
  withButton: PropTypes.bool,
  placeholder: PropTypes.string,
  mailList: PropTypes.array,
  mailListSetter: PropTypes.func,
  handler: PropTypes.func,
  error: PropTypes.bool,
  setError: PropTypes.func,
  sharing: PropTypes.bool,
  label: PropTypes.string,
};
