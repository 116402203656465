import React, { useState } from "react";
import styles from "./style.module.scss";
import PropTypes from "prop-types";
import TeamsCard from "../TeamsCard";
import { Link } from "react-router-dom";

function TeamsCardsContainer({ invite, searchData, setOpenDashboard }) {
  const [activeMenu, setActiveMenu] = useState(false);
  const openDashboard = () => {
    setOpenDashboard(true);
  };
  return (
    <div className={styles.container}>
      {!invite && (
        <Link to="#" onClick={() => openDashboard()}>
          <TeamsCard add="add" title="Invite team groups to dashboard" />
        </Link>
      )}
      {searchData.map((team, index) => {
        return (
          <Link key={index} to={`${index}`}>
            <TeamsCard
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              index={index}
              team={team}
            />
          </Link>
        );
      })}
    </div>
  );
}

TeamsCardsContainer.propTypes = {
  searchData: PropTypes.array,
  setOpenDashboard: PropTypes.func,
  invite: PropTypes.bool,
};

export default TeamsCardsContainer;
