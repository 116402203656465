/* eslint-disable react/prop-types */
// Slider.js
import React from "react";
import "./style.scss";

function Slider({ value = 0 }) {
  const trackStyle = {
    width: `${value}%`, // Assuming value is a percentage from 0 to 100
  };

  return (
    <div className="slider-container">
      <div className="slider-track" style={trackStyle} />
    </div>
  );
}

export default Slider;
