import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import videog from "../../../../assets/images/videog.jpg";
import { Link } from "react-router-dom";
import SharedButton from "../SharedButton";
import VideoPlayerContainer from "components/ui/molecules/VideoContainer/Container";

function MyVideoDashboard({
  name,
  firstdescription,
  seconddescription,
  videoItem,
}) {
  return (
    <div className={styles.myVideo}>
      <h1>
        {name} - <span>My Video Dashboard</span>
      </h1>
      <div className={styles.paragraphLink}>
        <h5>WIZR Connect</h5>
        <span>-</span>
        <Link to="/re-edit">Re-edit videos here</Link>
      </div>
      <div className={styles.videoQuestion}>
        <div className={styles.videoWrapper}>
          <VideoPlayerContainer
            extraClass="heightAuto"
            videoItem={videoItem}
            comments={false}
            speedContainer={false}
          />
        </div>
        <div>
          <h1>{videoItem.question}</h1>
          <p>{firstdescription}</p>
          <p>{seconddescription}</p>
          <div className={styles.buttons}>
            <SharedButton children="Re-Take Video" extraClass="lightGrayBtn" />
            <SharedButton children="Accept & Continue" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default MyVideoDashboard;
MyVideoDashboard.propTypes = {
  name: PropTypes.string,
  firstdescription: PropTypes.string,
  seconddescription: PropTypes.string,
  videoItem: PropTypes.object,
};
