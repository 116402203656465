import React from "react";
import PropTypes from "prop-types";
import Post from "./Post/Post";
import "./chats.scss";
import NothingToDisplay from "components/ui/atoms/NothingToDisplay";

function Chats ({ chatHistory }) {
  return (
    <div className='chatsContainer'>
    <div className='chats'>
      {chatHistory.length > 0 ? (
        <>
          {chatHistory.map((chat, index) => {
            return <Post chat={chat?.chatMessage} key={index} />;
          })}
        </>
      ) : (
        <NothingToDisplay />
      )}
    </div>
  </div>
  );
}

Chats.propTypes = {
  chatHistory: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      text: PropTypes.string,
      time: PropTypes.number,
      name: PropTypes.string,
      sender: PropTypes.string,
      coloredAvatar: PropTypes.string,
      picture: PropTypes.string,
      target: PropTypes.string,
      receiverDisplayName: PropTypes.string,
      senderId: PropTypes.string,
    })
  ),
};

export default Chats;
