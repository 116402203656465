import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";
import LibraryCard from "../../atoms/MeetingsLibraryCard";

const GridView = ({
  list,
  pathHandler,
  dropDown,
  meetingList,
  loadingMeetingList,
}) => {
  const [activeMenu, setActiveMenu] = useState(false);

  const closeAnyMenu = () => {
    setActiveMenu(false);
  };

  useEffect(() => {
    document.body.addEventListener("click", closeAnyMenu);

    return () => {
      document.body.removeEventListener("click", closeAnyMenu);
    };
  }, []);

  const meetingsListMap = () => {
    return meetingList.map(meeting => {
      return (
        <Link to= {`/meeting-repository/hostMeetings/sessions/${meeting.roomId}`}>
          <LibraryCard
            key='meetings'
            staticTitle={meeting.roomId}
            pathHandler={() => {}}
          />
        </Link>
      );
    });
  };

  return (
    <div className={styles.gridContainer}>
      {!loadingMeetingList && meetingList ? (
        <>
          {meetingsListMap()}
        </>
      ) : null}

      {list.map((d, i) => {
        return (
          <LibraryCard
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
            listIndex={i}
            pathHandler={pathHandler}
            {...d}
            key={i}
            dropDown={dropDown}
          />
        );
      })}
    </div>
  );
};

export default GridView;
GridView.propTypes = {
  list: PropTypes.array,
  pathHandler: PropTypes.func,
  dropDown: PropTypes.bool,
  meetingList: PropTypes.array,
  loadingMeetingList: PropTypes.bool,
};
