import * as types from "../commentTypes";

export const createComment = (action) => {
    return {
        type: types.CREATE_COMMENT,
        payload: action
    };
};

export const createCommentError = (action) => {
    return {
        type: types.GET_ALL_COMMENTS_ERROR,
        payload: action
    };
};


export const getAllComments = (action) => {
    return {
        type: types.GET_ALL_COMMENTS,
        payload: action
    };
};

export const getAllCommentsError = (action) => {
    return {
        type: types.GET_ALL_COMMENTS_ERROR,
        payload: action
    };
};

export const getEditedComment = (action) => {
    return {
        type: types.EDIT_COMMENT,
        payload: action
    };
};

export const getEditedCommentError = (action) => {
    return {
        type: types.EDIT_COMMENT_ERROR,
        payload: action
    };
};

export const deleteComment = (action) => {
    return {
        type: types.DELETE_COMMENT,
        payload: action
    };
};

export const deleteCommentError = (action) => {
    return {
        type: types.DELETE_COMMENT_ERROR,
        payload: action
    };
};


