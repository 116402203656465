import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";
import LibraryCard from "components/ui/atoms/LibraryCard";
import { parseDateStringTitle } from "components/helpers/utils";

const GridView = ({
  list,
  pathHandler,
  dropDown,
  sessionList,
  loadingMeetingList,
}) => {
  const [activeMenu, setActiveMenu] = useState(false);

  const closeAnyMenu = () => {
    setActiveMenu(false);
  };

  useEffect(() => {
    document.body.addEventListener("click", closeAnyMenu);

    return () => {
      document.body.removeEventListener("click", closeAnyMenu);
    };
  }, []);

  const sessionsListMap = () => {
    return list.map(session => {
      return (
        <Link to= {`/meeting-repository/hostMeetings/data/${session.sessionId}`}>
          <LibraryCard
            key='sessions'
            title={parseDateStringTitle(session.createdAt)}
            thumbnail=""
            pathHandler={() => {}}
          />
        </Link>
      );
    });
  };

  return (
    <div className={styles.gridContainer}>
      {/* {!loadingMeetingList && meetingList ? (
        <> */}
      {sessionsListMap()}
        {/* </>
      ) : null} */}

      {/* {list.map((d, i) => {
        return (
          <LibraryCard
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
            listIndex={i}
            pathHandler={pathHandler}
            {...d}
            key={i}
            dropDown={dropDown}
          />
        );
      })} */}
    </div>
  );
};

export default GridView;
GridView.propTypes = {
  list: PropTypes.array,
  pathHandler: PropTypes.func,
  dropDown: PropTypes.bool,
  sessionList: PropTypes.array,
  loadingMeetingList: PropTypes.bool,
};
