import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";

function Input({ label, placeholder, type, required, onChange, value, min, disabled, name, onKeyDown }) {
  return (
    <div className={styles.inputGroup}>
      <label>{label}</label>
      <input
        type={type}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        value={value}
        min={min}
        disabled={disabled}
        name={name}
        onKeyDown={onKeyDown}
      />
    </div>
  );
}
export default Input;
Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  min: PropTypes.any,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onKeyDown: PropTypes.func
};

Input.defaultProps = {
  onKeyDown: () => {},
};
