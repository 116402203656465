import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import { ReactComponent as Link } from "../../../../assets/images/link.svg";


const TableRowInterview = ({ name, date, organiser, image, rating, shortname, status, bgColor }) => {
    return (
        <tbody>
            <tr className={styles.row}>
                <td className={styles.name} data-label="Users">
                    <div className={styles.tdContent}>
                        <p style={{ backgroundColor: bgColor }}>{shortname}</p>
                        <span >
                            {name}
                        </span>
                    </div>
                </td>
                <td data-label="Rating">
                    <div>
                        {
                            rating.map(data => {
                                return <>{data}</>;
                            })
                        }
                    </div>
                </td>
                <td data-label="Date Added">{date}</td>
                <td data-label="Status">{status}</td>
                <td data-label="Organiser" className={styles.organiser}>
                    <div className={styles.tdContent}>
                        {organiser && <>
                            <img src={image} alt="" />
                            {organiser}
                        </>}
                    </div>
                </td>
                <td className={styles.lastItem}>
                    <Link />
                    <div></div>
                    <div></div>
                    <div></div>
                </td>
            </tr>
        </tbody>
    );
};

TableRowInterview.propTypes = {
    name: PropTypes.string,
    index: PropTypes.number,
    date: PropTypes.string,
    image: PropTypes.number,
    organiser: PropTypes.string,
    rating: PropTypes.number,
    shortname: PropTypes.string,
    status: PropTypes.string,
    bgColor: PropTypes.string,
};

export default TableRowInterview;
