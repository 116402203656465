import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import { ReactComponent as Plus } from "assets/images/plus.svg";

function MembersCard({ member, add, text, activeMenu, setActiveMenu, index }) {
  const openMenu = e => {
    e.preventDefault();
    setActiveMenu(index === activeMenu ? false : index);
  };
  return add && "first" ? (
    <div
      className={styles.addCard}
      style={{
        border: "1px dashed",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span className={styles.add}>
        <Plus className={styles.plus} />
      </span>
      <p>{text}</p>
      <div />
    </div>
  ) : (
    // All Items that we take from dummydata
    <div className={styles.card}>
      <button onClick={e => openMenu(e)}>
        <div className={styles.dotsContainer}>
          <span className={styles.dots} />
          <span className={styles.dots} />
          <span className={styles.dots} />
          {index === activeMenu && (
            <div className={styles.menu}>
              <ul>
                <li>Edit Team</li>
                <li>Access to host</li>
                <li>Access for meeting</li>
                <li>Delete</li>
              </ul>
            </div>
          )}
        </div>
      </button>
      <img src={member.img} alt={member.name} />
      <h2 className={styles.name}>{member.name}</h2>
      <p className={styles.info}>{member.info}</p>
    </div>
  );
}
MembersCard.propTypes = {
  member: PropTypes.object,
  add: PropTypes.string,
  text: PropTypes.string,
  index: PropTypes.number,
  setActiveMenu: PropTypes.func,
  activeMenu: PropTypes.any,
};

export default MembersCard;
