import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";

function OptionInput ({ list, id, extraClass, label, onChange, name, value }) {
  return (
    <div
      className={`${styles.OptionInput} ${styles[extraClass]}`}
      // onChange={e => onChange(e.target.value)}
    >
      {list ? (
        list.map((item, i) => {
          return (
            <div key={i}>
              <input
                type='radio'
                name={name}
                // value={value}
                onChange={e => {
                  console.log(e.target.name, "event log");
                  const syntheticEvent = {
                    ...e,
                    target: {
                      ...e.target,
                      value: item,
                      name: name,
                    },
                  };
                  onChange(syntheticEvent);
                }}
              />
              <label>{item}</label>
            </div>
          );
        })
      ) : (
        <div key={id}>
          <input
            onChange={e => {
              const syntheticEvent = {
                ...e,
                target: {
                  ...e.target,
                  name,
                  value: e.target.value
                },
              };
              onChange(syntheticEvent);
            }}
            type='radio'
            name={name}
          />
          <label>{label}</label>
        </div>
      )}
    </div>
  );
}
export default OptionInput;
OptionInput.propTypes = {
  id: PropTypes.number,
  list: PropTypes.array,
  extraClass: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
};
