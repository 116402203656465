import TeamsCardsContainer from "components/ui/molecules/TeamsCardsContainer";
import {teamsData} from "constants/AllTeams";
import React, {useState} from "react";
import Container from "../Container";
import SharedBanner from "components/ui/atoms/SharedBanner";
import SearchContainer from "components/ui/molecules/SearchContainer";
import Dashboard from "components/ui/molecules/Dashboard";

function AllTeams() {
  const [search, setSearch] = useState("");
  const [openDashboard, setOpenDashboard] = useState(false);
  const teamsKeys = ["name", "subgroup", "members"];

  const searchResult = (data) => {
    return (
      data.filter(team =>
        teamsKeys.some(
          key =>
            team[key].toString().toLowerCase().includes(search.toLocaleLowerCase())
        )
      )
    );
  };
  return (
    <>
      <SharedBanner
        extraClass="team"
        title="Team"
        description="You will find all your meeting recording, notes, chats. shared files saved up here in meeting folders."
      />
      <Container extraClassName="teamsContainer">
        <SearchContainer setSearch={setSearch}/>
        <TeamsCardsContainer
          invite={false}
          searchData={searchResult(teamsData)}
          setOpenDashboard={setOpenDashboard}
        />
      </Container>
      {openDashboard && <Dashboard setOpenDashboard={setOpenDashboard}/>}
    </>
  );
}

export default AllTeams;
