import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";

const Comments = ({ item, notAvailable }) => {

    return (
        <div className={`${styles.firstChat} ${notAvailable && styles.bigChat}`}>
            {item.online ?
                <div className={styles.imgStatus}>
                    <img src={item.image} alt="" />
                    <div className={`${styles.circle} ${styles.on}`}></div>
                </div> : notAvailable ? <img src={item.image} className={styles.imgBig} alt="" /> :
                    <div className={styles.imgStatus}>
                        <img src={item.image} alt="" />
                        <div className={`${styles.circle} ${styles.off}`}></div>
                    </div>
            }
            <div className={styles.nameDescription}>
                <div className={styles.infoIcons}>
                    <div className={styles.nameDate}>
                        <p>{item.name}</p>
                        <span>•</span>
                        <p className={styles.date}>{item.date}</p>
                    </div>
                    {item.icons ? <div className={styles.icons}>👏 👀 1</div> : null}
                </div>
                <p className={styles.desc}>{item.firstdescription}</p>
                <p>{item.seconddescription}</p>
            </div>
        </div>
    );
};

export default Comments;
Comments.propTypes = {
    item: PropTypes.array,
    notAvailable: PropTypes.bool
};