/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useRef, useEffect } from "react";
import StartRecordingPopup from "./SettingsPopup";
import RecordTabs from "./RecordTabs";
import SavePopup from "./SaveRecordingPopup";
import ModalSaveFile from "./SaveFileModal";
import styles from "./styles.module.scss";
import SharedBanner from "components/ui/atoms/SharedBanner";
import { successToast } from "../../atoms/Toasts/toasts";
var stream = null;
var audio = null;
var chunks = [];
var recorder = null;

// rectangle, roundedRectangle, square, roundedSqaure, circle
// topLeft, centerLeft, bottomLeft, topRight, centerRight, bottomRight

export default function VideoRecords() {
  const [showPopupConfig, setShowPopupConfig] = useState(false);
  const [recording, setRecording] = useState(false);
  const [finishedVideoReacrod, setFinishedVideoReacord] = useState(false);
  const [paused, setPaused] = useState(false);
  const [audioActive, setAudioActive] = useState(true);
  const [urlDonwloadFile, setUrlDonwloadFile] = useState("");
  const [blobFile, setBlobFile] = useState(null);
  const [saveRecorderInTheFolder, setSaveRecorderInTheFolder] = useState(false);
  const [videoName, setVideoName] = useState("");
  const [settings, setSettings] = useState({
    audio: true,
    video: true,
    camera: true,
    formOfCamera: "roundedSqaure",
    postionOfCamera: "bottomRight",
    start: false,
    flex: false,
  });
  const [time, setTime] = useState({
    minute: "00",
    second: "00",
  });
  const elemRef = useRef(null);
  const dragProps = useRef();

  function handelPopup() {
    setShowPopupConfig(!showPopupConfig);
  }

  // if camera is on after settings selection show it on the UI
  useEffect(() => {
    let video = document.querySelector("#video");
    if(recording && settings.camera) {
        video.srcObject = audio ? audio : null;
    } else {
      video.srcObject = null;
    }
  }, [recording, settings.camera]);

  async function setupStream() {
    try {
      settings.video
        ? (stream = await navigator.mediaDevices.getDisplayMedia({
            video: {
              width: 1280,
              height: 720,
            },
          }))
        : (stream = false);

      settings.audio || settings.camera
        ? (audio = await navigator.mediaDevices.getUserMedia({
            audio: settings.audio
              ? {
                  echoCancellation: true,
                  noiseSuppression: true,
                  sampleRate: 44100,
                }
              : false,
            video: settings.camera ? true : false,
          }))
        : (audio = false);
    } catch (err) {
      console.error(err);
    }
  }

  async function startRecording() {
    await setupStream();
    let mixedStream = null;

    if (audio || stream) {
      if (audio && stream) {
        mixedStream = new MediaStream([
          ...stream.getVideoTracks(),
          ...audio.getTracks(),
        ]);
      } else if (stream) {
        mixedStream = new MediaStream([...stream.getVideoTracks()]);
      } else if (audio) {
        mixedStream = new MediaStream([...audio.getTracks()]);
      } else {
        mixedStream = new MediaStream();
      }
      recorder = new MediaRecorder(mixedStream);
      recorder.ondataavailable = handleDataAvailable;
      recorder.onstop = handleStop;
      recorder.start(1000);
      document.querySelector("#root").requestFullscreen();
      setRecording(true);
      setShowPopupConfig(false);
      console.log("Recording started");
    } else {
      console.warn("No stream available.");
    }
  }

  function stopRecording() {
    recorder.stop();
    setRecording(false);
    setFinishedVideoReacord(true);
  }

  function handleDataAvailable(e) {
    chunks.push(e.data);
  }

  function handleStop() {
    const blob = new Blob(chunks, { type: "video/webm" });
    chunks = [];
    let downloadButton = null;
    downloadButton = URL.createObjectURL(blob);
    setUrlDonwloadFile(downloadButton);
    setBlobFile(blob);
    stream && stream.getTracks().forEach(track => track.stop());
    audio && audio.getTracks().forEach(track => track.stop());
    console.log("Recording stopped");
  }

  function pauseAndResumeRecording() {
    if (!recorder) return;
    if (recorder.state === "recording") {
      recorder.pause();
      setPaused(true);
    } else if (recorder.state === "paused") {
      recorder.resume();
      setPaused(false);
    }
  }

  function muteAndUnmute() {
    const track = audio.getAudioTracks();
    track[0].enabled = !audioActive;
    setAudioActive(!audioActive);
  }

  function settingsData(e) {
    setSettings({
      audio: e.audio,
      video: e.screen,
      camera: e.camera,
      formOfCamera: e.formOfCamera,
      postionOfCamera: e.positionCamera,
      start: true,
      flex: e.flex ? e.flex : false,
    });
  }

  const startDragging = ({ clientX, clientY }) => {
    elemRef.current.style.transform = `translate(${
      dragProps.current.dragStartLeft + clientX - dragProps.current.dragStartX
    }px, ${
      dragProps.current.dragStartTop + clientY - dragProps.current.dragStartY
    }px)`;
  };

  const stopDragging = () => {
    window.removeEventListener("mousemove", startDragging, false);
    window.removeEventListener("mouseup", stopDragging, false);
  };

  const initialiseDrag = event => {
    const { target, clientX, clientY } = event;
    const { offsetTop, offsetLeft } = target;
    const { left, top } = elemRef.current.getBoundingClientRect();

    dragProps.current = {
      dragStartLeft: left - offsetLeft,
      dragStartTop: top - offsetTop,
      dragStartX: clientX,
      dragStartY: clientY,
    };
    window.addEventListener("mousemove", startDragging, false);
    window.addEventListener("mouseup", stopDragging, false);
  };

  // console.log(settings.flex);
  return (
    <div className={styles.wrapper}>
      <div className={styles.topSection}>
        <SharedBanner
          extraClass="recordingBanner"
          extraClass1="recordings"
          title="Recordings"
          description="All your recordings will be saved here."
          button
          clickEvent={handelPopup}
        />
      </div>

      <div
        className={styles.videoRecords}
        style={{
          display: "flex",
          justifyContent: "center",
          userSelect: "none",
        }}
      >
        <video
          onMouseDown={settings.flex ? initialiseDrag : null}
          ref={settings.flex ? elemRef : null}
          className={`${styles[settings.postionOfCamera]} ${
            styles[settings.formOfCamera]
          } ${settings.flex ? styles.video : null}`}
          draggable={false}
          id="video"
          autoPlay
          muted
        ></video>

        {showPopupConfig && (
          <StartRecordingPopup
            settings={e => settingsData(e)}
            startRecord={startRecording}
            close={() => setShowPopupConfig(false)}
          />
        )}
        {recording && (
          <RecordTabs
            stopRecording={(minute, second) => {
              setTime({ minute: minute, second: second });
              stopRecording();
            }}
            recording={recording}
            pauseAndResumeRecording={pauseAndResumeRecording}
            paused={paused}
            audio={muteAndUnmute}
            audioSettings={settings.audio ? audio : null}
            audioActive={audioActive}
            cancelRecording={() => {
              recorder.stop();
              setRecording(false);
            }}
          />
        )}
        {finishedVideoReacrod && (
          <SavePopup
            close={() => {
              setFinishedVideoReacord(false);
            }}
            urlVideoFile={urlDonwloadFile}
            click={() => {
              setFinishedVideoReacord(false);
              setSaveRecorderInTheFolder(true);
            }}
            changeText={e => setVideoName(e)}
            time={time}
          />
        )}
        {saveRecorderInTheFolder && (
          <ModalSaveFile
            videoName={videoName ? videoName : "video"}
            blobFile={blobFile}
            url={urlDonwloadFile}
            stopVideo={() => setSaveRecorderInTheFolder()}
            click={() => {
              setSaveRecorderInTheFolder(false);
              successToast("Recording saved successfully");
            }}
          />
        )}
      </div>
    </div>
  );
}
