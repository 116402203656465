import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";

function SingleInput({ placeholder, type, required }) {
  return (
    <div className={styles.singleInput}>
      <input type={type} placeholder={placeholder} required={required} />
    </div>
  );
}
export default SingleInput;
SingleInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
};
