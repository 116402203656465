import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../../atoms/Popup";
import styles from "./style.module.scss";
import { ReactComponent as Linkedin } from "assets/images/linkedin-logo.svg";
import { ReactComponent as Fb } from "assets/images/fb-logo.svg";
import { ReactComponent as Whatsapp } from "assets/images/whatsapp-logo.svg";
import { ReactComponent as Twitter } from "assets/images/twitter logo.svg";
import MultiEmailsSharing from "../../atoms/MultiEmailsSharing";
import TextLink from "components/ui/atoms/TextLink";
import PopupButton from "../../atoms/Popup/PopupButton";
import PropTypes from "prop-types";
import { print } from "graphql/index";
import axiosInstance from "../../../../utils/axiosInstance";
import { getSharedUrl } from "../../../../redux/actions/videoActions";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";

import { SEND_MAIL_VIDEO_DASHBOARD } from "../../../../graphql/sendMailQuery";
import Cookies from "js-cookie";

function SharePopup({ color, id, closeMenu }) {
  const dispatch = useDispatch();
  const { sharing } = useSelector(s => s.video);

  const [emails, setEmails] = useState([]);
  const [popup, setPopup] = useState(false);
  const [link, setLink] = useState("");
  const [wrongInput, setWrongInput] = useState(false);

  const shareData = {
    videoId: id,
    emails: emails,
  };

  useEffect(() => {
    // get link for sharing
    if (!link) {
      dispatch(
        getSharedUrl({
          payload: shareData,
          callBack: value => setLink(value),
          withToast: false,
        })
      );
    } else {
      return;
    }
  }, []);



  const baseURL = process.env.REACT_APP_URL;
  const url = `${baseURL}/?intendedURL=/video-player/${id}&shared=true`;
  console.log(url, "-------url------");
  const text = "A video has been shared with you, click to watch it";
  const encodedUrl = encodeURIComponent(url);
  const encodedText = encodeURIComponent(text);

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
  const whatsappShareUrl = `https://wa.me/?text=${encodedText} ${encodedUrl}`;
  const linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedUrl}`;

  const socialIcons = [
    <a href={facebookShareUrl} target="_blank" rel="noopener noreferrer">
      <Fb />
    </a>,

    <a href={twitterShareUrl} target="_blank" rel="noopener noreferrer">
      <Twitter />
    </a>,

    <a href={linkedinShareUrl} target="_blank" rel="noopener noreferrer">
      <Linkedin />
    </a>,

    <a href={whatsappShareUrl} target="_blank" rel="noopener noreferrer">
      <Whatsapp />
    </a>,
  ];

  const hidePopup = () => {
    setPopup(false);
    closeMenu && closeMenu();
  };

  const openPopup = () => {
    setPopup(true);
  };

  const callBack = value => {
    const Id = value.split("/")[5];
    const url = `${process.env.REACT_APP_URL}/?intendedURL=/video-player/${Id}?shared=true`;
    const trimmedUrl = url.replace(/(\r\n|\n|\r)/gm, "");
    navigator.clipboard.writeText(trimmedUrl);
    hidePopup();
  };


  //send mails here
  const sendEmails = async () => {
    if (emails.length) {
      dispatch(getSharedUrl({payload: shareData, callBack: () => hidePopup(), withToast: true, toastTxt: "Emails sent!", errTxt: "Failed to send emails!"}));

      const mappedInput = emails.map(email => ({
        mail: email,
        username: email?.split("@")[0],
        isScheduled: false,
        link: url
      }));

      try {

        // const authToken = localStorage.getItem("authToken").trim();
        const authToken = Cookies.get("authCode");
        const serverUrl = process.env.REACT_APP_SERVER_URL;
        console.log(serverUrl, "-----surl----");
        const { data } = await axiosInstance.post(serverUrl, {
          query: print(SEND_MAIL_VIDEO_DASHBOARD),
          headers: { "Content-Type": "application/json", Authorization: authToken },
          variables: { input: mappedInput },
        });
      } catch (error) {
        console.log(error, "------error-------sendMailVideoDashboard");
      }
    } else {
      setWrongInput(true);
      // when there are no emails
    }
  };

  return (
    <>
      <PopupButton title="Share" openPopup={openPopup} extraClass={[color]} />
      <Popup removePopup={hidePopup} title="Share" state={popup}>
        <h4>Add people</h4>
        <MultiEmailsSharing
          text="Share"
          withButton
          placeholder="Enter or paste email addresses separeted by comma"
          mailList={emails}
          mailListSetter={setEmails}
          handler={sendEmails}
          error={wrongInput}
          setError={setWrongInput}
          sharing={sharing}
        />
        <h4>Or share this via</h4>
        <div className={styles.icons}>
          {socialIcons.map((line, i) => {
            return (
              <div key={i} className={styles.iconBg}>
                {line}
              </div>
            );
          })}
        </div>
        <TextLink
          title="Get Embedded Link"
          paragraph="Anyone with the link can view"
          link="Copy Link"
          icon
          onClick={() =>
            dispatch(
              getSharedUrl({ payload: shareData, callBack, withToast: true })
            )
          }
        />
      </Popup>
    </>
  );
}

SharePopup.propTypes = {
  color: PropTypes.string,
  id: PropTypes.string,
  closeMenu: PropTypes.func,
};

export default SharePopup;
