import { VideoPlayer } from "@master-wizr/video-player";
import "@master-wizr/video-player/dist/index.css";
import PropTypes from "prop-types";

const WizrVideoPlayer = ({ src }) => <VideoPlayer src={src} />;

WizrVideoPlayer.propTypes = {
  src: PropTypes.string,
};

export default WizrVideoPlayer;
