import React from "react";
import PropTypes from "prop-types";

function Icon({ onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      onClick={onClick}
    >
      <path
        stroke="#DCD6D6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 19l-7-7m0 0l7-7m-7 7h18"
      ></path>
    </svg>
  );
}

Icon.propTypes = {
  onClick: PropTypes.func
};

export default Icon;
