import axiosInstance from "utils/axiosInstance";
import * as actions from "./actionCreators/userDataActionCreators";
import {
  successToast,
  errorToast,
} from "../../components/ui/atoms/Toasts/toasts";

export const getUserData = async (dispatch) => {
  dispatch(actions.getUserDataInit());
  try {
    const res = await axiosInstance.get(`/api/v1/user/profile/`);
    dispatch(actions.getUserDataSuccess(res.data.data));
  } catch (error) {
    dispatch(actions.getUserDataError(error));
  }
};

export const createUserData = async (dispatch, params, callback) => {
  dispatch(actions.createUserDataInit());
  try {
    const res = await axiosInstance.post(`/api/v1/user/profile`, params);
    callback && callback();
    dispatch(actions.createUserDataSuccess(res.data.data));
    successToast("Profile updated successfully");
  } catch (error) {
    dispatch(actions.createUserDataError(error));
    errorToast("Could not update your profile data");
  }
};