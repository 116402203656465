import Cookies from "js-cookie";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

function SharedVideo () {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector(state => state.authReducer);
  const { id } = useParams();
  useEffect(() => {
 
    if (isAuthenticated) {
      navigate(`/video-player/${id}?shared=true`);
    }
  }, [id, isAuthenticated, navigate]);

  return null;
}

export default SharedVideo;
