import { useEffect } from "react";
import { useSelector } from "react-redux";
import removeComment from "../../../../../redux/reducers/utilities/removeComment";

const useDeleteComment = (setAllComments, allComments, setTotalComments) => {
    const deletedComment = useSelector((state) => state?.comments?.deletedComment);
    useEffect(() => {
        if (!deletedComment?._id) return null;
        const filteredComments = removeComment(allComments, deletedComment?._id);
        setAllComments(filteredComments);
        return setTotalComments((previousState) => (Number(previousState - 1)));

    }, [deletedComment?._id, setAllComments, setTotalComments]);
};

export default useDeleteComment;
