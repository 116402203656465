import { useEffect } from "react";
import { useSelector } from "react-redux";

const useAddNewComments = (allComments, setAllComments, setTotalComments) => {
    const newComment = useSelector((state) => state?.comments?.newComment);
    useEffect(() => {
        if (!newComment?._id) return null;
        if (newComment?._id) {
            setAllComments((previousState) => ([ ...previousState, newComment ]));
            return setTotalComments((previousState) => (Number(previousState + 1)));
        }
    }, [newComment, newComment?._id, setAllComments, setTotalComments]);
};

export default useAddNewComments;
