import React from "react";
import sketch from "../../../../assets/icons/pdf.png";
import pdf from "../../../../assets/icons/pdf.png";
import vector from "../../../../assets/icons/vector.png";
import png from "../../../../assets/icons/png.png";
import zip from "../../../../assets/icons/zip.png";
import PropTypes from "prop-types";

const icons = {
  sketch: sketch,
  pdf: pdf,
  docx: vector,
  png: png,
  zip: zip,
};
function Icons({ type }) {
  return <img src={icons[type]} alt="" />;
}

Icons.propTypes = {
  type: PropTypes.any,
};

export default Icons;
