import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/ui/atoms/Loader";
import TableView from "components/ui/molecules/PollsListTableView/PollsListTableView";
import GridView from "components/ui/molecules/GridViewPolls";
import NothingToDisplay from "components/ui/atoms/NothingToDisplay";
// import Pagination from "components/ui/atoms/Pagination";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { getPolls, getSessionPolls } from "../../../../redux/actions/pollsActions";
import { selectFilteredPolls } from "redux/selectors";
import Pagination from "components/ui/atoms/Paginate/Index";

function Polls ({ type = "row" }) {
  const { user: { _id }} = useSelector(_state => _state.authReducer);
  const { loading } = useSelector(state => state.polls);
  const { data,
    page: currentPage,
    perPage: itemsPerPage,
    totalPolls: totalItems,
    totalPages} = useSelector(selectFilteredPolls);
  const dispatch = useDispatch();
  const { id: sessionId } = useParams();

  useEffect(() => {
    if (sessionId) {
      dispatch(getSessionPolls(sessionId));
    } else {
      dispatch(getPolls(_id, currentPage, itemsPerPage));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage]);

  const [parent, setParent] = useState(0);
  const [history, setHistory] = useState([0]);
  const [filterArray, setFilterArray] = useState([]);
  const [asc, setAsc] = useState(false);
  const [_data, setData] = useState(null);

  const navigate = useNavigate();

  const sortAtoZ = type => {
    let sortedArray = [...filterArray];
    setAsc(!asc);
    sortedArray.sort((a, b) => {
      const isReversed = asc ? -1 : 1;
      return isReversed * a[type].localeCompare(b[type]);
    });
    setFilterArray(sortedArray);
  };

  const filterHandler = type => {
    switch (type) {
      case "roomId":
      // case "createdAt":
      // case "author":
        sortAtoZ(type);
        break;
      default:
        return filterArray;
    }
  };

  const setPathFromChild = (index, type) => {
    if (type === "folder") {
      setParent(index);
      let historyArray = [...history, index];
      setHistory(historyArray);
    } else {
      navigate(`/polls/${index}`);
    }
  };

  const filterParentData = tableData => {
    let filter = tableData
      .map(item => ({ ...item, parent: 0 }))
      .filter(a => {
        return a.parent === parent;
      })
      .sort((a, b) => {
        const isReversed = -1;
        if (a.type && b.type) {
          return isReversed * a.type.localeCompare(b.type);
        }
        return 0;
      });
    setFilterArray(filter);
  };

  useEffect(() => {
    if (!loading && data.length) {
      filterParentData(data);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parent, data]);

  const handleMoveToFolder = result => {
    const newArray = [..._data];

    const arrayToSave = newArray.map(item => {
      if (item.index === parseInt(result.draggableId)) {
        return {
          ...item,
          parent: parseInt(result.destination.droppableId),
        };
      } else {
        return item;
      }
    });

    setData(arrayToSave);

    filterParentData(arrayToSave);
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        !loading &&
        (data.length > 0 ? (
          <>
            {type === "row" ? (
              <TableView
                filterHandler={filterHandler}
                list={filterArray}
                pathHandler={setPathFromChild}
                moveToFolder={handleMoveToFolder}
              />
            ) : (
              <GridView list={filterArray} pathHandler={setPathFromChild} />
            )}
            <Pagination currentPage={currentPage} data={data} itemsPerPage={itemsPerPage} onPageChange={(page) => dispatch(getPolls(_id, page, itemsPerPage))} totalItems={totalItems} totalPages={totalPages} />
          </>
        ) : (
          <NothingToDisplay />
        ))
      )}
    </Fragment>
  );
}

Polls.propTypes = {
  type: PropTypes.string,
};

export default Polls;
