import React, { useState, useRef } from "react";
import moment from "moment";
import styles from "./style.module.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import SharedButton from "components/ui/atoms/SharedButton";
import { ReactComponent as Comments } from "../../../../assets/images/comments.svg";
import LinkShareIcon from "../../../../assets/icons/LinkShareIcon";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { startCreateComment } from "../../../../redux/actions/commentsActions";
import useGetAuthenticatedUser from "../Authentication/useHooks/useGetAuthenticatedUser";
import useCloseMenu from "./hooks/useCloseMenu";
import useSetState from "../../atoms/Pagination/hooks/useSetState";
import useSetAllComments from "../../atoms/Pagination/hooks/useSetAllComments";
import useUpdateState from "../../atoms/Pagination/hooks/useUpdateState";
import useUpdateAllComments from "../../atoms/Pagination/hooks/useUpdateAllComments";
import useAddNewComments from "../../atoms/Pagination/hooks/useAddNewComments";
import useGetTotalComments from "../../atoms/Pagination/hooks/useGetTotalComments";
import useEditComment from "../../atoms/Pagination/hooks/useEditComment";
import useDeleteComment from "../../atoms/Pagination/hooks/useDeleteComment";
import Comment from "../Comment";
import { errorToast } from "../../atoms/Toasts/toasts";
import fetchComments from "../../atoms/Pagination/utilities/functions/fetchComments";
import DropDownMenu from "components/ui/atoms/DropDownMenu";
import {getSharedUrl} from "redux/actions/videoActions";
import Avatar from "components/ui/atoms/Avatar";

function CommentsContainer() {
  const dispatch = useDispatch();
  const params = useParams();
  const { isFetching, video } = useSelector(state => state?.video);
  const [totalComments, setTotalComments] = useState(0);
  const [state, setState] = useState([]);
  const [allComments, setAllComments] = useState([]);
  const [query, setQuery] = useState();
  const [dropdown, setDropdown] = useState(false);
  const [commentsPart, setCommentsPart] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const LIMIT = 10;
  const menuRef = useRef();
  const user = useGetAuthenticatedUser();
  const { user: {_id}} = useSelector(state => state.authReducer);

  useGetTotalComments(setTotalComments);
  useCloseMenu(menuRef, setDropdown);
  useSetState(state, setState);
  useSetAllComments(setAllComments, totalComments);
  useUpdateState(totalComments, setState);
  useUpdateAllComments(state, setAllComments);
  useAddNewComments(allComments, setAllComments, setTotalComments);
  useEditComment(setAllComments, allComments);
  useDeleteComment(setAllComments, allComments, setTotalComments);

  const fetchData = async () => {
    if (allComments?.length >= totalComments) return setHasMore(false);
    const comments = await fetchComments(video._id, LIMIT, page);
    setAllComments([...allComments, ...comments]);

    if (comments?.length === 0 || comments?.length < LIMIT) {
      setHasMore(false);
    }
    setPage(page + 1);
  };

  const submitComment = async () => {
    const time = moment().format("YYYY-MM-DD HH:mm:ss");
    console.log(time, "time");
    const data = { time, videoId: params.id, comment: query, ...user };
    if (!query) return errorToast("Please, enter the comment you want to add!");
    dispatch(startCreateComment(data));
    setQuery(" ");
  };

  const updateQuery = ({ target }) => {
    setQuery(target.value);
  };
  const submitHandler = e => {
    e.preventDefault();
  };

  const CopyLinkToClipBoard = value => {
    const Id = value.split("/")[5];
    const url = `${process.env.REACT_APP_URL}/?intendedURL=/video-player/${Id}?shared=true`;
    const trimmedUrl = url.replace(/(\r\n|\n|\r)/gm, "");
    navigator.clipboard.writeText(trimmedUrl);
  };

  const getVideoShareLink = () => {
    dispatch(getSharedUrl({payload: {
      videoId: video._id,
      emails: [],
    }, callBack: CopyLinkToClipBoard, withToast: true}));
  };

  return (
    <>
      {!isFetching && (
        <>
          <div className={styles.comments} style={{ margin: "0px 10px" }}>
            <h2>{video?.title}</h2>
            <div className={styles.inlineButtons}>
              <button class={styles.largeComments} onClick={() => setCommentsPart(!commentsPart)}>
                <p className={styles.paragraph}>
                  <Comments className={styles.icons} /> Comments (
                  {totalComments})
                </p>
              </button>
              <button>
                <p className={styles.paragraph}>
                  {" "}
                  <LinkShareIcon onClick={getVideoShareLink} className={styles.icons} />{" "}
                </p>
              </button>

              <button
                onClick={() => setDropdown(!dropdown)}
                className={styles.dots}
                ref={menuRef}
              >
                <div />
                <div />
                <div />
              </button>
              {dropdown && (
                  <DropDownMenu
                    id={params.id}
                    activeMenu={dropdown}
                    setActiveMenu={setDropdown}
                    toUp={true}
                    onVideoPlayer={true}
                  />
              )}
            </div> 
          </div>
          <div class={styles.mobileComments}>
            <button onClick={() => setCommentsPart(!commentsPart)}>
              <p className={styles.paragraph}>
                <Comments className={styles.icons} /> Comments (
                {totalComments})
              </p>
            </button>
          </div>
          {commentsPart && (
            <div className={styles.commentsPart} style={{ margin: "0px 10px" }}>
              <InfiniteScroll
                next={fetchData}
                hasMore={hasMore}
                dataLength={allComments?.length}
                className={styles.commentsPart}
                style={{ width: "600px" }}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>
                      {allComments?.length === 0
                        ? "This video has no comments"
                        : "You have seen all the comments"}
                    </b>
                  </p>
                }
                loader={""}
              >
                {allComments?.map(item => {
                  return <Comment key={item._id} comment={item} showMenu={item.userId.toString() === _id.toString()} />;
                })}
              </InfiniteScroll>
              <form onSubmit={submitHandler}>
                <div className={styles.commentWrapper}>
                  <div className={styles.title}>
                    <Avatar name={user?.author} image={null}/>
                    <h4>{user.author}</h4>
                  </div>
                  <textarea
                    rows={5}
                    value={query}
                    onChange={updateQuery}
                    style={{ resize: "none" }}
                    required
                  />
                  <SharedButton clickEvent={submitComment} extraClass="grayBtn">
                    Add Comment
                  </SharedButton>
                </div>
              </form>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default CommentsContainer;
