// selectors.js
export const selectFilteredVideos = state => {

   // Extract the values from the reducer
   const numerator = state?.video?.videos?.totalVideos;
   const denominator = state?.video?.videos?.perPage;

   // Calculate totalPages
   const totalPages = denominator !== 0 ? numerator / denominator : 1;

   const { page, perPage, totalVideos } = state?.video?.videos;

  let searchText = state.SearchReducer.searchText;

  let filteredVideos =  state?.video?.videos?.data?.filter(video =>
    video.title.toLowerCase().includes(searchText.toLowerCase())
  );

  return {
    data: filteredVideos,
    totalPages: Math.ceil(totalPages),
    page,
    perPage,
    totalVideos,
  };
};

export const selectFavouriteFilteredVideos = state => {
  let searchText = state.SearchReducer.searchText;

  // Extract the values from the reducer
  const numerator = state.video.favouriteVideos.total;
  const denominator = state.video.favouriteVideos.perPage;

  // Calculate totalPages
  const totalPages = denominator !== 0 ? numerator / denominator : 1;

  const { page, perPage, total } = state.video.favouriteVideos;

  let favouriteVideos = state.video.favouriteVideos?.data?.videos?.filter(video =>
    video.title.toLowerCase().includes(searchText.toLowerCase())
  ) || [];

  return {
    data: {
      videos: favouriteVideos
    },
    totalPages: Math.ceil(totalPages),
    page,
    perPage,
    totalFavouriteVideos: total,
  };
};

export const selectArchivedFilteredVideos = state => {
  let searchText = state.SearchReducer.searchText;

  // Extract the values from the reducer
  const numerator = state.video.archivedVideos.totalArchivedVideos;
  const denominator = state.video.archivedVideos.perPage;

  // Calculate totalPages
  const totalPages = denominator !== 0 ? numerator / denominator : 1;

  const { page, perPage, totalArchivedVideos } = state.video.archivedVideos;

  let filteredArchivedVideos = state.video.archivedVideos?.data?.filter(video =>
    video.title.toLowerCase().includes(searchText.toLowerCase())
  );

  return {
    data: filteredArchivedVideos,
    totalPages: Math.ceil(totalPages),
    page,
    perPage,
    totalArchivedVideos,
  };
};

export const selectFilteredHostMeetings = state => {
  let searchText = state.SearchReducer.searchText;

  // Extract the values from the reducer
  const numerator = state.meetingRepository.hostMeetings.totalMeetings;
  const denominator = state.meetingRepository.hostMeetings.perPage;

  // Calculate totalPages
  const totalPages = denominator !== 0 ? numerator / denominator : 1;

  const { page, perPage, totalMeetings } = state.meetingRepository.hostMeetings;

  let filteredMeetings = state.meetingRepository.hostMeetings?.data?.filter(
    item => item._id.toLowerCase().includes(searchText.toLowerCase())
  );

  return {
    data: filteredMeetings,
    totalPages: Math.ceil(totalPages),
    page,
    perPage,
    totalMeetings,
  };
};

export const selectSharedWithMeVideos = state => {
  let searchText = state.SearchReducer.searchText;
  return state.video.sharedWithMe.filter(video =>
    video.title.toLowerCase().includes(searchText.toLowerCase())
  );
};

export const selectFilteredPolls = state => {
  let searchText = state.SearchReducer.searchText;

  const numerator = state.polls.totalPolls;
  const denominator = state.polls.perPage;

  // Calculate totalPages
  const totalPages = denominator !== 0 ? numerator / denominator : 1;

  const { page, perPage, totalPolls } = state.polls;

  let filteredPolls = state.polls?.data?.filter(({ pollTopic }) =>
    pollTopic.toLowerCase().includes(searchText.toLowerCase())
  );

  return {
    data: filteredPolls,
    totalPages: Math.ceil(totalPages),
    page,
    perPage,
    totalPolls,
  };
};

export const selectVideoById = (state, videoId) => {
  const searchInArray = arr =>
    arr.find(({ _id }) => _id.toString() === videoId.toString());

  return (
    searchInArray(state?.video?.videos?.data) ||
    searchInArray(state.video.archivedVideos.data) ||
    searchInArray(state.video.favouriteVideos.data.videos)
  );
};

export const selectFilteredHostMeetingRecordings = state => {
  let searchText = state.SearchReducer.searchText;
  return state.meetingRepository.hostMeetingRecordings.filter(({ title }) =>
    title.toLowerCase().includes(searchText.toLowerCase())
  );
};

export const selectFilteredSubHostMeetings = state => {
  let searchText = state.SearchReducer.searchText;
  return state.meetingRepository.subHostMeetings.filter(({ _id }) =>
    _id.toLowerCase().includes(searchText.toLowerCase())
  );
};
