import React from "react";
import PropTypes from "prop-types";

function Icon({onClick}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      onClick={onClick}
    >
      <path fill="#777" d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
      <path
        fill="#777"
        fillRule="evenodd"
        d="M.457 10c1.274-4.057 5.065-7 9.542-7 4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7zm13.542 0a4 4 0 11-8 0 4 4 0 018 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

Icon.propTypes = {
    onClick: PropTypes.func
};

export default Icon;