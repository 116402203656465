import React from "react";
import style from "./styles.module.scss";
import img from "../../../../assets/images/carosel_img.png";

export default function index({ items, item, setActiveVideo }) {
  const handleActive = id => {
    setActiveVideo(items[id - 1]);
  };
  return (
    <div
      className={style.carouselWraper}
      onClick={() => handleActive(item.id)}
      onKeyDown={() => handleActive(item.id)}
      role="button"
      tabIndex={0}
    >
      <div className={style.img}>
        <img src={img} alt="img" />
        <span>{item.time}</span>
      </div>
      <div className={style.desc}>
        <h2>{item.question}</h2>
        <p>{item.description}</p>
      </div>
    </div>
  );
}
