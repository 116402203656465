import { getProfilesInit, getProfilesSuccess, getProfilesError } from "./actionCreators/profileActionCreators";
import axios from "axios";
import Cookies from "js-cookie";

export const getUserProfiles = (userId) => async dispatch => {
    const token = Cookies.get("authCode");

    const PROFILES_BY_USER_ID = `
            query userProfilesQuery($id: ID!) {
                user(id: $id) {
                ... on User {
                    _id
                    firstName
                    lastName
                    otherNames
                    email
                    twoFactorAuth {
                    enabled
                    method
                    }
                    location
                    telephone
                    profiles {
                    _id
                    jobPosition
                    profilePhoto
                    isDefaultAccount
                    accessedModules {
                        name
                        role
                    }
                    accessedPortals {
                        portalId
                    }
                    accountId
                    accountRole
                    accountInfo {
                        _id
                        name
                        createdAt
                        location
                        creator
                        website
                        info
                        contact
                        theme
                        licenceInfo {
                        licenceType
                        dateOfExpiration
                        }
                        logoUrl
                        logoShape
                    }
                    userInfo {
                        signupMethods
                    }
                    }
                }
                }
            }
        `;
    dispatch(getProfilesInit());
    try {
        const { data } = await axios({
            url: process.env.REACT_APP_SERVER_URL,
            method: "post",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`
            },
            data: {
                query: PROFILES_BY_USER_ID,
                variables: { id: userId },
            }
        });
        dispatch(getProfilesSuccess(data.data.user));
    } catch (error) {
        dispatch(getProfilesError(error));
    }
};
