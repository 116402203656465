import card1 from "assets/images/home-card-1.webp";
import card2 from "assets/images/home-card-2.webp";
import card3 from "assets/images/home-card-3.webp";
import card4 from "assets/images/home-card-4.webp";

export const homeCards = [
  {
    image: card1,
    title: "Actions",
    description:
      "You have the power of the WiZR Connect from the Dashboard. You can take multiple actions from just once place. Simply easy.",
    link: "/", // Get actual redirect url
  },
  {
    image: card2,
    title: "Personal Library",
    description:
      "Several ways of taking care of your video & multimedia content. Personal, where only you can view. Favorites when you want to easy find material and Archived for the rest.",
    link: "/personal-library",
  },
  {
    image: card3,
    title: "Overview",
    description:
      "Easily get an overview of meetings you have had and important details. Convenient to navigate your past meetings and finding what you are looking for.",
    link: "/meeting-repository",
  }
];
