import {
    GET_USER_DATA,
    UPDATE_USER_DATA
} from "../actionTypes";

export const getUserDataInit = () => {
  return {
    type: GET_USER_DATA.INIT,
  };
};

export const getUserDataSuccess = data => {
  return {
    type: GET_USER_DATA.SUCCESS,
    payload: data,
  };
};


export const getUserDataError = error => {
  return {
    type: GET_USER_DATA.ERROR,
    payload: error,
  };
};

export const createUserDataInit = () => {
  return {
    type: UPDATE_USER_DATA.INIT,
  };
};

export const createUserDataSuccess = data => {
  return {
    type: UPDATE_USER_DATA.SUCCESS,
    payload: data,
  };
};

export const createUserDataError = error => {
  return {
    type: UPDATE_USER_DATA.ERROR,
    payload: error,
  };
};