import React from "react";
import styles from "./style.module.scss";
export function NothingToDisplay(){
    return(
        <div className={styles.wrapper}>
        <p>Nothing saved yet </p>
        </div>
    );
}

export default NothingToDisplay;
