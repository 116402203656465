import axios from "axios";
import Cookies from "js-cookie";

const axiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? process.env.REACT_APP_BASE_URL
      : "http://localhost:3001",
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  config => {
    const token = Cookies.get("authCode");
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // localStorage.removeItem("authToken");
      // Cookies.remove("authCode");
      const urlString = process.env.REACT_APP_URL.split(".");
      const  [_, ...lastTwoParts] = urlString;
      const remainder = `.${lastTwoParts.join(".")}`.split(':')[0];
      const domain = remainder;
      Cookies.remove("authCode", { domain });
      const loginUrl = "/";
      if (window.location.href !== loginUrl) {
        window.location.href = loginUrl;
        return;
      }
    }
    // eslint-disable-next-line consistent-return
    return Promise.reject(error);
  }
);

export default axiosInstance;
