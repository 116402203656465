import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import SearchBar from "../SearchBar";

function SearchContainer({ subTeam,team, setSearch }) {
  return (
    <div
      className={styles.teamSearchbar}
    >
      <div className={styles.title}>
        <h2 className={subTeam ? styles.dark : ""}>Dashboard Team </h2>
        {subTeam && (
          <h2 style={{ color: "#C1C1C1", marginRight:"10px" }}>
            &nbsp;{">"}&nbsp;{subTeam}
          </h2>
        )}
        {team && (
          <h2 style={{ color: "#C1C1C1" }}>
            &nbsp;{">"}&nbsp;{team}
          </h2>
        )}
      </div>
      <SearchBar setSearch={setSearch} />
    </div>
  );
}

SearchContainer.propTypes = {
  setSearch: PropTypes.func,
  subTeam: PropTypes.string,
  team: PropTypes.string,
};

export default SearchContainer;
