import React, { useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import styles from "./style.module.scss";
import { ReactComponent as ArrowRight } from "assets/images/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "assets/images/arrow-left.svg";
import { ReactComponent as Close } from "assets/images/close2.svg";
import { ReactComponent as FullScreenIcon } from "assets/images/icon-fullscreen.svg";
import { ReactComponent as Exitfullscreen } from "assets/images/exitfullscreen.svg";
import { ReactComponent as Slides } from "assets/images/icon-slides.svg";
import { guideData } from "constants/guideData";
import Carousel from "react-elastic-carousel";

function Container() {
  const [index, setIndex] = useState(1);
  const [state, setState] = useState(false);
  const handle = useFullScreenHandle();
  const increaseIndex = () => {
    index === guideData.length ? setIndex(1) : setIndex(index + 1);
  };
  const decreaseIndex = () => {
    index === 1 ? setIndex(guideData.length) : setIndex(index - 1);
  };

  const breakPoints = [
    { width: 550, itemsToShow: 3, pagination: false, showArrows: false },
    { width: 768, itemsToShow: 4, pagination: false, showArrows: false },
    { width: 1000, itemsToShow: 6, pagination: false, showArrows: false },
    { width: 1500, itemsToShow: 10, pagination: false, showArrows: false },
  ];

  const [isOpen, setIsOpen] = useState();
  const [isShowed, setIsShowed] = useState("Show Slider off");

  const ShowSliders = (bool) => {
    if (isOpen === bool) {
      setIsOpen(false);
      setIsShowed("Show Slider off");
    }
    else {
      setIsOpen(bool);
      setIsShowed("Show Slider on");
    }
  };

  const [isOpenmode, setIsOpenmode] = useState(true);

  return (
    <div className={state ? `${styles.container} ${styles.fullScreenMode}` : `${styles.container}`}>
      <FullScreen handle={handle}>
        <div className={styles.content}>
          {state && isOpenmode ?
            <div className={styles.modeChanging}>
              <div>
                <h3>Warning</h3>
                <p>Please continue using application in Landscape mode for better view</p>
              </div>
              <Close onClick={() => setIsOpenmode(false)} />
            </div>
            : null}
          <div className={styles.slides}>
            <div className={styles.arrow}>
              <ArrowLeft onClick={decreaseIndex} />
            </div>
            {guideData.map((item, i) => {
              return (
                <div key={i}>
                  {index === item.index && (
                    <img key={i} src={item.image} alt="slide" />
                  )}
                </div>
              );
            })}
            <div className={`${styles.arrow} ${styles.rightArrow}`}>
              <ArrowRight onClick={increaseIndex} />
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.pages}>
              <h3>
                {index}/{guideData.length}
              </h3>
            </div>
            {state ?
              <div className={styles.slider}>
                <Carousel breakPoints={breakPoints} className={`${styles.carouseli} ${isOpen === true && styles.isHovered}  ${isOpen === false && styles.notHovered}`} style={{ opacity: isOpen ? "1" : "" }}>
                  {guideData.map((item, i) => {
                    return (
                      <div aria-hidden={true} role="button" key={i} className={styles.imgWrapper} onClick={() => setIndex(item.index)}>
                        <img key={i} src={item.image} className={index === item.index ? styles.activeImage : styles.imgStyle} alt="slide-img" />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
              : null}
            <div className={styles.iconsRight}>
              {state ?
                <div aria-hidden={true} role="button" className={styles.slidesShow} onClick={() => ShowSliders(true)}>
                  <div className={styles.tooltip}>
                    {isShowed}
                  </div>
                  <Slides />
                </div>
                : null}
              <div className={styles.fullScreen}>
                <div className={styles.tooltip}>
                  {!state ? "Go Fullscreen" : "Exit Fullscreen"}
                </div>
                {!state ? <FullScreenIcon onClick={() => { handle.enter(); setState(true) }} /> : <Exitfullscreen onClick={() => { handle.exit(); setState(false) }} />}
              </div>
            </div>
          </div>
        </div>
      </FullScreen>
    </div>
  );
}
export default Container;
