import { gql } from "graphql-tag";

const MAIL_INPUT_TYPE = gql`
  input sendMailDashboardInput {
    mail: String!
    username: String!
    isScheduled: Boolean!
    link: String!
  }
`;


export const SEND_MAIL_VIDEO_DASHBOARD = gql`
  mutation SendMailVideoDashboard($input: [sendMailDashboardInput!]!) {
    sendMailVideoDashboard(input: $input) {
      success
      status
      message
    }
  }
`;
