import React from "react";
import TopNavigation from "components/ui/atoms/TopNavigation/index";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import UniversalMenuComponent from "components/ui/organisms/UniversalMenu/UniversalMenu";
import { useBackgroundImage } from "../BackgroundImage/BackgroundImageProvider";

function ProtectedPanel({ children }) {
  
  const backgroundImage = useBackgroundImage();

  return (
    <div className={styles.protectedPanel} style={{ backgroundImage: `url(${backgroundImage})` }}>
      <TopNavigation />
      <UniversalMenuComponent />
      {children}
    </div>
  );
}
export default ProtectedPanel;
ProtectedPanel.propTypes = {
  children: PropTypes.node,
  leftMenu: PropTypes.bool,
};
