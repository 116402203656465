/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import styles from "./style.module.scss";
import Icons from "./Icons";
import PropTypes from "prop-types";
import EyeIcon from "assets/icons/Eye";
import FileDownloadIcon from "assets/icons/FileDownload";
import FileDeleteIcon from "assets/icons/FileDelete";
import { errorToast, successToast } from "components/ui/atoms/Toasts/toasts";

const DownloadButton = ({ assetLink, desiredFilename }) => {
  const handleDownload = async () => {
    try {
        const response = await fetch(assetLink);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const blob = await response.blob();
        const blobURL = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = blobURL;
        link.download = desiredFilename || "download.jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Optional: Release the blob URL after a short delay
        setTimeout(() => {
            URL.revokeObjectURL(blobURL);
        }, 100);

        successToast("File downloaded successfully");
    } catch (error) {
        errorToast("There was an error downloading the file");
        console.error("There was a problem with the fetch operation:", error);
    }
};
  return <FileDownloadIcon onClick={handleDownload} />;
};

DownloadButton.propTypes = {
  assetLink: PropTypes.string,
  desiredFilename: PropTypes.string,
};

function SharedFolder ({ item, setFileAndShowPreview }) {
  return (
    <div className={styles.sharedfolder}>
      <div className={styles.image}>
        <Icons type={'png'} />
        <Icons type={item.location.split("/").pop().split(".")[0]} />
      </div>
      <div className={styles.information}>
        <div className={styles.block}>
          {/* <h5>{item.roomFile.fileName}</h5> */}
          <h5>{decodeURIComponent(item.location.split("/").pop())}</h5>
          {/* <p>Shared By: {item.roomFile.name}</p> */}
          <p>Shared By: {item.sharedBy}</p>
        </div>
        <div className={styles.dots}>
          <EyeIcon
            // onClick={() => setFileAndShowPreview(item.roomFile.roomFile)}
            onClick={() => setFileAndShowPreview(item.location)}
          />
          <FileDeleteIcon />
          <DownloadButton
            // assetLink={item.roomFile.roomFile}
            // desiredFilename={item.roomFile.name || "file"}
            assetLink={item.location}
            desiredFilename={item.location || "file"}
          />
        </div>
      </div>
    </div>
  );
}

SharedFolder.propTypes = {
  item: PropTypes.any,
  setFileAndShowPreview: PropTypes.func,
};

export default SharedFolder;
