import React from "react";
import styles from "./style.module.scss";

export function PageNotFound(){
    return(
        <div className={styles.wrapper}>
            404 <br/> Page Not Found :(
        </div>
    );
}

export default PageNotFound;
