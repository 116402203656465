import axiosInstance from "../../utils/axiosInstance";
import axiosInstanceWizrConnect from "utils/axiosInstanceWizrConnect";
import {
  successToast,
  errorToast,
} from "../../components/ui/atoms/Toasts/toasts";

import {
  getHostMeetingDataError,
  getHostMeetingDataInit,
  getHostMeetingDataSuccess,
  getHostMeetingsError,
  getHostMeetingsInit,
  getHostMeetingsSuccess,
  getHostMeetingRecordingsError,
  getHostMeetingRecordingsInit,
  getHostMeetingRecordingsSuccess,
  getSingleHostMeetingRecordingsError,
  getSingleHostMeetingRecordingsInit,
  getSingleHostMeetingRecordingsSuccess,
  getSubHostMeetingInit,
  getSubHostMeetingSuccess,
  getSubHostMeetingError,
  getMyHostedMeetingsInit,
  getMyHostedMeetingsSuccess,
  getMyHostedMeetingsError,
  getMeetingSessionsInit,
  getMeetingSessionsSuccess,
  getMeetingSessionsError,
  getMeetingChatsInit,
  getMeetingChatsSuccess,
  getMeetingChatsError,
  getMeetingNotesInit,
  getMeetingNotesSuccess,
  getMeetingNotesError,
  getMeetingFilesInit,
  getMeetingFilesSuccess,
  getMeetingFilesError,
  getMeetingTranscriptsInit,
  getMeetingTranscriptsSuccess,
  getMeetingTranscriptsError,
} from "./actionCreators/meetingRepositoryActionCreators";


export const getHostMeetings = (email="wagbrian12@gmail.com", limit = 10, page = 1) => {
  return async dispatch => {
    try {
    dispatch(getHostMeetingsInit());
      const url = `/api/v1/hosted-meetings?email=${email}&page=${page}&limit=${limit}`;
      const { data } = await axiosInstance.get(url);
      dispatch(getHostMeetingsSuccess(data));
      return successToast(data.message);
    } catch (error) {
      if (error.response) {
        dispatch(getHostMeetingsError(error.response.data.message));
        return errorToast(error?.response?.data?.response);
      }
    }
  };
};

//


export const getSpecificHostMeetingData = (activeRoom, roomId) => {
  return async dispatch => {
    try {
    dispatch(getHostMeetingDataInit());
      const url = `/api/v1/room-meta/${activeRoom}?roomId=${roomId}`;
      const { data } = await axiosInstance.get(url);
      dispatch(getHostMeetingDataSuccess(data.data));
      return successToast(data.message);
    } catch (error) {
      if (error.response) {
        dispatch(getHostMeetingDataError(error.response.data.message));
        return errorToast(error?.response?.data?.response);
      }
    }
  };
};

export const getHostMeetingsRecordings = (email="wagbrian12@gmail.com") => {
  return async dispatch => {
    try {
    dispatch(getHostMeetingRecordingsInit());
    // console.log(email, meetingId, "getHostMeetingsRecordings:::email, meetingId test");
      const url = `/api/v1/wizr-recordings?email=${email}`;
      const { data } = await axiosInstance.get(url);
      dispatch(getHostMeetingRecordingsSuccess(data.personalVideos));
      return successToast(data.message);
    } catch (error) {
      if (error.response) {
        dispatch(getHostMeetingRecordingsError(error.response.data.message));
        return errorToast(error?.response?.data?.response);
      }
    }
  };
};


// single host meeting

export const getSingleHostMeetingsRecordings = (roomId, email) => {
  return async dispatch => {
    try {
    dispatch(getSingleHostMeetingRecordingsInit());
      const url = `/api/v1/hosted-meetings/details?email=${email}&roomId=${roomId}`;
      const { data } = await axiosInstance.get(url);
      dispatch(getSingleHostMeetingRecordingsSuccess(data.data[0]));
      return successToast(data.message);
    } catch (error) {
      if (error.response) {
        dispatch(getSingleHostMeetingRecordingsError(error.response.data.message));
        return errorToast(error?.response?.data?.response);
      }
    }
  };
};

// get sub meetings

export const getSubHostMeetings = (roomId, email) => {
  return async dispatch => {
    try {
    dispatch(getSubHostMeetingInit());
      const url = `/api/v1/room-meta/${roomId}/data?email=${email}`;
      const { data } = await axiosInstance.get(url);
      dispatch(getSubHostMeetingSuccess(data?.data));
    } catch (error) {
      if (error.response) {
        dispatch(getSubHostMeetingError(error.response.data.message));
        return errorToast(error?.response?.data?.response);
      }
    }
  };
};


export const getMyHostedMeetings = (email, limit = 10, page = 1) => {
  return async dispatch => {
    try {
    dispatch(getMyHostedMeetingsInit());
      const url = `/api/host/myhosted-meetings?&page=${page}&limit=${limit}`;
      const postData = {email};
      const { data } = await axiosInstanceWizrConnect.post(url, postData);
      dispatch(getMyHostedMeetingsSuccess(data));
      return successToast(data.message);
    } catch (error) {
      if (error.response) {
        dispatch(getMyHostedMeetingsError(error.response.data.message));
        return errorToast(error?.response?.data?.response);
      }
    }
  };
};


export const getMeetingSessions = (roomId) => {
  return async dispatch => {
    try {
    dispatch(getMeetingSessionsInit());
      const url = `/api/sessions/room/${roomId}`;
      const { data } = await axiosInstanceWizrConnect.get(url);
      dispatch(getMeetingSessionsSuccess(data));
      return successToast(data.message);
    } catch (error) {
      if (error.response) {
        dispatch(getMeetingSessionsError(error.response.data.message));
        return errorToast(error?.response?.data?.response);
      }
    }
  };
};

export const getMeetingChats = (sessionId) => {
  return async dispatch => {
    try {
    dispatch(getMeetingChatsInit());
      const url = `/api/chats/session/${sessionId}`;
      const { data } = await axiosInstanceWizrConnect.get(url);
      dispatch(getMeetingChatsSuccess(data));
      return successToast(data.message);
    } catch (error) {
      if (error.response) {
        dispatch(getMeetingChatsError(error.response.data.message));
        return errorToast(error?.response?.data?.response);
      }
    }
  };
};

export const getMeetingNotes = (sessionId) => {
  return async dispatch => {
    try {
    dispatch(getMeetingNotesInit());
      const url = `/api/notes/session/${sessionId}`;
      const { data } = await axiosInstanceWizrConnect.get(url);
      dispatch(getMeetingNotesSuccess(data));
      return successToast(data.message);
    } catch (error) {
      if (error.response) {
        dispatch(getMeetingNotesError(error.response.data.message));
        return errorToast(error?.response?.data?.response);
      }
    }
  };
};

export const getMeetingFiles = (sessionId) => {
  return async dispatch => {
    try {
    dispatch(getMeetingFilesInit());
      const url = `/api/sharedFiles/session/${sessionId}`;
      const { data } = await axiosInstanceWizrConnect.get(url);
      dispatch(getMeetingFilesSuccess(data));
      return successToast(data.message);
    } catch (error) {
      if (error.response) {
        dispatch(getMeetingFilesError(error.response.data.message));
        return errorToast(error?.response?.data?.response);
      }
    }
  };
};

export const getMeetingTranscripts = (sessionId, userId) => {
  return async dispatch => {
    try {
    dispatch(getMeetingTranscriptsInit());
      const url = `/api/transcriptions/session/${sessionId}?userId=${userId}`;
      const { data } = await axiosInstanceWizrConnect.get(url);
      dispatch(getMeetingTranscriptsSuccess(data));
      return successToast(data.message);
    } catch (error) {
      if (error.response) {
        dispatch(getMeetingTranscriptsError(error.response.data.message));
        return errorToast(error?.response?.data?.response);
      }
    }
  };
};
