import { useEffect } from "react";
import { useSelector } from "react-redux";
import removeComment from "../../../../../redux/reducers/utilities/removeComment";

const useEditComment = (setAllComments, allComments) => {
    const editedComment = useSelector((state) => state?.comments?.editedComment);

    useEffect(() => {
        if (!editedComment._id) return null;
        const filteredComments = removeComment(allComments, editedComment?._id);
        return setAllComments([ ...filteredComments, editedComment ]);

    }, [allComments, editedComment, editedComment._id, setAllComments]);

};

export default useEditComment;
