import React from "react";
import Container from "../../molecules/Container";
import {personalLibraryData} from "../../../../constants/personalLibraryData";
import RightMenu from "../../atoms/RightMenu";
import {meetingMenu} from "../../../../constants/homeMenu";
import SharedBanner from "components/ui/atoms/SharedBanner";
import styles from "./styles.module.scss";
import image from "assets/images/meetingbanner.jpg";
const MeetingRepository = () => {
  return (
    <>
     <SharedBanner
        title="Meeting Repository"
        extraClass="meetingbanner"
        description="All your meeting repository content will be saved here."
      />
      <div className={styles.bottom}>

      <Container list={personalLibraryData} viewType="meetings" />
      <RightMenu bgImage={image} list={meetingMenu} buttons/>
      </div>
    </>
  );
};

export default MeetingRepository;
