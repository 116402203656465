import React from "react";
import SharedButton from "../SharedButton";
import styles from "./style.module.scss";
import PropTypes from "prop-types";

function TwoButtons({ setDeleteMenu, setState, handleFunction, handleCancel, firsttext, secondtext, extraClass, loading }) {
  return (
    <div className={styles.twoButtons}>
      <SharedButton clickEvent={handleCancel} disabled={loading} extraClass={extraClass} setState={setState} setDeleteMenu={setDeleteMenu}>{firsttext}</SharedButton>
      <SharedButton clickEvent={handleFunction} disabled={loading} setState={setState} setDeleteMenu={setDeleteMenu}>{secondtext}</SharedButton>
    </div>
  );
}

export default TwoButtons;
TwoButtons.propTypes = {
    firsttext: PropTypes.string,
    secondtext: PropTypes.string,
    extraClass: PropTypes.string,
    handleFunction: PropTypes.func,
    handleCancel: PropTypes.func,
    loading: PropTypes.bool,
    setState: PropTypes.func,
    setDeleteMenu: PropTypes.func
};
