import { SCHEDULE_MEETING } from "../actions/actionTypes";

const initialState = {
  loading: false,
  error: null,
  data: null,
};

function scheduleMeetingReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SCHEDULE_MEETING.INIT:
      return {
        ...state,
        loading: true,
      };
    case SCHEDULE_MEETING.SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case SCHEDULE_MEETING.ERROR:
      return {
        ...state,
        error: true,
        message: action.error.message,
        loading: false,
      };
    default:
      return state;
  }
}

export default scheduleMeetingReducer;
