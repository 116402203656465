import {
  GET_HOST_MEETINGS,
  GET_HOST_MEETING_DATA,
  GET_HOST_MEETING_RECORDINGS,
  GET_SINGLE_HOST_MEETING_RECORDINGS,
  GET_SUB_HOST_MEETINGS,
  GET_MY_HOSTED_MEETINGS,
  GET_MEETING_SESSIONS,
  GET_MEETING_CHATS,
  GET_MEETING_NOTES,
  GET_MEETING_FILES,
  GET_MEETING_TRANSCRIPTS,
} from "redux/actions/actionTypes";

const INITIAL_DATA = {
  hostMeetings: {
    data: [],
    page: 1,
    perPage: 10,
    totalMeetings: 0
  },
  myHostedMeetings: [],
  loadingMyHostedMeetings: false,
  hostMeetingRecordings: [],
  singleHostMeetingRecordings: {},
  singleHostMeetingRecordingStatus: false,
  singleMeetingData: {},
  loadingHostRecordings: false,
  loading: false,
  error: null,
  loadingSubHostMeetings: false,
  subHostMeetings: [],
  meetingSessions: [],
  loadingMeetingSessions: false,
  meetingChats: [],
  loadingMeetingChats: false,
  meetingNotes: [],
  loadingMeetingNotes: false,
  meetingFiles: [],
  loadingMeetingFiles: false,
  meetingTranscripts: [],
  loadingMeetingTranscripts: false,
};

const meetingRepositoryReducer = (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case GET_HOST_MEETINGS.INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_HOST_MEETINGS.SUCCESS:
      return {
        ...state,
        loading: false,
        hostMeetings: action.payload,
      };
    case GET_HOST_MEETINGS.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_HOST_MEETING_DATA.INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_HOST_MEETING_DATA.SUCCESS:
      return {
        ...state,
        loading: false,
        singleMeetingData: action.payload[0],
      };
    case GET_HOST_MEETING_DATA.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // recordings

    case GET_HOST_MEETING_RECORDINGS.INIT:
      return {
        ...state,
        loadingHostRecordings: true,
      };
    case GET_HOST_MEETING_RECORDINGS.SUCCESS:
      return {
        ...state,
        loadingHostRecordings: false,
        hostMeetingRecordings: action.payload,
      };
    case GET_HOST_MEETING_RECORDINGS.ERROR:
      return {
        ...state,
        loadingHostRecordings: false,
        error: action.payload,
      };

    case GET_SINGLE_HOST_MEETING_RECORDINGS.INIT:
      return {
        ...state,
        singleHostMeetingRecordingStatus: true,
      };
    case GET_SINGLE_HOST_MEETING_RECORDINGS.SUCCESS:
      return {
        ...state,
        singleHostMeetingRecordingStatus: false,
        singleHostMeetingRecordings: action.payload,
      };
    case GET_SINGLE_HOST_MEETING_RECORDINGS.ERROR:
      return {
        ...state,
        singleHostMeetingRecordingStatus: false,
        error: action.payload,
      };

    case GET_SUB_HOST_MEETINGS.INIT:
      return {
        ...state,
        loadingSubHostMeetings: true,
      };
    case GET_SUB_HOST_MEETINGS.ERROR:
      return {
        ...state,
        loadingSubHostMeetings: false,
        error: action.payload,
      };
    case GET_SUB_HOST_MEETINGS.SUCCESS:
      return {
        ...state,
        loadingSubHostMeetings: false,
        subHostMeetings: action.payload
      };
      case GET_MY_HOSTED_MEETINGS.INIT:
        return {
          ...state,
          loadingMyHostedMeetings: true,
        };
      case GET_MY_HOSTED_MEETINGS.ERROR:
        return {
          ...state,
          loadingMyHostedMeetings: false,
          error: action.payload,
        };
      case GET_MY_HOSTED_MEETINGS.SUCCESS:
        return {
          ...state,
          myHostedMeetings: action.payload.hostedMeetings,
          loadingMyHostedMeetings: false,
        };
        case GET_MEETING_SESSIONS.INIT:
          return {
            ...state,
            loadingMeetingSessions: true,
          };
        case GET_MEETING_SESSIONS.ERROR:
          return {
            ...state,
            loadingMeetingSessions: false,
            error: action.payload,
          };
        case GET_MEETING_SESSIONS.SUCCESS:
          return {
            ...state,
            meetingSessions: action.payload.data,
            loadingMeetingSessions: false,
          };
          case GET_MEETING_CHATS.INIT:
            return {
              ...state,
              loadingMeetingChats: true,
            };
          case GET_MEETING_CHATS.ERROR:
            return {
              ...state,
              loadingMeetingChats: false,
              error: action.payload,
            };
          case GET_MEETING_CHATS.SUCCESS:
            return {
              ...state,
              meetingChats: action.payload?.data?.chatHistory || [],
              loadingMeetingChats: false,
            };
          case GET_MEETING_NOTES.INIT:
            return {
              ...state,
              loadingMeetingNotes: true,
            };
          case GET_MEETING_NOTES.ERROR:
            return {
              ...state,
              loadingMeetingNotes: false,
              error: action.payload,
            };
          case GET_MEETING_NOTES.SUCCESS:
            return {
              ...state,
              meetingNotes: action.payload.data,
              loadingMeetingNotes: false,
            };
          case GET_MEETING_FILES.INIT:
            return {
              ...state,
              loadingMeetingFiles: true,
            };
          case GET_MEETING_FILES.ERROR:
            return {
              ...state,
              loadingMeetingFiles: false,
              error: action.payload,
            };
          case GET_MEETING_FILES.SUCCESS:
            return {
              ...state,
              meetingFiles: action.payload.data,
              loadingMeetingFiles: false,
            };
            case GET_MEETING_TRANSCRIPTS.INIT:
              return {
                ...state,
                loadingMeetingFiles: true,
              };
            case GET_MEETING_TRANSCRIPTS.ERROR:
              return {
                ...state,
                loadingMeetingFiles: false,
                error: action.payload,
              };
            case GET_MEETING_TRANSCRIPTS.SUCCESS:
              return {
                ...state,
                meetingTranscripts: action.payload,
                loadingMeetingTranscripts: false,
              };
    default:
      return state;
  }
};

export default meetingRepositoryReducer;
