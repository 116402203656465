/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import { ReactComponent as File } from "../../../../assets/images/file.svg";
import { ReactComponent as Folder } from "../../../../assets/images/folder.svg";
import DropDownMenu from "../DropDownMenu";
import hasValidExtension from "utils/imageExtensions";

const TableRow = ({
  title,
  thumbnail,
  dropDown,
  disabledItems,
  _id,
  createdAt,
  size,
  email,
  type,
  cloudinaryUrl,
  pathHandler,
  setActiveMenu,
  activeMenu,
  myRef,
  duplicateLocation,
  showContextMenu,
}) => {
  const [freePosition, setFreePosition] = useState();
  const handleContextMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    setActiveMenu(_id);
    setFreePosition({
      posX: e.target.offsetLeft,
    });
  };

  return (
    <tr
      className={styles.row}
      onContextMenu={e => {
        handleContextMenu(e);
      }}
    >
      {dropDown && _id === activeMenu && (
        <DropDownMenu
          id={_id}
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
          // setActiveMenu={setMenu}
          freePosition={freePosition}
          duplicateLocation={duplicateLocation}
          disabledItems={disabledItems}
          cloudinaryUrl={cloudinaryUrl}
          title={title}
          myRef={myRef}
          // activeMenu={true}
        />
      )}

      <td className={styles.nameColumn} onClick={() => pathHandler(_id, type)}>
        <span
          className={styles.name}
          onKeyDown={() => pathHandler(_id, type)}
          role='button'
          tabIndex={0}
        >
          {type === "file" ? (
            hasValidExtension(thumbnail) ? (
              <img src={thumbnail} alt='library' />
            ) : (
              <File />
            )
          ) : hasValidExtension(thumbnail) ? (
            <img src={thumbnail} alt='library' />
          ) : (
            <Folder />
          )}
          {title}
        </span>
      </td>
      <td className={styles.dateColumn}>
        {new Date(createdAt).toLocaleString()}
      </td>
      <td className={styles.sizeColumn}>
        {size && size + " mb"}
      </td>
      <td className={styles.email} onClick={() => pathHandler(_id, type)}>
        <div className={styles.emailContainer}>
          {email && (
            <>
              <div className={styles.emailText}>{email}</div>
            </>
          )}
        </div>
      </td>
      {
        showContextMenu
        &&
        <td>
          <div className={styles.kebabContainer}>
          <button onClick={() => {
            setActiveMenu(_id);
            }}>
            <div></div>
            <div></div>
            <div></div>
          </button>
          </div>
        </td>
      }
    </tr>
  );
};

TableRow.defaultProps = {
  showContextMenu: true,
};

TableRow.propTypes = {
  title: PropTypes.string,
  _id: PropTypes.string,
  createdAt: PropTypes.string,
  size: PropTypes.number,
  email: PropTypes.string,
  type: PropTypes.string,
  pathHandler: PropTypes.func,
  activeMenu: PropTypes.string,
  setActiveMenu: PropTypes.func,
  dropDown: PropTypes.bool,
  duplicateLocation: PropTypes.string,
  cloudinaryUrl: PropTypes.string,
  disabledItems: PropTypes.array,
  thumbnail: PropTypes.string,
  myRef: PropTypes.any,
  showContextMenu: PropTypes.bool,
};

export default TableRow;
