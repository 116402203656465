import {
  GET_HOST_MEETINGS,
  GET_HOST_MEETING_DATA,
  GET_HOST_MEETING_RECORDINGS,
  GET_SINGLE_HOST_MEETING_RECORDINGS,
  GET_SUB_HOST_MEETINGS,
  GET_MY_HOSTED_MEETINGS,
  GET_MEETING_SESSIONS,
  GET_MEETING_CHATS,
  GET_MEETING_NOTES,
  GET_MEETING_FILES,
  GET_MEETING_TRANSCRIPTS,
} from "../actionTypes";

export const getHostMeetingsInit = () => ({
  type: GET_HOST_MEETINGS.INIT,
});

export const getHostMeetingsSuccess = payload => ({
  type: GET_HOST_MEETINGS.SUCCESS,
  payload,
});

export const getHostMeetingsError = error => ({
  type: GET_HOST_MEETINGS.ERROR,
  error,
});


// single meeting data actions

export const getHostMeetingDataInit = () => ({
  type: GET_HOST_MEETING_DATA.INIT,
});

export const getHostMeetingDataSuccess = payload => ({
  type: GET_HOST_MEETING_DATA.SUCCESS,
  payload,
});

export const getHostMeetingDataError = error => ({
  type: GET_HOST_MEETING_DATA.ERROR,
  error,
});


// host recordings

export const getHostMeetingRecordingsInit = () => ({
  type: GET_HOST_MEETING_RECORDINGS.INIT,
});

export const getHostMeetingRecordingsSuccess = payload => ({
  type: GET_HOST_MEETING_RECORDINGS.SUCCESS,
  payload,
});

export const getHostMeetingRecordingsError = error => ({
  type: GET_HOST_MEETING_RECORDINGS.ERROR,
  error,
});


// single host recordings

export const getSingleHostMeetingRecordingsInit = () => ({
  type: GET_SINGLE_HOST_MEETING_RECORDINGS.INIT,
});

export const getSingleHostMeetingRecordingsSuccess = payload => ({
  type: GET_SINGLE_HOST_MEETING_RECORDINGS.SUCCESS,
  payload,
});

export const getSingleHostMeetingRecordingsError = error => ({
  type: GET_SINGLE_HOST_MEETING_RECORDINGS.ERROR,
  error,
});
// get sub meetings

export const getSubHostMeetingInit = () => ({
  type: GET_SUB_HOST_MEETINGS.INIT,
});

export const getSubHostMeetingSuccess = payload => ({
  type: GET_SUB_HOST_MEETINGS.SUCCESS,
  payload,
});

export const getSubHostMeetingError = error => ({
  type: GET_SUB_HOST_MEETINGS.ERROR,
  error,
});

export const getMyHostedMeetingsInit = () => ({
  type: GET_MY_HOSTED_MEETINGS.INIT,
});

export const getMyHostedMeetingsSuccess = payload => ({
  type: GET_MY_HOSTED_MEETINGS.SUCCESS,
  payload,
});

export const getMyHostedMeetingsError = error => ({
  type: GET_MY_HOSTED_MEETINGS.ERROR,
  error,
});

export const getMeetingSessionsInit = () => ({
  type: GET_MEETING_SESSIONS.INIT,
});

export const getMeetingSessionsSuccess = payload => ({
  type: GET_MEETING_SESSIONS.SUCCESS,
  payload,
});

export const getMeetingSessionsError = error => ({
  type: GET_MEETING_SESSIONS.ERROR,
  error,
});


export const getMeetingChatsInit = () => ({
  type: GET_MEETING_CHATS.INIT,
});

export const getMeetingChatsSuccess = payload => ({
  type: GET_MEETING_CHATS.SUCCESS,
  payload,
});

export const getMeetingChatsError = error => ({
  type: GET_MEETING_CHATS.ERROR,
  error,
});


export const getMeetingNotesInit = () => ({
  type: GET_MEETING_NOTES.INIT,
});

export const getMeetingNotesSuccess = payload => ({
  type: GET_MEETING_NOTES.SUCCESS,
  payload,
});

export const getMeetingNotesError = error => ({
  type: GET_MEETING_NOTES.ERROR,
  error,
});

export const getMeetingFilesInit = () => ({
  type: GET_MEETING_FILES.INIT,
});

export const getMeetingFilesSuccess = payload => ({
  type: GET_MEETING_FILES.SUCCESS,
  payload,
});

export const getMeetingFilesError = error => ({
  type: GET_MEETING_FILES.ERROR,
  error,
});

export const getMeetingTranscriptsInit = () => ({
  type: GET_MEETING_TRANSCRIPTS.INIT,
});

export const getMeetingTranscriptsSuccess = payload => ({
  type: GET_MEETING_TRANSCRIPTS.SUCCESS,
  payload,
});

export const getMeetingTranscriptsError = error => ({
  type: GET_MEETING_TRANSCRIPTS.ERROR,
  error,
});
