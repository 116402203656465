import React from "react";
import Container from "../../molecules/Container";
import {personalLibraryData} from "../../../../constants/personalLibraryData";
import RightMenu from "../../atoms/RightMenu";
import {pollsMenu} from "../../../../constants/homeMenu";
import SharedBanner from "components/ui/atoms/SharedBanner";
import styles from "./styles.module.scss";
import image from "assets/images/meetingbanner.jpg";
const PollsContainer = () => {
  return (
    <>
     <SharedBanner
        title="Polls"
        extraClass="pollsbanner"
        description="All polls will be saved here."
      />
      <div className={styles.bottom}>

      <Container list={personalLibraryData} viewType="polls" />
      <RightMenu bgImage={image} list={pollsMenu} buttons/>
      </div>
    </>
  );
};

export default PollsContainer;
