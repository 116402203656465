import { useState, useEffect } from "react";
import Popup from "../../atoms/Popup";
import TwoButtons from "components/ui/atoms/TwoButtons";
import PopupButton from "../../atoms/Popup/PopupButton";
import PropTypes from "prop-types";

function DeletePopup({ setDeleteMenu, handleFunction, firsttext, secondtext, loading, message = "Are you sure you want to delete this video?", showPopUp }) {

  const [ popup, setPopup ] = useState(showPopUp);

  const hidePopup = () => {
    setPopup(false);
  };
  const openPopup = () => {
    setPopup(true);
  };

  return (
    <>
      <PopupButton title="Delete" /*extraClass="grayBtn1"*/ openPopup={openPopup} />
      <Popup removePopup={hidePopup} state={popup} title="Delete">
        {/*<p>Are you sure you want to delete this video?</p>*/}
          <p>{ message }</p>
        <TwoButtons
          firsttext={firsttext}
          secondtext={!loading ? secondtext : "Deleting..."}
          extraClass="grayBtn"
          handleFunction={handleFunction}
          loading={loading}
          handleCancel={hidePopup}
          setState={setPopup}
          setDeleteMenu={setDeleteMenu}

        />
      </Popup>
    </>
  );
}
DeletePopup.propTypes = {
    handleFunction: PropTypes.func,
    firsttext: PropTypes.string,
    secondtext: PropTypes.string,
    color: PropTypes.string,
    text: PropTypes.string,
    loading: PropTypes.bool,
    message: PropTypes.string,
    setDeleteMenu: PropTypes.func,
    showPopUp: PropTypes.bool
};

DeletePopup.defaultProps = {
  showPopUp: false
};

export default DeletePopup;
