import axiosInstance from "utils/axiosInstance";
import axiosInstanceWizrConnect from "utils/axiosInstanceWizrConnect";
import {
  getPollsError,
  getPollsInit,
  getPollsSuccess,
  getPollInit,
  getPollSuccess,
  getPollError
} from "./actionCreators/pollsActionCreators";

export const getPolls = (userId='64ba9706de5c23b6d1f45b70', page, limit=10) => async dispatch => {
  dispatch(getPollsInit());
  try {
    const response = await axiosInstance.get(`/api/v1/poll-results/${userId}?page=${page}&limit=${limit}`);
    dispatch(getPollsSuccess(response.data));
  } catch (error) {
    dispatch(getPollsError(error));
  }
};

export const getPoll = (userId, pollId) => async dispatch => {
  dispatch(getPollInit());
  try {
    const response = await axiosInstance.get(`/api/v1/poll-results/${userId}/${pollId}`);
    dispatch(getPollSuccess(response.data.data[0]));
  } catch (error) {
    dispatch(getPollError(error));
  }
};

export const getSessionPolls = (sessionId) => async dispatch => {
  dispatch(getPollsInit());
  try {
    const response = await axiosInstanceWizrConnect.get(`/api/poll-results/session/${sessionId}`);
    dispatch(getPollsSuccess(response.data));
  } catch (error) {
    dispatch(getPollsError(error));
  }
};
