import React, { useState, useEffect } from "react";
import styles from "./style.module.scss";
import PropTypes from "prop-types";
import SettingElement from "../../molecules/SettingElement";
import ProfileMain from "./ProfileMain";
import ProfileData from "./ProfileData";
import { useDispatch } from "react-redux";
import { createUserData } from "redux/actions/userDataActions";

export default function Profile({ profile, isFetching }) {
  const emptyData = {
    first_name: "",
    last_name: "",
    title: "",
    country: "",
    about: "",
    area_of_focus: "",
    location: "",
    birthday: "",
    date_joined: "",
    show_room_link: "",
    cover_page: "",
    email: "",
    phone: "",
  };

  const dispatch = useDispatch();
  const [changes, setChanges] = useState(emptyData); // will be used to send changes to backend
  const [restorePopup, setRestorePopup] = useState(null); // will be used to send changes to backend
  const [loading, setLoading] = useState(true); // will be used to send changes to backend

  const loadFromLocalStorage = answer => {
    if (answer) {
      setChanges(restorePopup);
    } else {
      localStorage.setItem("profile", JSON.stringify(emptyData));
    }
    setRestorePopup(null);
  };

  const cleanLocalStorage = () => {
    loadFromLocalStorage(false);
  };

  const uploadChanges = () => {
    createUserData(dispatch, changes, cleanLocalStorage);
  };

  const tryLocalStorage = reqData => {
    if (localStorage.getItem("profile")) {
      const unsavedData = JSON.parse(localStorage.getItem("profile"));
      if (Object.values(unsavedData).join("")) {
        setRestorePopup(unsavedData);
        setChanges(reqData);
      }
      setChanges(reqData);
    } else {
      localStorage.setItem("profile", JSON.stringify(emptyData));
    }
  };

  useEffect(() => {
    if (isFetching) return;
    if (!profile) {
      tryLocalStorage(emptyData);
      setLoading(false);
    } else {
      tryLocalStorage(profile);
      setLoading(false);
    }
  }, [isFetching]);

  useEffect(() => {
    if (!loading) {
      if(profile) {
        Object.values(changes).join("") !== Object.values(profile).join("") &&
         localStorage.setItem("profile", JSON.stringify(changes));
      } else {
        localStorage.setItem("profile", JSON.stringify(changes));
      }
    }
  }, [changes]);

  const elements = [
    {
      header: "PROFILE",
      description:
        "Some description on why we collect this personal information",
      togglerName: "Edit Profile",
      child: (editing, setSubmittableData) => (
        <ProfileMain
          editing={editing}
          changes={changes}
          setChanges={setChanges}
          setSubmittableData={setSubmittableData}
        />
      ),
    },
    {
      header: "PROFILE CARD",
      description: profile ?
        "Some description on why we collect this personal information" : "fill in the form above and save to be able to modify this section",
      togglerName: profile ? "Edit Profile Card" : null,
      child: (editing, setSubmittableData) => (
        <ProfileData
          editing={editing}
          changes={changes}
          setChanges={setChanges}
          setSubmittableData={setSubmittableData}
        />
      ),
    },
  ];

  return (
    <div className={styles.ProfileContainer}>
      {restorePopup && (
        <div className={styles.modal}>
          <div className={styles.restorePopup}>
            <p>
              It looks like you have unsaved data stored on your device. Would
              you like to restore it ?
            </p>
            <div className={styles.buttons}>
              <button onClick={() => loadFromLocalStorage(true)}>Yes</button>
              <button onClick={() => loadFromLocalStorage(false)}>No</button>
            </div>
          </div>
        </div>
      )}
      {elements.map((element, idx) => (
        <React.Fragment key={idx}>
          <SettingElement
            header={element.header}
            description={element.description}
            togglerName={element.togglerName}
            childAsFunction={element.child}
            placement={elements.length - idx}
            uploadChanges={uploadChanges}
          />
        </React.Fragment>
      ))}
    </div>
  );
}

Profile.propTypes = {
  profile: PropTypes.object,
  isFetching: PropTypes.bool,
};
