import { ReactComponent as UserID } from "assets/images/userId.svg";
import { ReactComponent as Calendar } from "assets/images/calendar.svg";
import bgImage from "assets/images/right-menu-2.webp";

export const scheduleItems = [
  {
    icon: <UserID />,
    title: "Meeting ID",
    id: 1,
    options: ["Generate Automatically", "Personal Meeting ID"],
  },
  {
    icon: <Calendar />,
    title: "Calendar",
    id: 2,
    options: ["Outlook", "Google Calendar", "Other Calendars"],
  },
];

export const rightMenuData = [
  {
    index: 0,
    Heading: "Connect with video",
    bgImage: bgImage,
    extraClass: "bigHeading",
    Paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
    items: [
      {
        title: "Get started without login",
        extraClassItem: "lineStyled",
        description:
          "Files of a large size (up to 10 GB) can be uploaded into our online music file processing application. There is no necessity to archive your large mp3 files to slow down or to speed up music.",
      },
    ],
  },
];
