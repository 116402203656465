import React from "react";
import styles from "./style.module.scss";
import PropTypes from "prop-types";

function IsolatedVideoContainer({ children }) {
  return (
    <div className={styles.container}>
      <div className={styles.center}>{children}</div>
    </div>
  );
}

IsolatedVideoContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default IsolatedVideoContainer;
