import React, { useState } from "react";

import PropTypes from "prop-types";

import styles from "./styles.module.scss";

const GridViewInterview = ({ list }) => {
  return (
    <div className={styles.gridItems}>
      {list.map((d, i) => {
        return (
          <div className={styles.item}>
            <div className={styles.namePoints}>
              <p style={{ backgroundColor: d.bgColor }}>{d.shortname}</p>
              <div className={styles.Points}>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <p>{d.name}</p>
            <div className={styles.rate}>
              {d.rating.map(data => {
                return <>{data}</>;
              })}
            </div>
            <div className={styles.date}>
              <p>{d.date}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default GridViewInterview;
GridViewInterview.propTypes = {
  list: PropTypes.array,
};
