import Cookies from "js-cookie";
import { logoutUserSuccess } from "./actionCreators/authenticationActionCreators";
export const logoutUser = () => dispatch => {
  try {
    // localStorage.removeItem('authToken');

    const urlString = process.env.REACT_APP_URL.split(".");
    const  [_, ...lastTwoParts] = urlString;
    const remainder = `.${lastTwoParts.join(".")}`.split(':')[0];
    const domain = remainder;
    Cookies.remove("authCode", { domain });
    dispatch(logoutUserSuccess());
    const url = `${process.env.REACT_APP_MASTER_WIZR_LOGIN}?_rdr=${process.env.REACT_APP_URL}`;
    window.location.href = url;
  } catch (error) {
    // Handle any potential errors from the cookie removal here.
    console.error("Error removing the authCode cookie:", error);

    // Optionally, you can dispatch an error action or some other handling here.
    // dispatch(logoutUserError(error));
  }
};
