import { LOGIN, LOGOUT_USER } from "../actionTypes";

export const loginInit = () => ({
    type: LOGIN.INIT
});

export const loginSuccess = (payload) => ({
    type: LOGIN.SUCCESS,
    user: payload
});

export const loginError = (error) => ({
    type: LOGIN.ERROR,
    error,
});

export const logoutUserSuccess = () => ({
    type: LOGOUT_USER.SUCCESS,
  });

