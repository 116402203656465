import { useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getVideobyUUID, getVideo } from "redux/actions/videoActions";
import { startGetAllComments } from "../../../../../redux/actions/commentsActions";
import useGetComments from "../hooks/useGetComments";
import { TailSpin } from "react-loader-spinner";
import BackButton from "assets/icons/BackButton";
import queryString from "query-string";
import WizrVideoPlayer from "../../WizrPlayer/WizrPlayer";
import Cookies from "js-cookie";

function VideoPlayerContainer ({ extraClass, sharing }) {
  let params = useParams();
  const parsed = queryString.parse(window.location.search);
  const { shared = false } = parsed;
  if (shared === "true" || shared === true) {
    const urlString = process.env.REACT_APP_BASE_URL.split(".");
    const  [_, ...lastTwoParts] = urlString;
    const domain = `.${lastTwoParts.join(".")}`;
    // localStorage.removeItem("intendedURL");
    Cookies.remove("intendedURL", { domain });

  }
  const { isFetching, video } = useSelector(state => state.video);

  const dispatch = useDispatch();
  const LIMIT_COMMENTS = 10;
  useGetComments(video, sharing, startGetAllComments, LIMIT_COMMENTS, 0);
  useEffect(() => {
    if (sharing) {
      getVideobyUUID(dispatch, params);
    } else {
      getVideo(dispatch, params);
    }
  }, []);

  const navigate = useNavigate();

  return (
    <>
      {!isFetching ? (
        <div className={`${styles.container} ${styles[extraClass]}`}>
          {!shared && (
            <button className={styles.backButton} onClick={() => navigate(-1)}>
              <BackButton />
              <p>Go back</p>
            </button>
          )}
          <WizrVideoPlayer src={video?.cloudinaryUrl} />
        </div>
      ) : (
        <div className={styles.wrapper}>
          <TailSpin color='#EC7500' height={80} width={80} />
        </div>
      )}
    </>
  );
}

export default VideoPlayerContainer;
VideoPlayerContainer.propTypes = {
  extraClass: PropTypes.string,
  route: PropTypes.any,
  videoItem: PropTypes.object,
  comments: PropTypes.bool,
  speedContainer: PropTypes.bool,
  sharing: PropTypes.bool,
};
