import * as types from "../actions/universalMenuActions";

const INITIAL_DATA = {
    view: false
};

const universalMenuReducer = (state = INITIAL_DATA, action) => {
    switch (action.type) {

        case types.UPDATE_UNIVERSAL_MENU_STATE:
            return {
                ...state,
                view: action.payload
            };

        default:
            return state;
    }
};

export default universalMenuReducer;

