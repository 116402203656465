import defineAction from "./utils";

export const RECORDING = defineAction("RECORDING", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);

export const LOGIN = defineAction("LOGIN", ["INIT", "SUCCESS", "ERROR"]);

export const LOGOUT_USER = defineAction("LOGOUT_USER", ["SUCCESS"]);

export const SCHEDULE_MEETING = defineAction("SCHEDULE_MEETING", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);

export const VIDEO = defineAction("VIDEO", ["INIT", "SUCCESS", "ERROR"]);
export const VIDEOUUID = defineAction("VIDEOUUID", ["INIT", "SUCCESS", "ERROR"]);

export const GET_VIDEOS = defineAction("GET_VIDEOS", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);

export const GET_USER_DATA = defineAction("GET_USER_DATA", ["INIT", "SUCCESS", "ERROR"]);
export const UPDATE_USER_DATA = defineAction("UPDATE_USER_DATA", ["INIT", "SUCCESS", "ERROR"]);


export const UPDATE_VIDEO = defineAction("UPDATE_VIDEO", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);

export const ARCHIVE_VIDEO = defineAction("ARCHIVE_VIDEO", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);

export const FAVORITE_VIDEO = defineAction("FAVORITE_VIDEO", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);

export const GET_FAVORITE_VIDEOS = defineAction("GET_FAVORITE_VIDEOS", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);

export const GET_ARCHIVED_VIDEOS = defineAction("GET_ARCHIVED_VIDEOS", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);

export const DELETE_VIDEO = defineAction("DELETE_VIDEO", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);

export const DOWNLOAD_VIDEO = defineAction("DOWNLOAD_VIDEO", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);

export const GET_SHARED_LINK = defineAction("GET_SHARED_LINK", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);

export const DUPLICATE_VIDEO = defineAction("DUPLICATE_VIDEO", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);

export const GET_PAGINATION_DATA = defineAction("GET_PAGINATION_DATA", [
    "INIT",
    "SUCCESS",
    "ERROR"
]);

export const GET_SHARED_WITH_ME_VIDEOS = defineAction("GET_SHARED_WITH_ME_VIDEOS", [
  "INIT",
  "SUCCESS",
  "ERROR"
]);



export const GET_HOST_MEETINGS= defineAction("GET_HOST_MEETINGS", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);

export const GET_HOST_MEETING_DATA = defineAction("GET_HOST_MEETING_DATA", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);


export const GET_MY_HOSTED_MEETINGS = defineAction("GET_MY_HOSTED_MEETINGS", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);

export const GET_MEETING_SESSIONS = defineAction("GET_MEETING_SESSIONS", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);

export const GET_MEETING_CHATS = defineAction("GET_MEETING_CHATS", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);

export const GET_MEETING_NOTES = defineAction("GET_MEETING_NOTES", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);

export const GET_MEETING_FILES = defineAction("GET_MEETING_FILES", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);

export const GET_MEETING_TRANSCRIPTS = defineAction("GET_MEETING_TRANSCRIPTS", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);

export const GET_HOST_MEETING_RECORDINGS = defineAction("GET_HOST_MEETING_RECORDINGS", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);

export const UPDATE_UNIVERSAL_MENU_STATE = "UPDATE_UNIVERSAL_MENU_STATE";

export const GET_SINGLE_HOST_MEETING_RECORDINGS = defineAction("GET_SINGLE_HOST_MEETING_RECORDINGS", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);


export const GET_POLLS = defineAction("GET_POLLS", ["INIT", "SUCCESS","ERROR"]);

export const GET_POLL = defineAction("GET_POLL", ["INIT", "SUCCESS","ERROR"]);

// GET_SUB_HOST_MEETINGS
export const GET_SUB_HOST_MEETINGS = defineAction("GET_SUB_HOST_MEETINGS", ["INIT", "SUCCESS","ERROR"]);

// GET_PROFILES
export const GET_PROFILES = defineAction("GET_PROFILES", ["GET_PROFILES_INIT", "GET_PROFILES_SUCCESS","GET_PROFILES_ERROR"]);
