/* eslint-disable react/prop-types */
import React from "react";
import styles from "./style.module.scss";

function Pagination ({
  currentPage,
  totalPages,
  itemsPerPage,
  totalItems,
  onPageChange,
  data
}) {

  const hasPrev = currentPage > 1;
  const hasNext = currentPage < totalPages;

  const startItemIndex = (currentPage - 1) * itemsPerPage + 1;
  const endItemIndex = hasNext ? currentPage * itemsPerPage : totalItems;

  const handlePrev = () => {
    if (hasPrev) onPageChange(currentPage - 1);
  };

  const handleNext = () => {
    if (hasNext) onPageChange(currentPage + 1);
  };

  // Calculate how many items are on the current page
  const firstItemOnPage = (currentPage - 1) * itemsPerPage + 1;
  const lastItemOnPage = 
      firstItemOnPage + itemsPerPage - 1 > totalItems 
        ? totalItems 
        : firstItemOnPage + itemsPerPage - 1;
  
  let message = `Showing ${firstItemOnPage}-${lastItemOnPage} of ${totalItems} result(s) | Page ${currentPage} of ${totalPages}`;
  
  if (currentPage === totalPages && totalPages !== 1) {
      // This is the last page but not the only page
      if (lastItemOnPage - firstItemOnPage === 0) {
          message = `Showing last item of ${totalItems} result(s) | Page ${currentPage} of ${totalPages}`;
      } else {
          message = `Showing last ${lastItemOnPage - firstItemOnPage + 1} of ${totalItems} result(s) | Page ${currentPage} of ${totalPages}`;
      }
  }
    if(totalItems < 10) {
      return null;
    }

  return (

    <div className={styles.container}>
      <div className={styles.meta}>
        {message}
      </div>

      <div>
        {hasPrev && <button className={`${styles.navigationButton} ${styles.previousButton}`} onClick={handlePrev}>Prev</button>}

        {hasNext && <button className={styles.navigationButton} onClick={handleNext}>Next</button>}
      </div>
    </div>
  );
}

export default Pagination;
