import Cookies from "js-cookie";
import { GET_PROFILES } from "../actions/actionTypes";

const INITIAL_DATA = {
  data: null,
  isModuleAccessible: true,
  selectedProfile: {},
  loading: false,
  error: null,
  status: "",
};

const profilesReducer = (state = INITIAL_DATA, { type, payload }) => {
  switch (type) {
    case GET_PROFILES.GET_PROFILES_INIT:
      return {
        ...state,
        loading: true
      };

    case GET_PROFILES.GET_PROFILES_SUCCESS:
      if (
        payload.profiles.length > 0 &&
        payload.profiles[0]
      ) {
        state.data = payload.profiles;
        let activeProfile = Cookies.get("activeProfile");
        if (!activeProfile) {
          activeProfile = payload?.profiles[0]?.accountId;
          const hostname = window.location.hostname;
          const mainDomain = hostname.match(/[^.]+\.(.+\..+)/);
          let usedDomain = hostname;
          if (mainDomain) {
            usedDomain = mainDomain[1] || hostname;
          }
          Cookies.set("activeProfile", activeProfile, {
            domain: usedDomain
          });
        }
        const profileId = activeProfile?.split("-")[0];
        const currentProfile = payload.profiles.find(
          (profile) => profile.accountId === profileId
        );
        if (currentProfile) {
          state.dateOfExpiration = currentProfile?.accountInfo?.licenceInfo?.dateOfExpiration;
          const expirationDate = new Date(state.dateOfExpiration);
          const currentDate = new Date();
          if (expirationDate > currentDate) {
            state.accessedModules = currentProfile?.accessedModules;
            state.selectedProfile = currentProfile;
            state.currentCompanyInfo = currentProfile?.accountInfo;
            // state.isModuleAccessible = currentProfile?.accessedModules?.some(
            //   (module) => module.name === "video dashboard"
            // );
            state.isModuleAccessible = true; // Set to true always. Module was removed from Masterhub.
            localStorage.setItem("company-id", currentProfile?.accountId);
          }
        } else {
          const madeActive = payload.profiles[0];
          state.dateOfExpiration = madeActive?.accountInfo?.licenceInfo?.dateOfExpiration;
          const expirationDate = new Date(state.dateOfExpiration);
          const currentDate = new Date();
          if (expirationDate > currentDate) {
            state.accessedModules = madeActive?.accessedModules;
            // state.isModuleAccessible =
            //   madeActive?.accessedModules?.some(
            //     (module) => module.name === "video dashboard"
            //   );
            state.isModuleAccessible = true;  // Set to true always. Module was removed from Masterhub.
            state.selectedProfile = madeActive;
            state.currentCompanyInfo = madeActive?.accountInfo;
            localStorage.setItem(
              "company-id",
              madeActive?.accountId
            );
          }
        }
      }
      return { ...state, status: "succeeded", loading: false };

    case  GET_PROFILES.GET_PROFILES_ERROR:
      return { ...state, error: payload, status: "failed", loading: false };

    default:
      return state;
  }
};

export default profilesReducer;
