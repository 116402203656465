/* eslint-disable linebreak-style */
import React from "react";
import VideoRecords from "components/ui/molecules/VideoRecords/index";

function VideoRecordsContainer() {
  return (
    <>
      <VideoRecords/>
    </>
  );
}

export default VideoRecordsContainer;
