import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RouteContainer from "routes/RouteContainer";
import store from "./redux/store";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <RouteContainer />
        </BrowserRouter>
        <ToastContainer />
      </Provider>
    </div>
  );
}

export default App;
