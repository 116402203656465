import { useEffect } from "react";
import { useDispatch } from "react-redux";

const useGetComments = (video, sharing, httpRequest, limit, page,) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if(!sharing && video) {
            const { _id } = video;
            dispatch(httpRequest(_id, limit, page));
        }
    }, [sharing, dispatch, httpRequest, limit, page, video]);
};

export default useGetComments;
