import { useParams, useNavigate } from "react-router-dom";
import Shared from "components/ui/molecules/Shared/Shared";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import {getMeetingFiles} from "redux/actions/meetingRepositoryActions";
import Loader from "components/ui/atoms/Loader";
import FilePreviewPopUp from "components/ui/atoms/FileReaderPopUp";
import BackButton from "assets/icons/BackButton";

const MeetingFiles = ({ containerPadding = true }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    meetingFiles,
    loadingMeetingFiles
  } = useSelector(state => state.meetingRepository);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMeetingFiles(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [file, setFile] = useState({
    url: "",
  });

  const [showPreview, setShowPreview] = useState(false);

  const setFileAndShowPreview = newFile => {
    setFile({ url: newFile });
    setShowPreview(true);
  };

  const onFileChange = event => {
    const fileReader = new window.FileReader();
    const file = event.target.files[0];

    fileReader.onload = fileLoad => {
      const { result } = fileLoad.target;
      setFile({ url: result });
    };

    fileReader.readAsDataURL(file);
  };

  const fullName = file.url?.substring(file.url?.lastIndexOf("/") + 1);
  const fileName = fullName?.substring(0, fullName.lastIndexOf("."));


  return !loadingMeetingFiles ? (
    <React.Fragment>
      <button
          className={styles.backButton}
          onClick={() => navigate(`/meeting-repository/hostMeetings/data/${id}`)}
        >
          <BackButton />
          <p>Go back</p>
        </button>
    <div
      className={`${styles.container} ${containerPadding && styles.containerPadding
        }`}
    >
        <Shared
          fileHistory={meetingFiles || []}
          setFileAndShowPreview={setFileAndShowPreview}
        />
      <div className={styles.infoMeeting}>
        <h1>Shared files</h1>
        <span>Everytime you join the meeting and have shared files you will find them here. <br /><br/>Pay attention: the shared files will be saved for one week only.   </span>
      </div>
      <FilePreviewPopUp file={file.url} title={fileName} fileType={file.url?.split(".").pop()} isOpen={showPreview} onRequestClose={() => setShowPreview(false)} />
    </div>
    </React.Fragment>
  ) : (
    <div className={styles.loader}>
      <Loader />
    </div>
  );
};

MeetingFiles.propTypes = {
  containerPadding: PropTypes.bool,
};

export default MeetingFiles;
