import React, { useRef } from "react";
import styles from "./style.module.scss";
import PropTypes from "prop-types";
import { ReactComponent as Plus } from "assets/images/plus.svg";
import useClickOutside from "components/helpers/useClickOutside";

function TeamsCard({ team, add, index, setActiveMenu, activeMenu }) {
  const openMenu = e => {
    e.preventDefault();
    setActiveMenu(index === activeMenu ? false : index);
  };
  const teamRef = useRef(null);
  useClickOutside(teamRef, () => setActiveMenu(false));

  //Just for First Item which is default (add)
  return add && "first" ? (
    <div
      className={styles.addCard}
      style={{
        border: "1px dashed",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span className={styles.add}>
        <Plus className={styles.plus} />
      </span>
      <p>Invite team groups to dashboard</p>
      <div />
    </div>
  ) : (
    // All Items that we take from dummydata
    <div className={styles.card} ref={teamRef}>
      <button onClick={e => openMenu(e)}>
        <div className={styles.dotsContainer}>
          <span className={styles.dots} />
          <span className={styles.dots} />
          <span className={styles.dots} />
          {index === activeMenu && (
            <div className={styles.menu}>
              <ul>
                <li>Edit Team</li>
                <li>Access to host</li>
                <li>Access for meeting</li>
                <li>Delete</li>
              </ul>
            </div>
          )}
        </div>
      </button>
      <h2 className={styles.name}>{team.name}</h2>
      <p className={styles.info}>
        This group contains {team.subgroup.length} subgroups and in total{" "}
        {team.members.length} members
      </p>
      <div className={styles.images}>
        {team.members.slice(0, 3).map((member, index) => {
          return (
            <img
              key={index}
              className={styles.img}
              src={member.img}
              alt={member.img}
            />
          );
        })}
        <span className={styles.number}>
          <p>{team.members.length}+</p>
        </span>
      </div>
    </div>
  );
}

TeamsCard.propTypes = {
  team: PropTypes.object,
  add: PropTypes.string,
  index: PropTypes.number,
  setActiveMenu: PropTypes.any,
  activeMenu: PropTypes.any,
};

export default TeamsCard;
