/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useState } from "react";
import styles from "./style.module.scss";
import PropTypes from "prop-types";
import { ReactComponent as Person } from "assets/images/person.svg";
import { ReactComponent as Duplicate } from "assets/images/duplicate.svg";
import TwoButtons from "../../atoms/TwoButtons";
import { ReactComponent as ArrowDown } from "../../../../assets/images/arrowdown.svg";
import { ReactComponent as ArrowUp } from "../../../../assets/images/arrowup.svg";

function InterviewSetup3() {
  const [copySuccess, setCopySuccess] = useState("");
  const textAreaRef = useRef(null);

  const copyToClipboard1 = e => {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setCopySuccess("Copied!");
  };

  const [interview, setInterview] = useState(false);
  const [interviewoption, setInterviewOption] = useState("No end date");
  const interviewDate = ["No end date 1", "No end date 2", "No end date 3"];

  return (
    <div className={styles.interviewSetup2}>
      <div>
        <h1>Make ready to share link</h1>
        <div>
          <p>Live until</p>
          <div
            aria-hidden={true}
            role="button"
            className={styles.selectOptions}
            onClick={() => setInterview(!interview)}
          >
            <div className={styles.title}>
              {interview ? (
                <ArrowDown className={styles.arrow} />
              ) : (
                <ArrowUp className={styles.arrow} />
              )}
              <p>{interviewoption}</p>
            </div>
            {interview && (
              <div className={styles.dropdown}>
                {interviewDate.map((item, i) => {
                  return (
                    <p key={i} onClick={() => setInterviewOption(item)}>
                    {item}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div>
          <p>Share with others</p>
          <div className={styles.inputIcon}>
            <input
              type="text"
              name="URL video linkdsdsds"
              ref={textAreaRef}
              value="URL video link"
            />
            {document.queryCommandSupported("copy") && (
              <>
                {copySuccess ? (
                  <p className={styles.success}>{copySuccess}</p>
                ) : (
                  <Duplicate onClick={copyToClipboard1} />
                )}
              </>
            )}
          </div>
        </div>
        <div className={styles.owner}>
          <Person />
          <p>blertina@mwstream.com</p>
          <p>(Owner)</p>
        </div>
        <h4>Almost done! Click ‘’Share’’ to save form</h4>
        <p className={styles.info}>
          You can edit this information at any time. Canditates can be invited
          on the next page. If any questions please contact us.
        </p>
      </div>
      <div className={styles.buttons}>
        <TwoButtons
          firsttext="Back"
          secondtext="Publish"
          extraClass="lightGrayBtn"
        />
      </div>
    </div>
  );
}

export default InterviewSetup3;
InterviewSetup3.propTypes = {
  searchData: PropTypes.array,
  setOpenDashboard: PropTypes.func,
  invite: PropTypes.bool,
  text: PropTypes.string,
};
