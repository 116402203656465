/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import Modal from "../Modal";
import RecordIcon from "../../../atoms/Icons/Record";
import Mic from "../../../atoms/Icons/Mic";
import Camera from "../../../atoms/Icons/Camera";
import Screen from "../../../atoms/Icons/Screen";
import { ReactComponent as ArrowDown } from "../../../../../assets/images/arrowdown.svg";
import { ReactComponent as ArrowUp } from "../../../../../assets/images/arrowup.svg";
import { ReactComponent as Person } from "../../../../../assets/images/person1.svg";

function PopupRecording ({
  title = "Video Recording",
  startRecord,
  close,
  settings,
}) {
  const location = ["Right", "Left", "Flex"];
  const position = ["Bottom", "Middle", "Top"];
  const shape = ["Rounded", "Square"];

  const video = ["Share Screen", "Do not share"];

  const [videoDropdown, setVideoDropdown] = useState(false);
  const [audioDropdown, setAudioDropdown] = useState(false);
  const [cameraDropdown, setCameraDropdown] = useState(false);
  const [customDropdown, setCustomDropdown] = useState(false);
  const [locationDropdown, setLocationDropdown] = useState(false);
  const [positionDropdown, setPositionDropdown] = useState(false);
  const [shapeDropdown, setShapeDropdown] = useState(false);
  const [audiooption, setAudio] = useState("Built-in Microphone");
  const [videooption, setVideo] = useState("No Camera");
  const [screenoption, setScreen] = useState("Share Screen");
  const [customoption, setCustom] = useState("Right");
  const [customoption1, setCustom1] = useState("Bottom");
  const [customoption2, setCustom2] = useState("Rounded");
  const [start, setStart] = useState(false);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedAudioDevice, setSelectedAudioDevice] = useState(null);
  const [audioDevices, setAudioDevices] = useState([]);
  const [feedback, setFeedback] = useState(null);
  const [hasAudioPermission, setHasAudioPermission] = useState(null);
  const [hasVideoPermission, setHasVideoPermission] = useState(null);
  const [permissionError, setPermissionError] = useState(null);

  useEffect(() => {
    async function checkPermissions() {
      const audioStatus = await navigator.permissions.query({
        name: "microphone",
      });
      const videoStatus = await navigator.permissions.query({ name: "camera" });
      setHasAudioPermission(audioStatus.state);
      setHasVideoPermission(videoStatus.state);
    }

    checkPermissions();
  }, []);

  console.log(devices, "audioDevices");

  useEffect(() => {
    console.log("Permissions have just been denied");
  }, [permissionError]);

  async function requestPermissions() {
    try {
      const constraints = {
        audio: hasAudioPermission !== "granted",
        video: hasVideoPermission !== "granted",
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
      setHasAudioPermission("granted");
      setHasVideoPermission("granted");
    } catch (error) {
      console.error("Error requesting media permissions:", error);
      setPermissionError(error.message);
    }
  }

  useEffect(() => {
    // Enumerate devices and set state
    const getDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();

        if (hasVideoPermission === "granted") {
          setDevices([
            { label: "No Camera", deviceId: null },
            ...devices.filter(
              device =>
                device.kind === "videoinput" && device.label && device.deviceId
            ),
          ]);
        }

        if (hasAudioPermission === "granted") {
          setAudioDevices([
            { label: "No audio", deviceId: null },
            ...devices.filter(
              device =>
                device.kind === "audioinput" && device.label && device.deviceId
            ),
          ]);
        }
      } catch (error) {
        console.error("Error fetching devices: ", error);
      }
    };

    // Only call getDevices if either audio or video permissions have been granted.
    if (hasAudioPermission === "granted" || hasVideoPermission === "granted") {
      getDevices();
    }
  }, [hasAudioPermission, hasVideoPermission]);

  let flex = false;

  function handlevideoDropdown () {
    setVideoDropdown(!videoDropdown);
    setAudioDropdown(false);
    setCameraDropdown(false);
    setCustomDropdown(false);
  }
  function handleaudioDropdown () {
    setAudioDropdown(!audioDropdown);
    setVideoDropdown(false);
    setCameraDropdown(false);
    setCustomDropdown(false);
  }
  function handlCameraDropdown () {
    setVideoDropdown(false);
    setAudioDropdown(false);
    setCameraDropdown(!cameraDropdown);
    setCustomDropdown(false);
  }
  function handlecustomDropdown () {
    setVideoDropdown(false);
    setAudioDropdown(false);
    setCameraDropdown(false);
    setCustomDropdown(!customDropdown);
  }

  function positionOfCam () {
    if (customoption === "Right" && customoption1 === "Top") return "topRight";
    else if (customoption === "Right" && customoption1 === "Middle")
      return "centerRight";
    else if (customoption === "Right" && customoption1 === "Bottom")
      return "bottomRight";
    else if (customoption === "Left" && customoption1 === "Top")
      return "topLeft";
    else if (customoption === "Left" && customoption1 === "Middle")
      return "centerLeft";
    else if (customoption === "Left" && customoption1 === "Bottom")
      return "bottomLeft";
    else if (customoption === "Flex") {
      flex = true;
      return "centerRight";
    } else return "bottomRight";
  }

  const settingsOptions = () => {
    const screen = screenoption !== "Do not share" ? true : false;
    const audio = audiooption !== "No audio" ? true : false;
    const camera = videooption !== "No Camera" ? true : false;
    const form = () => {
      switch (customoption2) {
        case "Square":
          return "square";
        case "Rounded":
          return "roundedSqaure";
        default:
          return "square";
      }
    };

    return {
      screen: screen,
      audio: audio,
      camera: camera,
      positionCamera: positionOfCam(),
      formOfCamera: form(),
      flex: flex,
      selectedCamera: selectedDevice,
      selectedAudioDevice: selectedAudioDevice,
    };
  };

  useEffect(() => {
    start && startRecord();
  }, [start]);

  const allPermissionsGranted =
    hasAudioPermission === "granted" && hasVideoPermission === "granted";

  const permissionsDenied =
    hasAudioPermission === "denied" || hasVideoPermission === "denied";
  return (
    <Modal close={close}>
      <div className={styles.contentModal} onClick={e => e.stopPropagation()}>
        <div className={styles.banner}>
          <h2>{title}</h2>
        </div>
        <div className={styles.content}>
          {!allPermissionsGranted && !permissionsDenied && !permissionError ? (
            <div>
              <p>
                We need access to your{" "}
                {hasAudioPermission !== "granted" && "microphone"}
                {hasAudioPermission !== "granted" &&
                  hasVideoPermission !== "granted" &&
                  " and "}
                {hasVideoPermission !== "granted" && "camera"} to enable
                customization. Please click the button below.
              </p>

              <button onClick={requestPermissions}>Grant Permissions</button>

              {/* {permissionError && (
                <p style={{ color: "red" }}>Error: {permissionError}</p>
              )} */}
            </div>
          ) : null}

          {(permissionsDenied || permissionError) && (
            <div>
              <p>
                It seems you've denied access to your microphone or camera. To
                proceed, please manually allow access:
              </p>
              <br />
              <ol className={styles.permissionInstructions}>
                <li>
                  Click on the camera/mic icon in the browser's address bar.
                </li>

                <li> Allow access to the microphone and/or camera.</li>
                <br />
                <li> Reload the page.</li>
              </ol>
            </div>
          )}

          {allPermissionsGranted && !permissionsDenied && (
            <>
              <span>
                Free screen recording tools, for you to transform your
                prospecting communications.
              </span>
              <div className={styles.settings}>
                <p className={styles.settingsTitle}>Recording Settings</p>
                <div
                  className={styles.select}
                  onClick={() => handlevideoDropdown()}
                >
                  {videoDropdown ? (
                    <ArrowDown className={styles.arrow} />
                  ) : (
                    <ArrowUp className={styles.arrow} />
                  )}
                  <div className={styles.title}>
                    <Screen className={styles.icon} />
                    <p>{screenoption}</p>
                  </div>
                  {videoDropdown && (
                    <div className={styles.dropdown}>
                      {video.map((item, i) => {
                        return (
                          <p
                            key={i}
                            onClick={() => {
                              setScreen(item);
                            }}
                          >
                            {item}
                          </p>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div
                  className={`${styles.select}  ${styles.second}`}
                  onClick={() => handleaudioDropdown()}
                >
                  {audioDropdown ? (
                    <ArrowDown className={styles.arrow} />
                  ) : (
                    <ArrowUp className={styles.arrow} />
                  )}
                  <div className={styles.title}>
                    <Mic className={styles.icon} />
                    <p>{audiooption}</p>
                  </div>
                  {audioDevices.length && audioDropdown && (
                    <div className={styles.dropdown}>
                      {audioDevices.map(({ label, deviceId }, i) => {
                        return (
                          <p
                            key={i}
                            onClick={() => {
                              setAudio(label);
                              setSelectedAudioDevice(deviceId);
                            }}
                          >
                            {label}
                          </p>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div
                  className={`${styles.select}  ${styles.third}`}
                  onClick={() => handlCameraDropdown()}
                >
                  {cameraDropdown ? (
                    <ArrowDown className={styles.arrow} />
                  ) : (
                    <ArrowUp className={styles.arrow} />
                  )}
                  <div className={styles.title}>
                    <Camera className={styles.icon} />
                    <p>{videooption}</p>
                  </div>
                  {cameraDropdown && (
                    <div className={styles.dropdown}>
                      {devices.length &&
                        devices.map(({ label, deviceId }, i) => {
                          return (
                            <p
                              key={i}
                              onClick={() => {
                                setVideo(label);
                                setSelectedDevice(deviceId);
                              }}
                            >
                              {label}
                            </p>
                          );
                        })}
                    </div>
                  )}
                </div>
                {videooption !== "No Camera" && (
                  <div
                    className={`${styles.select}  ${styles.fourth}`}
                    onClick={() => {
                      setLocationDropdown(!locationDropdown);
                      setPositionDropdown(!positionDropdown);
                      setShapeDropdown(!shapeDropdown);
                      handlecustomDropdown();
                    }}
                  >
                    {customDropdown ? (
                      <ArrowDown className={styles.arrow} />
                    ) : (
                      <ArrowUp className={styles.arrow} />
                    )}
                    <div className={styles.title}>
                      <Person className={styles.icon} />
                      <p>
                        {customoption} {customoption1} {customoption2}
                      </p>
                    </div>
                  </div>
                )}
                {customDropdown && (
                  <div className={`${styles.select} ${styles.select1}`}>
                    <div className={`${styles.dropdown} ${styles.dropdown1}`}>
                      {locationDropdown && (
                        <div>
                          <h3 aria-disabled>Location</h3>
                          <div className={styles.wrapper}>
                            {location.map((item, i) => {
                              return (
                                <div className={styles.radio}>
                                  <div className={styles.input}>
                                    <input
                                      defaultChecked={i == 0}
                                      name='location'
                                      type='radio'
                                      value={item}
                                      key={i}
                                      onClick={() => setCustom(item)}
                                    />
                                    <label for={item}> {item} </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      {positionDropdown && (
                        <div>
                          <h3 aria-disabled>Position</h3>
                          <div className={styles.wrapper}>
                            {position.map((item, i) => {
                              return (
                                <div className={styles.radio}>
                                  <div className={styles.input}>
                                    <input
                                      defaultChecked={i == 0}
                                      name='position'
                                      type='radio'
                                      value={item}
                                      key={i}
                                      onClick={() => setCustom1(item)}
                                    />
                                    <label for={item}> {item} </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      {shapeDropdown && (
                        <div>
                          <h3 aria-disabled>Shape</h3>
                          <div className={styles.wrapper}>
                            {shape.map((item, i) => {
                              return (
                                <div className={styles.radio}>
                                  <div className={styles.input}>
                                    <input
                                      defaultChecked={i == 0}
                                      name='shape'
                                      type='radio'
                                      value={item}
                                      key={i}
                                      onClick={() => setCustom2(item)}
                                    />
                                    <label for={item}> {item} </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <p className={styles.feedback}>{feedback}</p>
              <button
                onClick={() => {
                  const { screen, audio, camera } = settingsOptions();
                  if (!screen && !audio && !camera) {
                    setFeedback(
                      "Please select the media that you want to capture"
                    );
                    return;
                  }
                  settings(settingsOptions());
                  setStart(true);
                }}
              >
                <RecordIcon /> Start recording
              </button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

PopupRecording.propTypes = {
  title: PropTypes.string,
  startRecord: PropTypes.func,
  close: PropTypes.func,
  settings: PropTypes.func,
};
export default PopupRecording;
