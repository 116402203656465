import React from "react";
import styles from "./style.module.scss";
import demo from "assets/images/3dpic.png";

function DashboardIntro() {
  return (
    <div className={styles.container}>
      <img src={demo} alt="mw product demo" />
      <div>
        <h1>
          My Video <br /> <span>Dashboard</span>
        </h1>
        <p>
          <span>Video Dashboard </span> - All multimedia options in just one place
        </p>
      </div>
    </div>
  );
}
export default DashboardIntro;
