/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/ui/atoms/Loader";
import TableView from "components/ui/molecules/MeetingListTableView/MeetingListTableView";
import GridView from "components/ui/molecules/MeetingGridView";
import NothingToDisplay from "components/ui/atoms/NothingToDisplay";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  getHostMeetings,
  getHostMeetingsRecordings,
  getMyHostedMeetings,
} from "redux/actions/meetingRepositoryActions";
import { selectFilteredHostMeetings } from "redux/selectors";
import Pagination from "components/ui/atoms/Paginate/Index";

function MeetingList ({ type = "row" }) {
  const {
    loading,
    loadingMyHostedMeetings,
    myHostedMeetings
  } = useSelector(
    state => state.meetingRepository
  );

  const {
    data,
    page: currentPage,
    perPage: itemsPerPage,
    totalMeetings: totalItems,
    totalPages,
  } = useSelector(selectFilteredHostMeetings);

  const {
    user: { email },
  } = useSelector(state => state.authReducer);
  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(getHostMeetingsRecordings(email));
    dispatch(getMyHostedMeetings(email));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [parent, setParent] = useState(0);
  const [history, setHistory] = useState([0]);
  const [filterArray, setFilterArray] = useState([]);
  const [asc, setAsc] = useState(false);
  const [_data, setData] = useState(null);

  const navigate = useNavigate();

  const sortAtoZ = type => {
    let sortedArray = [...filterArray];
    setAsc(!asc);
    sortedArray.sort((a, b) => {
      const isReversed = asc ? -1 : 1;
      return isReversed * a[type].localeCompare(b[type]);
    });
    setFilterArray(sortedArray);
  };

  const filterHandler = type => {
    switch (type) {
      case "roomId":
        sortAtoZ(type);
        break;
      default:
        return filterArray;
    }
  };


  const setPathFromChild = (index, type) => {
    if (type === "folder") {
      setParent(index);
      let historyArray = [...history, index];
      setHistory(historyArray);
    }
  };

  const filterParentData = tableData => {
    let filter = tableData
      .map(item => ({ ...item, parent: 0 }))
      .filter(a => {
        return a.parent === parent;
      })
      .sort((a, b) => {
        const isReversed = -1;
        if (a.type && b.type) {
          return isReversed * a.type.localeCompare(b.type);
        }
        return 0;
      });
    setFilterArray(filter);
  };

  useEffect(() => {
    if (!loading && data?.length) {
      filterParentData(data);
    }
  }, [parent, data]);

  const handleMoveToFolder = result => {
    const newArray = [..._data];

    const arrayToSave = newArray.map(item => {
      if (item.index === parseInt(result.draggableId)) {
        return {
          ...item,
          parent: parseInt(result.destination.droppableId),
        };
      } else {
        return item;
      }
    });

    setData(arrayToSave);

    filterParentData(arrayToSave);
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        (!loadingMyHostedMeetings) &&
        (myHostedMeetings.length > 0 ? (
          <>
            {type === "row" ? (
              <TableView
                filterHandler={filterHandler}
                list={filterArray}
                meetingList={myHostedMeetings}
                loadingMeetingList={loadingMyHostedMeetings}
                pathHandler={setPathFromChild}
                moveToFolder={handleMoveToFolder}
                dropDown={false}
              />
            ) : (
              <GridView
                meetingList={myHostedMeetings}
                loadingMeetingList={loadingMyHostedMeetings}
                data={data}
                list={filterArray}
                pathHandler={setPathFromChild}
                dropDown={false}
              />
            )}
           { data.length > 0 ? <Pagination
              data={data}
              currentPage={currentPage}
              totalPages={totalPages}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              onPageChange={page => dispatch(getHostMeetings(email, itemsPerPage, page))}
            /> : null}
          </>
        ) : (
          <NothingToDisplay />
        ))
      )}
    </Fragment>
  );
}

MeetingList.propTypes = {
  type: PropTypes.string,
};

export default MeetingList;
