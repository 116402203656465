import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import axios from "axios";
import DropdownSelect from "../DropdownSelect";

function SpecialRegionInput({
  label,
  placeholder,
  country,
  required,
  onChange,
  value,
  disabled,
}) {
  const [arrayOfStates, setArrayOfStates] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    arrayOfStates[0] || null
  );

  const fetchRegions = async () => {
    if (country) {
      setSelectedOption(null);
      const response = await axios.post(
        `https://countriesnow.space/api/v0.1/countries/states`,
        { country: country }
      );
      const regions = response.data.data.states.map(region => region.name);
      setArrayOfStates(regions);
      if (regions.includes(value)) {
        setSelectedOption(value);
      } else {
        setSelectedOption(regions[0]);
      }
    }
  };

  useEffect(() => {
    if (country) {
      fetchRegions();
    }
  }, [country]);

  const changeRegion = region => {
    setSelectedOption(region);
    onChange(region);
  };

  return (
    <div className={styles.inputGroup}>
      <label>{label}</label>
      {country && selectedOption ? (
        <DropdownSelect
          list={arrayOfStates}
          disabled={disabled}
          onChange={changeRegion}
          selected={selectedOption}
        />
      ) : (
        <input
          placeholder={placeholder}
          required={required}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          disabled={disabled}
        />
      )}
    </div>
  );
}
export default SpecialRegionInput;
SpecialRegionInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  country: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  disabled: PropTypes.bool,
};
