import { useEffect } from "react";
import PropTypes from "prop-types";

const useCloseMenu = (ref, setActive) => {
    useEffect(() => {
        document.addEventListener("click", ev => {
            if (!ref?.current?.contains(ev.target)) {
                setActive(false);
            }
        });

        return () => {
            document.removeEventListener("click", () => {});
        };
    }, [ref, setActive]);
};

export default useCloseMenu;

useCloseMenu.propTypes = {
    ref: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
};
