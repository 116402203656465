const initialState = {
  searchText: "",
};

const SearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SEARCH_TEXT":
      return {
        ...state,
        searchText: action.payload,
      };
    case "CLEAR_SEARCH_TEXT":
      return {
        ...state,
        searchText: "",
      };
    default:
      return state;
  }
};

export default SearchReducer;