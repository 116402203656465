import jwt from "jsonwebtoken";
import { LOGIN, LOGOUT_USER, } from "../actions/actionTypes";
import Cookies from "js-cookie";

// const token = localStorage.getItem("authToken");
const token  = Cookies.get("authCode");
const initialState = {
  isAuthenticated: !token,
  user: token !== null && token !== undefined ? jwt.decode(token) : {},
  loading: false,
  error: null,
  status: "",
};

function auth(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN.INIT:
      return {
        ...state,
        loading: true,
      };

    case LOGIN.SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: jwt.decode(action.user),
        loading: false,
        status: "succeeded"
      };


    case LOGIN.ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        status: "failed",
      };

    case LOGOUT_USER.SUCCESS:
    return {
      loading: false,
      isAuthenticated: false,
      error: null,
    };

    default:
      return state;
  }
}

export default auth;
