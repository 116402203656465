import * as types from "../actions/commentTypes";
import removeComment from "./utilities/removeComment";

const INITIAL_DATA = {
  error: false,
  isFetching: true,
  allComments: [],
  newComment: {},
  editedComment: {},
  deletedComment: {},
  errorMessage: "",
};

const commentReducer = (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case types.CREATE_COMMENT:
      return {
        ...state,
        newComment: action.payload,
        allComments: [...state.allComments, action.payload],
        error: false,
        errorMessage: "",
        isFetching: false,
      };

    case types.CREATE_COMMENT_ERROR:
      return {
        ...state,
        error: true,
        newComment: {},
        errorMessage: action.payload,
      };

    case types.GET_ALL_COMMENTS:
      return {
        ...state,
        isFetching: false,
        allComments: action.payload,
        errorMessage: "",
      };

    case types.GET_ALL_COMMENTS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: true,
        allComments: [],
        errorMessage: action.payload,
      };

    case types.EDIT_COMMENT:
      return {
        ...state,
        editedComment: action.payload,
        error: false,
        isFetching: false,
      };

    case types.EDIT_COMMENT_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
      };

    // case types.DELETE_COMMENT:
    //     return {
    //         ...state,
    //         deletedComment: action.payload,
    //         error: false,
    //         isFetching: false
    //     };

    case types.DELETE_COMMENT:
      // 1. Remove the comment from allComments array
      const updatedAllComments = state.allComments.filter(
        comment => comment._id !== action.payload._id
      );

      // 2. Check and remove from newComment or editedComment if the id matches
      let updatedNewComment = state.newComment;
      if (updatedNewComment._id === action.payload._id) {
        updatedNewComment = {};
      }

      let updatedEditedComment = state.editedComment;
      if (updatedEditedComment._id === action.payload._id) {
        updatedEditedComment = {};
      }

      return {
        ...state,
        allComments: updatedAllComments,
        newComment: updatedNewComment,
        editedComment: updatedEditedComment,
        deletedComment: action.payload,
        error: false,
        isFetching: false,
      };

    case types.DELETE_COMMENT_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        isFetching: false,
      };

    default:
      return state;
  }
};

export default commentReducer;
