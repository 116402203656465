import React, {Fragment, useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";
import GridView from "../GridView";
import TableView from "../TableView";
import { ReactComponent as BackButton } from "../../../../assets/images/backButton.svg";
import { useNavigate } from "react-router-dom";
import { getfavoriteVideos } from "../../../../redux/actions/videoActions";
import Loader from "../../atoms/Loader";
import NothingToDisplay from "../../atoms/NothingToDisplay";
import Pagination from "../../atoms/Paginate/Index";
import { networkErrorHandler } from "../../../helpers/networkErrorHandler";
import {selectFavouriteFilteredVideos} from "redux/selectors";

const Items = ({ type }) => {
  const LIMIT = 10;
  const { isFetching } = useSelector(state => state?.video);
  const [data, setData] = useState(null);
  const [filterArray, setFilterArray] = useState([]);
  const [asc, setAsc] = useState(false);
  const [parent, setParent] = useState(0);
  const [history, setHistory] = useState([0]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, message } = useSelector(state => state.video);

  const {
    data: { videos: favouriteVideos },
    page: currentPage,
    perPage: itemsPerPage,
    totalFavouriteVideos: totalItems,
    totalPages,
  } = useSelector(selectFavouriteFilteredVideos);

  useEffect(() => {
    dispatch(getfavoriteVideos({limit: LIMIT}));
    networkErrorHandler(error, message);
  }, []);

  const filterParentData = tableData => {
    let filter = tableData
      .map(item => ({ ...item, parent: 0 }))
      .filter(a => {
        return a.parent === parent;
      })
      .sort((a, b) => {
        const isReversed = -1;
        return isReversed * a.type.localeCompare(b.type);
      });
    setFilterArray(filter);
  };

  useEffect(() => {
    if (!isFetching && favouriteVideos.length) {
      filterParentData(favouriteVideos);
    }
  }, [parent, favouriteVideos]);

  const sortAtoZ = type => {
    let sortedArray = [...filterArray];
    setAsc(!asc);
    sortedArray.sort((a, b) => {
      const isReversed = asc ? -1 : 1;
      return isReversed * a[type].localeCompare(b[type]);
    });
    setFilterArray(sortedArray);
  };

  const filterHandler = type => {
    switch (type) {
      case "title":
      case "createdAt":
      case "author":
        sortAtoZ(type);
        break;
      case "size":
        let sizeArray = [...filterArray];
        setAsc(!asc);
        sizeArray.sort((a, b) => {
          if (a.size > b.size) return asc ? -1 : 1;
          if (a.size < b.size) return asc ? 1 : -1;
          return 0;
        });
        setFilterArray(sizeArray);
        break;
      default:
        return filterArray;
    }
  };

  const setPathFromChild = (index, type) => {
    if (type === "folder") {
      setParent(index);
      let historyArray = [...history, index];
      setHistory(historyArray);
    } else {
      navigate(`/video-player/${index}`);
    }
  };

  const backHandler = () => {
    history.pop();
    setParent(history[history.length - 1]);
  };

  const handleMoveToFolder = result => {
    const newArray = [...data];

    const arrayToSave = newArray.map((item, index) => {
      if (item.index === parseInt(result.draggableId)) {
        return {
          ...item,
          parent: parseInt(result.destination.droppableId),
        };
      } else {
        return item;
      }
    });

    setData(arrayToSave);

    filterParentData(arrayToSave);
  };

  return (
    <Fragment>
      {isFetching ? <Loader /> : !isFetching && (
        favouriteVideos?.length > 0 ?
          <>
            {type === "row" ? (
              <TableView
                filterHandler={filterHandler}
                list={filterArray}
                pathHandler={setPathFromChild}
                moveToFolder={handleMoveToFolder}
                dropDown={true}
                disabledItems={["favourites"]}
              />
            ) : (
              <GridView list={filterArray} pathHandler={setPathFromChild} dropDown={true} />
            )}
            {
            filterArray?.length > 0 ?
            <Pagination
              currentPage={currentPage}
              data={favouriteVideos}
              itemsPerPage={itemsPerPage}
              onPageChange={page =>
                dispatch(getfavoriteVideos({ page, limit: itemsPerPage }))
              }
              totalItems={totalItems}
              totalPages={totalPages}
            />
            : null
        }
          </> : <NothingToDisplay />
      )}

    </Fragment>
  );
};

export default Items;
Items.propTypes = {
  type: PropTypes.string,
};
