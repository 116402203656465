import React, {useState} from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import {ReactComponent as Triangle} from "../../../../assets/images/triangle.svg";

const TableHead = ({ handler }) => {

    const [active, setActive] = useState("");

    const onSort = (type) => {
        setActive(type);
        handler(type);
    };

    return (
        <tr className={styles.row}>
            <td className={styles.nameColumn}>
                <span
                    className={`${active === "name" && styles.active}`}
                    onClick={() => onSort("name")} onKeyDown={() => onSort("name")} role="button" tabIndex={0}>
                    Name
                    {/* <Triangle/> */}
                </span>
            </td>
            <td className={styles.dateColumn}>
                <span
                    className={`${active === "date" && styles.active}`}
                    onClick={() => onSort("createdAt")} onKeyDown={() => onSort("createdAt")} role="button" tabIndex={0}>
                    Date Added
                    <Triangle/>
                </span>
            </td>
            <td className={styles.sizeColumn}>
                <span
                    className={`${active === "size" && styles.active}`}
                    onClick={() => onSort("size")} onKeyDown={() => onSort("size")} role="button" tabIndex={0}>
                    Size
                    {/* <Triangle/> */}
                </span>
            </td>
            <td className={styles.organiserColumn}>
                <span
                    className={`${active === "organiser" && styles.active}`}
                    onClick={() => onSort("organiser")} onKeyDown={() => onSort("organiser")} role="button" tabIndex={0}>
                    Organiser
                    {/* <Triangle/> */}
                </span>
            </td>
        </tr>
    );
};

export default TableHead;
TableHead.propTypes = {
    handler: PropTypes.func
};
