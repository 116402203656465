import styles from "./style.module.scss";
import PropTypes from "prop-types";
import DeletePopup from "components/ui/organisms/DeletePopup";
import DownloadPopup from "components/ui/organisms/DownloadPopup";
import SharePopup from "components/ui/organisms/SharePopup";
import ArchivePopup from "components/ui/organisms/ArchivePopup";
import FavouritePopup from "components/ui/organisms/FavoritePopUp/Index";
import RenamePopup from "components/ui/organisms/RenamePopup";
import { useNavigate } from "react-router-dom";
import {
  updateVideo,
  deleteVideo,
  downloadVideo,
  duplicateVideo,
  archiveVideo,
} from "../../../../redux/actions/videoActions";
import { useDispatch, useSelector } from "react-redux";
import { selectVideoById } from "redux/selectors";

function DropDownMenu ({
  id,
  duplicateLocation,
  activeMenu,
  setActiveMenu,
  freePosition,
  toUp,
  onVideoPlayer,
  myRef
}) {
  const { patchStatus, addStatus, downloadStatus } = useSelector(
    state => state.video
  );

  const video = useSelector(s => selectVideoById(s, id));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let freeStyle;
  if (freePosition) {
    freeStyle = {
      left: `${freePosition?.posX}px`,
      right: "unset",
      bottom: "30px",
    };
  }

  const handleTitleUpdate = (id, title, callBack) => {
    if (!title) {
      return;
    }
    dispatch(
      updateVideo(id, { title }, () => {
        callBack && callBack();
        setActiveMenu(false);
      })
    );
  };

  const handleFavourite = (id, setActiveMenu) => {
    dispatch(updateVideo(id, { favourite: true }, () => setActiveMenu(false)));
  };

  const handleArchive = (id, setActiveMenu) => {
    dispatch(archiveVideo(id, { archived: true }, () => setActiveMenu(false)));
  };

  const handleDownload = async response => {
    try {
      // Create a blob URL and anchor element to trigger the download
      const downloadURL = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadURL;
      link.setAttribute("download", `${video?.title || "video"}.mp4`); // Use your desired file name and extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading the video:", error);
    }
  };

  return (
    <div
      ref={myRef}
      className={`${styles.dropdown} ${activeMenu && styles.showDropdown} ${
        toUp && styles.dropDownUp
      }`}
      style={freePosition ? freeStyle : null}
      onClick={e => e.stopPropagation()}
      aria-hidden
    >
      <span className={styles.buttonHover}>
        <RenamePopup
          title='Rename'
          handleFunction={handleTitleUpdate}
          loading={patchStatus}
          firsttext='Cancel'
          secondtext='Rename'
          id={id}
          previousTitle={video?.title}
        />
      </span>

      <button
        className={styles.buttonHover}
        onClick={() =>
          dispatch(
            duplicateVideo(id, () => setActiveMenu(false)),
            duplicateLocation
          )
        }
      >
        {addStatus ? "Duplicating" : "Duplicate"}
      </button>

      {!video?.archived ? (
        <span className={styles.buttonHover}>
          <ArchivePopup
            handleFunction={() => handleArchive(id, setActiveMenu)}
            loading={patchStatus}
          />
        </span>
      ) : null}

      <span className={styles.buttonHover}>
        <SharePopup id={id} closeMenu={() => setActiveMenu(false)} />
      </span>

      <span className={styles.buttonHover}>
        <DownloadPopup
          loading={downloadStatus}
          handleFunction={() =>
            dispatch(
              downloadVideo(id, async response => {
                setActiveMenu(false);
                await handleDownload(response);
              })
            )
          }
          firsttext='Cancel'
          secondtext={!downloadStatus ? "Download" : "Downloading..."}
          size={video?.size}
        />
      </span>

      <span className={styles.buttonHover}>
        <DeletePopup
          handleFunction={() =>
            dispatch(
              deleteVideo(id, () => {
                setActiveMenu(false);
                onVideoPlayer && navigate(-1);
              })
            )
          }
          firsttext='Cancel'
          secondtext='Delete'
          loading={patchStatus}
        />
      </span>
      {!video?.favourite ? (
        <span className={styles.buttonHover}>
          <FavouritePopup
            handleFunction={() => handleFavourite(id, setActiveMenu)}
            loading={patchStatus}
          />
        </span>
      ) : null}
    </div>
  );
}

export default DropDownMenu;

DropDownMenu.propTypes = {
  id: PropTypes.string,
  activeMenu: PropTypes.bool || PropTypes.string,
  setActiveMenu: PropTypes.func,
  freePosition: PropTypes.object,
  toUp: PropTypes.bool,
  onVideoPlayer: PropTypes.bool,
  duplicateLocation: PropTypes.string,
  disabledItems: PropTypes.array,
  myRef: PropTypes.any
};

DropDownMenu.defaultProps = {
  disabledItems: []
};
