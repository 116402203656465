import React from "react";
import  styles  from "./style.module.scss";
import {ReactComponent as Close} from "../../../../assets/images/close2.svg";
import PropTypes from "prop-types";

function Dashboard({ setOpenDashboard }) {
  return (
    <div className={styles.dashboard}>
      <div className={styles.dashboardBox}>
        <div className={styles.topContent}>
          <h4>Invite team to board</h4>
          <Close
            className={styles.closeIcon}
            onClick={() => setOpenDashboard(false)}
          />
        </div>
        <div className={styles.midContent}>
          <h5>Add people</h5>
          <div>
            <input placeholder="Enter email addresses, separated by comma" />
            <button>Send</button>
          </div>
        </div>
        <div className={styles.bottomContent}>
          <div className={styles.link}>
            <h5>Invite with link</h5>
            <p>Anyone with the link can join as board member</p>
          </div>
          <button>Create Link</button>
        </div>
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  setOpenDashboard: PropTypes.func,
};

export default Dashboard;