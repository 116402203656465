import { useEffect } from "react";
import { useSelector } from "react-redux";

const useGetTotalComments = (setTotalComments) => {
    const totalComments = useSelector((state) => state?.video?.paginationData?.total);
    useEffect(() => {
        setTotalComments(totalComments);
    }, [setTotalComments, totalComments]);
};

export default useGetTotalComments;
