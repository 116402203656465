import { RECORDING } from "../actionTypes";

export const recordingInit = () => ({
  type: RECORDING.INIT,
});

export const recordingSuccess = payload => ({
  type: RECORDING.SUCCESS,
  payload,
});

export const recordingError = error => ({
  type: RECORDING.ERROR,
  error,
});
