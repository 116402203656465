import React, { useState } from "react";
import Popup from "../../atoms/Popup";
import TwoButtons from "components/ui/atoms/TwoButtons";
import PopupButton from "../../atoms/Popup/PopupButton";
import PropTypes from "prop-types";

function FavouritePopup({ handleFunction, loading }) {
  const [popup, setPopup] = useState(false);

  const hidepopup = () => {
    setPopup(false);
  };
  const openpopup = () => {
    setPopup(true);
  };
  return (
    <>
      <PopupButton
        // extraClass="grayBtn1"
        title="Favourite"
        openPopup={openpopup}
      />
      <Popup removePopup={hidepopup} title="Favourite" state={popup}>
        <p>By hitting Favourite, you add this video to favourites.</p>
        <TwoButtons
          firsttext="Cancel"
          secondtext={loading ? "Favouriting.." : "Favorite"}
          handleFunction={handleFunction}
          handleCancel={hidepopup}
          extraClass="grayBtn"
        />
      </Popup>
    </>
  );
}

FavouritePopup.propTypes = {
  handleFunction: PropTypes.func,
  loading: PropTypes.bool,
};

export default FavouritePopup;
