import React, { useRef } from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
// import { ReactComponent as Favourite } from "assets/images/favourite.svg";
import FavouriteIcon from "assets/icons/Favourite";
import ArchiveIcon from "assets/icons/ArchiveIcon";
import { ReactComponent as Folder } from "../../../../assets/images/folder.svg";
import image from "../../../../assets/images/video-card-1.webp";
import useClickOutside from "components/helpers/useClickOutside";
import DropDownMenu from "../DropDownMenu";
import altImage from "assets/images/videointerview.jpg";

function LibraryCard({
  docs = [],
  _id,
  sizeInBytes,
  createdAt,
  type,
  pathHandler,
  favourite,
  archived,
  activeMenu,
  setActiveMenu,
  dropDown
}) {
  // const [fav, setFav] = useState(false);

  // const openMenu = e => {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   setActiveMenu(_id === activeMenu ? false : _id);
  // };
  const teamRef = useRef(null);
  // useClickOutside(teamRef, () => setActiveMenu(false));


  return (
    <div className={styles.videoCard}>
      <div
        className={styles.imagePart}
        onClick={() => pathHandler(docs[0]?.roomId, type, docs[0]?.activeRoom)}
        onKeyDown={() => pathHandler(docs[0]?.roomId, type, docs[0]?.activeRoom)}
        role="button"
        tabIndex={0}
      >
        {type === "file" ? (
          <img src={image} alt="library" onError={(e) => {e.target.src = altImage}}  />
        ) : (
          <Folder style={{ width: "80%", height: "150px" }} />
        )}
        <div className={styles.bottom}>
          <div>{favourite && <FavouriteIcon color="red" />}</div>
          <div>{archived && <ArchiveIcon />}</div>
          <div>
            <p>{sizeInBytes} mb</p>
          </div>
        </div>
      </div>
      <div className={styles.heading} ref={teamRef}>
        <span
          onClick={() => pathHandler(docs[0]?.roomId, type, docs[0]?.activeRoom)}
          onKeyDown={() => pathHandler(docs[0]?.roomId, type, docs[0]?.activeRoom)}
          role="button"
          tabIndex={0}
        >
          {_id}
        </span>
        {/* <button onClick={e => openMenu(e)}>
          <div></div>
          <div></div>
          <div></div>
        </button> */}
      </div>
      <p>{createdAt ? new Date(createdAt).toLocaleDateString(): ""}</p>

      {dropDown && _id === activeMenu && (
        <DropDownMenu
        id={_id}
        activeMenu={activeMenu}
        setActiveMenu={setActiveMenu}
        />
      )}
    </div>
  );
}
export default LibraryCard;
LibraryCard.propTypes = {
  title: PropTypes.string,
  roomId: PropTypes.string,
  _id: PropTypes.number,
  createdAt: PropTypes.string,
  sizeInBytes: PropTypes.string,
  type: PropTypes.string,
  dropdownItem: PropTypes.number,
  dropdown: PropTypes.func,
  listIndex: PropTypes.number,
  pathHandler: PropTypes.func,
  favourite: PropTypes.bool,
  archived: PropTypes.bool,
  activeMenu: PropTypes.any,
  setActiveMenu: PropTypes.func,
  dropDown: PropTypes.bool,
  docs: PropTypes.array
};
