import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
function PublicPanel({ children }) {
  return <div className={styles.publicPanel}>{children}</div>;
}
export default PublicPanel;
PublicPanel.propTypes = {
  children: PropTypes.node,
};
