
export const Questions = [
    {
        question:"Question 1",
        description:"How do you handle pressure?"
    },
    {
        question:"Question 2",
        description:"What is the most interesting thing about you that the CV is not reflecting?"
    },
    {
        question:"Question 3",
        description:"Share stories about sources of motivation..."
    },
    {
        question:"Question 4",
        description:"Explain how you have worked with your weaknesses?"
    }
];