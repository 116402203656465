import React from "react";

export default function Screen() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.54199 2.5C2.50824 2.5 1.66699 3.34125 1.66699 4.375V12.7083C1.66699 13.7421 2.50824 14.5833 3.54199 14.5833H7.08366V16.6667H5.62533C5.54251 16.6655 5.46028 16.6808 5.38342 16.7117C5.30657 16.7426 5.23662 16.7884 5.17764 16.8466C5.11866 16.9047 5.07182 16.974 5.03985 17.0504C5.00789 17.1268 4.99142 17.2088 4.99142 17.2917C4.99142 17.3745 5.00789 17.4565 5.03985 17.5329C5.07182 17.6093 5.11866 17.6786 5.17764 17.7368C5.23662 17.7949 5.30657 17.8408 5.38342 17.8717C5.46028 17.9025 5.54251 17.9178 5.62533 17.9167H14.3753C14.4581 17.9178 14.5404 17.9025 14.6172 17.8717C14.6941 17.8408 14.764 17.7949 14.823 17.7368C14.882 17.6786 14.9288 17.6093 14.9608 17.5329C14.9928 17.4565 15.0092 17.3745 15.0092 17.2917C15.0092 17.2088 14.9928 17.1268 14.9608 17.0504C14.9288 16.974 14.882 16.9047 14.823 16.8466C14.764 16.7884 14.6941 16.7426 14.6172 16.7117C14.5404 16.6808 14.4581 16.6655 14.3753 16.6667H12.917V14.5833H16.4587C17.4924 14.5833 18.3337 13.7421 18.3337 12.7083V4.375C18.3337 3.34125 17.4924 2.5 16.4587 2.5H3.54199ZM10.0003 5C10.9195 5 11.667 5.7475 11.667 6.66667C11.667 7.58583 10.9195 8.33333 10.0003 8.33333C9.08116 8.33333 8.33366 7.58583 8.33366 6.66667C8.33366 5.7475 9.08116 5 10.0003 5ZM7.81283 9.16667H12.1878C12.5903 9.16667 12.917 9.49333 12.917 9.89583V10.3825C12.917 11.3221 11.6112 12.0833 10.0003 12.0833C8.38949 12.0833 7.08366 11.3221 7.08366 10.3825V9.89583C7.08366 9.49333 7.41033 9.16667 7.81283 9.16667ZM8.33366 14.5833H11.667V16.6667H8.33366V14.5833Z"
        fill="#C1C1C1"
      />
    </svg>
  );
}
