import React, { useRef } from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import FavouriteIcon from "assets/icons/Favourite";
import ArchiveIcon from "assets/icons/ArchiveIcon";
import { ReactComponent as Folder } from "../../../../assets/images/folder.svg";
import image from "../../../../assets/images/video-card-1.webp";
// import useClickOutside from "components/helpers/useClickOutside";
import DropDownMenu from "../DropDownMenu";
import altImage from "assets/images/videointerview.jpg";

function LibraryCard({
  sizeInBytes,
  staticTitle = "",
  _id,
  createdAt,
  type,
  pathHandler,
  activeMenu,
  setActiveMenu,
  dropDown = false
}) {

  const teamRef = useRef(null);
  // useClickOutside(teamRef, () => setActiveMenu(false));

  return (
    <div className={styles.videoCard}>
      <div
        className={styles.imagePart}
        onClick={() => pathHandler(_id, type)}
        onKeyDown={() => pathHandler(_id, type)}
        role="button"
        tabIndex={0}
      >
        {type === "file" ? (
          <img src={image} alt="library" onError={(e) => {e.target.src = altImage}} />
        ) : (
          <Folder style={{ width: "80%", height: "150px" }} />
        )}
        <div className={styles.bottom}>
          {sizeInBytes ?
            <div>
              <p>{sizeInBytes} bytes</p>
            </div> : null
          }
        </div>
      </div>
      <div className={styles.heading} ref={teamRef}>
        <span
          onClick={() => pathHandler(_id, type)}
          onKeyDown={() => pathHandler(_id, type)}
          role="button"
          tabIndex={0}
        >
          {staticTitle || _id}
        </span>
      </div>
      <p>{createdAt ? new Date(createdAt).toLocaleDateString(): ""}</p>

      {dropDown && _id === activeMenu && (
        <DropDownMenu
        id={_id}
        activeMenu={activeMenu}
        setActiveMenu={setActiveMenu}
        />
      )}
    </div>
  );
}
export default LibraryCard;
LibraryCard.propTypes = {
  title: PropTypes.string,
  roomId: PropTypes.string,
  _id: PropTypes.number,
  createdAt: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  dropdownItem: PropTypes.number,
  dropdown: PropTypes.func,
  listIndex: PropTypes.number,
  pathHandler: PropTypes.func,
  activeMenu: PropTypes.any,
  setActiveMenu: PropTypes.func,
  dropDown: PropTypes.bool,
  staticTitle: PropTypes.string,
  sizeInBytes: PropTypes.number
};
