import { GET_PROFILES } from "../actionTypes";

export const getProfilesInit = () => ({
    type: GET_PROFILES.GET_PROFILES_INIT
});

export const getProfilesSuccess = (payload) => ({
    type: GET_PROFILES.GET_PROFILES_SUCCESS,
    payload
});

export const getProfilesError = (error) => ({
    type: GET_PROFILES.GET_PROFILES_ERROR,
    error,
});
