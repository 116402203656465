import React from "react";
import PropTypes from "prop-types";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.331"
      height="18.331"
      viewBox="0 0 18.331 18.331"
      onClick={props.onClick}
    >
      <g data-name="Group 55" transform="translate(0 3.273)">
        <g data-name="Group 54">
          <path
            fill="#3d3d3d"
            d="M13.094 85.333H1.964A1.964 1.964 0 000 87.3v11.126a1.964 1.964 0 001.964 1.964h11.13a1.964 1.964 0 001.964-1.964V87.3a1.964 1.964 0 00-1.964-1.967z"
            data-name="Path 30"
            transform="translate(0 -85.333)"
          ></path>
        </g>
      </g>
      <g data-name="Group 57" transform="translate(2.619)">
        <g data-name="Group 56">
          <path
            fill="#3d3d3d"
            d="M82.015 0H70.231a1.964 1.964 0 00-1.964 1.949v.015h10.475a3.277 3.277 0 013.273 3.273v10.475h.015a1.964 1.964 0 001.949-1.964V1.964A1.964 1.964 0 0082.015 0z"
            data-name="Path 31"
            transform="translate(-68.267)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

Icon.propTypes = {
    onClick: PropTypes.func
};

export default Icon;
