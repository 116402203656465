import * as React from "react";

const SVGComponent = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 500 500"
    style={{
      enableBackground: "new 0 0 500 500",
      width: "25px",
      height: "25px"
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style type="text/css">
      {
        "\n\t.st0{fill:url(#SVGID_1_);}\n\t.st1{fill:url(#SVGID_2_);}\n\t.st2{fill:url(#SVGID_3_);}\n\t.st3{fill:url(#SVGID_4_);}\n\t.st4{fill:url(#SVGID_5_);}\n"
      }
    </style>
    <g>
      <g>
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1={149.5809}
          y1={141.1675}
          x2={354.8014}
          y2={141.1675}
        >
          <stop
            offset={0}
            style={{
              stopColor: "#FFFFFF",
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "#707070",
            }}
          />
        </linearGradient>
        <path
          className="st0"
          d="M335.4,275c12.1-17,19.4-37.7,19.4-60.1V111.1C354.8,53.9,308.2,7.3,251,7.3c-49.7,0-91.3,35.1-101.4,81.9 L335.4,275z"
        />
        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1={147.2007}
          y1={242.8788}
          x2={290.8584}
          y2={242.8788}
        >
          <stop
            offset={0}
            style={{
              stopColor: "#FFFFFF",
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "#707070",
            }}
          />
        </linearGradient>
        <path
          className="st1"
          d="M147.2,167.1v47.8c0,57.2,46.6,103.8,103.8,103.8c14.1,0,27.6-2.9,39.9-8L147.2,167.1z"
        />
      </g>
      <linearGradient
        id="SVGID_3_"
        gradientUnits="userSpaceOnUse"
        x1={-830.0458}
        y1={464.8367}
        x2={-232.1825}
        y2={464.8367}
        gradientTransform="matrix(0.5372 0.1216 0.1216 0.5372 479.8026 45.5866)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#FFFFFF",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#707070",
          }}
        />
      </linearGradient>
      <path
        className="st2"
        d="M441,420.7L441,420.7c-4.4,4.4-11.6,4.4-16,0L61,56.7c-4.4-4.4-4.4-11.6,0-16l0,0c4.4-4.4,11.6-4.4,16,0 l364,364C445.4,409.1,445.4,416.3,441,420.7z"
      />
      <g>
        <linearGradient
          id="SVGID_4_"
          gradientUnits="userSpaceOnUse"
          x1={361.1253}
          y1={234.2459}
          x2={424}
          y2={234.2459}
        >
          <stop
            offset={0}
            style={{
              stopColor: "#FFFFFF",
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "#707070",
            }}
          />
        </linearGradient>
        <path
          className="st3"
          d="M385.5,322.8c24-29.5,38.5-66.9,38.5-107.9V163c0-9.6-7.8-17.3-17.3-17.3c-9.6,0-17.3,7.7-17.3,17.3v51.9 c0,31.4-10.6,60.3-28.3,83.5L385.5,322.8z"
        />
        <linearGradient
          id="SVGID_5_"
          gradientUnits="userSpaceOnUse"
          x1={78.0005}
          y1={318.7002}
          x2={343.0099}
          y2={318.7002}
        >
          <stop
            offset={0}
            style={{
              stopColor: "#FFFFFF",
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "#707070",
            }}
          />
        </linearGradient>
        <path
          className="st4"
          d="M318.2,335.8c-19.9,11.1-42.8,17.5-67.2,17.5c-76.3,0-138.4-62.1-138.4-138.4V163c0-9.6-7.8-17.3-17.3-17.3 S78,153.4,78,163v51.9c0,89.4,68.5,162.4,155.7,171.2v88.3c0,9.5,7.8,17.3,17.3,17.3c9.5,0,17.3-7.8,17.3-17.3v-88.3 c27.2-2.8,52.6-11.8,74.7-25.6L318.2,335.8z"
        />
      </g>
    </g>
  </svg>
);
export default SVGComponent;