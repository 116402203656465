import axiosInstance from "../../../../../../utils/axiosInstance";

const fetchComments = async (id, limit, page) => {
    const url = `/api/v1/comment/getComments/video/${id}?limit=${limit}&page=${page}`;
    const { data } = await axiosInstance.get(url);
    return data.data.comments;

};

export default fetchComments;
