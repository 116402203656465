import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { pages } from "./pages";
import { loginSuccess } from "../redux/actions/actionCreators/authenticationActionCreators";
import Cookies from "js-cookie";
import { getUserProfiles } from "redux/actions/profileActions";

export default function RoutesCom () {
  const [authToken, setAuthToken] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const { isModuleAccessible, loading, status: profileStatus } = useSelector((state) => state.profilesReducer);
  const { user, status: authStatus } = useSelector(state => state.authReducer);
  let mounted = true;

//   const {
//     user: { _id: userId },
// } = useSelector(state => state.authReducer);


  const intendedURL = Cookies.get("intendedURL") || new URLSearchParams(location.search).get("intendedURL");

  useEffect(() => {
    const token = Cookies.get("authCode");
    if (token) {
      setAuthToken(token);
    } else {
      const intendedURL = Cookies.get("intendedURL") || new URLSearchParams(location.search).get("intendedURL");
      if (intendedURL) {
        const urlString = process.env.REACT_APP_URL.split(".");
        const  [_, ...lastTwoParts] = urlString;
        const remainder = `.${lastTwoParts.join(".")}`.split(":")[0];
        const domain = remainder;
        Cookies.set("intendedURL", intendedURL, { domain });
        // debugger;
        window.location.href = `${process.env.REACT_APP_MASTER_WIZR_LOGIN}?_rdr=${process.env.REACT_APP_URL}&intendedURL=${intendedURL}&shared=true`;
        return;
      } else {
        window.location.href = `${process.env.REACT_APP_MASTER_WIZR_LOGIN}?_rdr=${process.env.REACT_APP_URL}`;
        return;
      }
    }
  }, []);

  

  useEffect(() => {
    const handlePageShow = (event) => {
      if (mounted && event.persisted) {
        const token = Cookies.get("authCode");
        console.log('This page was restored from the bfcache.');
        if (!token) {
          const intendedURL = Cookies.get("intendedURL") || new URLSearchParams(location.search).get("intendedURL");
          if (intendedURL) {
            const urlString = process.env.REACT_APP_URL.split(".");
            const  [_, ...lastTwoParts] = urlString;
            const remainder = `.${lastTwoParts.join(".")}`.split(":")[0];
            const domain = remainder;
            Cookies.set("intendedURL", intendedURL, { domain });
            // debugger;
            window.location.href = `${process.env.REACT_APP_MASTER_WIZR_LOGIN}?_rdr=${process.env.REACT_APP_URL}&intendedURL=${intendedURL}&shared=true`;
            return;
          } else {
            window.location.href = `${process.env.REACT_APP_MASTER_WIZR_LOGIN}?_rdr=${process.env.REACT_APP_URL}`;
            return;
          }
        }
      } else {
        console.log('This page was loaded normally.');
        // Perform any actions you need when the page is loaded normally
      }
    };

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      mounted = false;
    };
  }, []); 

  useEffect(() => {
    if (authToken) {
      dispatch(loginSuccess(authToken));
    }
  }, [authToken, dispatch]);

  useEffect(() => {
    if (authStatus === "") {
      return;
    }
    if (user && user._id) {
      const  { _id: userId } = user;
      dispatch(getUserProfiles(userId));
    } else {
    }
  }, [user]);

  useEffect(() => {
    if (profileStatus === "") {
      return;
    }
    if (!loading) {
      if (isModuleAccessible) {
        // Navigate to the current location without any search parameters
        navigate(location.pathname);
      } else {
        window.location.href = `${process.env.REACT_APP_MASTER_WIZR_LOGIN}?_rdr=${process.env.REACT_APP_URL}`;
        return;
      }
    }
  }, [isModuleAccessible, loading]);


  /**
   * Recursively return child routes for a route in a list of routes
   * @param  {[pathnames]} arg1 An array of routes to loop through
   * @param  {prefix} arg2 A prefix to add onto the previous route path
   */
  const routeHandler = (pagenames, prefix = "") => {
    return pagenames.map(route => {
      if (!route.children) {
        return (
          <Route
            key={`${prefix}${route.path}`}
            path={`${prefix}${route.path}`}
            exact={route.exact}
            element={
              <route.layout>
                <route.element />
              </route.layout>
            }
          />
        );
      }
      return routeHandler(route.children, route.path);
    });
  };

  return <Routes>{routeHandler(pages)}</Routes>;
}

RoutesCom.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};
