import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import getStarted from "../../../../assets/images/getStarted.jpg";
import { Link } from "react-router-dom";
import SharedButton from "../SharedButton";
import TitleParagraphs from "../TitleParagraphs";

function GetStarted({ title, objectives, responsibilities, skills, hasVideo }) {
    return (
        <div className={styles.getStarted}>
            <h1>MW Interview - <span>{title}</span></h1>
            <p className={styles.location}>Norway, Oslo</p>
            {hasVideo &&
                <img src={getStarted} alt="" />
            }
            <p className={styles.job}>Job Description</p>
            <Link to="https://masterwizr.com/">https://masterwizr.com/</Link>
            <p className={styles.paragraph}>
                We’re seeking a quick learner with strong negotiating skills, and someone with a track record of success who can inspire the same in others. The role demands a leader with a sharp mind and the ability to coach, advise, motivate, or replace sales representatives to create and maintain a high-performance sales force.
            </p>
            <TitleParagraphs title="Objectives of this Role" paragraphs={objectives} />
            <TitleParagraphs title="Daily and Monthly Responsibilities" paragraphs={responsibilities} />
            <TitleParagraphs title="Skills and Qualifications" paragraphs={skills} />
            <p>Good Luck!</p>
            <p className={styles.team}>MW Recruitment team.</p>
            <SharedButton children="Get Started" />
        </div>
    );
}
export default GetStarted;
GetStarted.propTypes = {
    title: PropTypes.string,
    objectives: PropTypes.array,
    responsibilities: PropTypes.array,
    skills: PropTypes.array,
    hasVideo: PropTypes.bool,
};
