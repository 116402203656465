import {useState, useEffect} from "react";

import PropTypes from "prop-types";

import styles from "./styles.module.scss";
import LibraryCard from "../../atoms/LibraryCard";

const GridView = ({list, pathHandler, dropDown, showContextMenu}) => {
  const [activeMenu, setActiveMenu] = useState(false);

  const closeAnyMenu = () => {
    setActiveMenu(false);
  };

  useEffect(() => {
    document.body.addEventListener("click", closeAnyMenu);

    return () => {
        document.body.removeEventListener("click", closeAnyMenu);
    };
  }, []);


    return (
        <div className={styles.gridContainer}>

            {
                list.map((d, i) => {
                    return <LibraryCard
                        activeMenu={activeMenu}
                        setActiveMenu={setActiveMenu}
                        listIndex={i} pathHandler={pathHandler}
                        {...d} key={i}
                        dropDown={dropDown}
                        showContextMenu={showContextMenu}
                    />;
                })
            }
        </div>
    );
};

export default GridView;

GridView.defaultProps = {
    showContextMenu: true,
};

GridView.propTypes = {
    list: PropTypes.array,
    pathHandler: PropTypes.func,
    dropDown: PropTypes.bool,
    recordingsList: PropTypes.array,
    loadingHostRecordings: PropTypes.bool,
    showContextMenu: PropTypes.bool,
};
