
import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "../Modal";
import styles from "./styles.module.scss";
import CopyIcon from "../../../atoms/Icons/Copy";
import { TagsInput } from "react-tag-input-component";
import "./custom-tags.scss";
import { useSelector } from "react-redux";
import { warningToast } from "components/ui/atoms/Toasts/toasts";

function SaveRecordingPopup({ click, close, urlVideoFile, time, changeText, videoName }) {

  const [copyText, setCopyText] = useState("copy");
  const [selected, setSelected] = useState([]);
  const [uniqueName, setUniqueNameError] = useState(null);
  function copy() {
    navigator.clipboard.writeText(urlVideoFile);
    setCopyText("copied");
  }
  const selectVideosData = (state, key) => state[key].data;

  const allVideos = useSelector(state => state?.video?.videos?.data);
  const allFavouriteVideos = useSelector(state => state.video.archivedVideos.data);
  const allArchivedVideos = useSelector(state => state.video.favouriteVideos.data.videos);

  const extractTitles = videos => videos.map(v => v.title);

  const allTitles = [...extractTitles(allVideos), ...extractTitles(allFavouriteVideos), ...extractTitles(allArchivedVideos)];

  console.log(allTitles, "allTitles");

  return (
    <Modal>
      <div className={styles.containerSavePopup}>
        <div className={styles.header}>
          <span>Save Recording</span>
          <button onClick={close}>X</button>
        </div>
        <div className={styles.content}>
          <span className={styles.time}>0:{time.minute}:{time.second}</span>
          <div className={styles.main}>
            <label htmlFor="firstName">
              File name
              <input type="text" onChange={e => changeText(e.target.value)} value={videoName}/>
              {!videoName ? <p className={styles.nameError}>Video name is required</p>: null}
              {uniqueName ? <p className={styles.nameError}>{uniqueName}</p> : null}
            </label>

            {/* <label htmlFor="firstName">
              Auto URL
              <input type="text" value={urlVideoFile} readOnly />
              <div onKeyDown={() =>{}} role="button" tabIndex="0"  title={copyText} onClick={() => copy()}>
                <CopyIcon />
              </div>
            </label> */}
          </div>
          {/* <div className={styles.tagsContainer}>
            <TagsInput
              value={selected}
              onChange={setSelected}
              name="tags"
              placeHolder="Add new tags..."
            />
          </div> */}
          <button className={!!videoName ? `${styles.button}` : `${styles.button} ${styles.disableBtn}`} disabled={!videoName} onClick={() => {
            if(allTitles.includes(videoName)){
              setUniqueNameError("Video name must be unique");
            } else {
              click();
            }
            }}>Save Recording</button>
        </div>
      </div>
    </Modal>
  );
}

SaveRecordingPopup.propTypes = {
  click: PropTypes.func,
  close: PropTypes.func,
  urlVideoFile: PropTypes.string,
  time: PropTypes.object,
  changeText:PropTypes.string,
  videoName: PropTypes.string,
};

export default SaveRecordingPopup;
