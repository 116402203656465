import React from "react";
import styles from "./style.module.scss";

export default function index({ name, subtitle, date }) {
  return (
    <div className={styles.container}>
      <h1>
        {name} - <span>{subtitle}</span>
      </h1>
      <h5>
        WiZR Connect - <span>Submitted {date}</span>
      </h5>
    </div>
  );
}
