import bgImage2 from "assets/images/right-menu-2.webp";
import bgImage3 from "assets/images/right-menu-3.webp";
import imagemenu from "assets/images/homemenu1.jpg";
import bgImage from "assets/images/right-menu-2.webp";

export const homeMenu = [
  {
    index: 0,
    bgImage:imagemenu,
    Heading: "Master WiZR",
    Paragraph:
      "Your WiZR Connect dashboard ",
    subHeading: "Practical tips",
    subParagraph:
      "Follow this, and you will enjoy your dashboard more:",
    items: [
      {
        item: 1,
        description:
          "Keep deleting files you will not need over time. Only save the most important files. ",
      },
      {
        item: 2,
        description:
          "Star your favorites for easy finding and reuse. Enjoy the magic of reuse.",
      },
      {
        item: 3,
        description:
          "Create Video Debriefs before and after your calls.",
      },
    ],
  },
  {
    index: 1,
    Heading: "Connect with video",
    bgImage: bgImage2,
    extraClass: "bigHeading",
    Paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
    items: [
      {
        title: "Get started without login",
        extraClassItem: "lineStyled",
        description:
          "Files of a large size (up to 10 GB) can be uploaded into our online music file processing application. There is no necessity to archive your large mp3 files to slow down or to speed up music.",
      },
    ],
  },
  {
    index: 2,
    bgImage: bgImage3,
    Heading: "Shared with me",
    Paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
    subHeading: "Practical tips",
    subParagraph:
      "Get the right people together. At the right time. In 2 steps.",
    items: [
      {
        item: 1,
        description: "Too many changes and updates can look unorganized.",
      },
      {
        item: 2,
        description:
          "Outsmart back-and-forth emails by giving a clear visual snapshot of your availability. It’s quick and collaborative. People can get back to work in minutes.",
      },
      {
        item: 3,
        description:
          "Your calendar - and your attendees’ calendars - are personal. We get it., events, meetings,",
      },
    ],
  },
];


export const pollsMenu = [
  {
    index: 0,
    Heading: "Polls",
    bgImage: bgImage,
    extraClass: "bigHeading",
    Paragraph:
      "Wizr Connect polls from your hosted meeting are shown here",
    items: [
      {
        title: "Get started without login",
        extraClassItem: "lineStyled",
        description:
          "Files of a large size (up to 10 GB) can be uploaded into our online music file processing application. There is no necessity to archive your large mp3 files to slow down or to speed up music.",
      },
    ],
  },
];

export const meetingMenu = [
  {
    index: 0,
    Heading: "Polls",
    bgImage: bgImage,
    extraClass: "bigHeading",
    Paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
    items: [
      {
        title: "Get started without login",
        extraClassItem: "lineStyled",
        description:
          "Files of a large size (up to 10 GB) can be uploaded into our online music file processing application. There is no necessity to archive your large mp3 files to slow down or to speed up music.",
      },
    ],
  },
];