import React from "react";

export default function stop({ width, height, color }) {
    return (
        <svg
            width={width || "25"}
            height={height || "24"}
            viewBox="0 0 300 300"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <circle fill={color || "#c1c1c1"} cx="150" cy="150" r="147.5" />
                <path fill={color || "#EC7600"} d="M213.4,204.5c0,4.9-4,8.8-8.8,8.8H95.5c-4.9,0-8.8-4-8.8-8.8V95.5c0-4.9,4-8.8,8.8-8.8h109.1
		c4.9,0,8.8,4,8.8,8.8L213.4,204.5L213.4,204.5z"/>
            </g>
        </svg>
    );
}
