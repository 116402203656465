/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-trailing-spaces */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import { getPoll } from "redux/actions/pollsActions";
import BackButton from "assets/icons/CloseArrow";
import NothingToDisplay from "components/ui/atoms/NothingToDisplay";
import Loader from "components/ui/atoms/Loader";
import PollSlider from "components/ui/atoms/pollSlider";
import RightMenu from "components/ui/atoms/RightMenu";
import { meetingMenu } from "constants/homeMenu";


const PollsDetails = () => {
  const { pollId } = useParams();

  const navigate = useNavigate();
  const { poll, loading } = useSelector(state => state.polls);
  const {
    user: { _id },
  } = useSelector(s => s.authReducer);
  const dispatch = useDispatch();

  const [displayedQuestionStats, setDisplayedQuestionStats] = useState(null);

  useEffect(() => {
    dispatch(getPoll(_id, pollId));
  }, [dispatch, _id, pollId]);

  const ChoiceAndVote = ({ option, vote }) => (
    <div className={styles.choiceVotes}>
      <div>{option}</div>
      <div>
        <div className={styles.vote}>{vote}</div>
      </div>
    </div>
  );

  const TextAnswer = ({ text, username }) => (
    <div className={styles.textAnswer}>
      <div className={styles.textAnswerText}>{text}</div>
      <div className={styles.textAnswerUsername}>
        {username ? `~ ${username}` :   "~ Anonymous"}
      </div>
    </div>
  );

  const SlideRangeAnswer = ({ value, votes, average }) => {
    const validValue = isNaN(value) ? 0 : value;
    const validVotes = isNaN(votes) ? 0 : votes;
    const validAverage = isNaN(average) ? 0 : average;
  
    return (
      <div className={styles.sliderMainContainer}>
        <div className={styles.slideRangeAnswer}>
          <div>
            <PollSlider value={validValue} />
          </div>
          <div>{`(${validAverage})`}{" "}{validValue}%</div>
        </div>
        <div className={styles.voteCount}>{validVotes ? `${validVotes} votes` : ""}</div>
      </div>
    );
  };
  

  // const MeetingInfo = () => {
  //   const { poll, loading } = useSelector(s => s.polls);
  //   return (
  //     <div className={styles.infoMeeting}>
  //       <div className={styles.header}>
  //         <h2>{!loading && poll ? poll.roomId : "Meeting name"}</h2>
  //       </div>

  //       <div className={styles.content}>
  //         <div className={styles.centered}>
  //           <p>
  //             These polls were created on{" "}
  //             {!loading && poll && new Date(poll.createdAt).toLocaleDateString()}
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const VoterStats = ({ question, options }) => (
    <div className={styles.voteStatsContainer}>
      <div className={styles.voteStatsNav}>
        <BackButton onClick={() => setDisplayedQuestionStats(null)} />
        <span>Go back</span>
      </div>
      <p className={styles.voteQuestion}>{question}</p>
      {options &&
        options.map(({ option, votes, userNames }, index) => (
          <div key={index}>
            <p className={styles.questionOption}><span className={styles.optionNumber}>
              {index + 1}{". "}</span>{`${option} ~ ${votes} votes `}
            </p>
            <ul>
              {userNames &&
                userNames.map(({ userName }) => (
                  <li key={userName}>{userName ?? "Anonymous"}</li>
                ))}
            </ul>
          </div>
        ))}
    </div>
  );

  return (
    <>
        <RightMenu openedMenu bgImage="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1692114544/wizr%20x/Group_40436_lhfuvp.png" list={meetingMenu} buttons/>
      <div className={styles.topNav}>
        <BackButton onClick={() => navigate(-1)} />
        <span>Back to polls</span>
      </div>
      <div className={styles.grid}>
        <div className={styles.polls}>
          <h1 className={styles.headingText}>Poll Results</h1>
          <p className={styles.dateHolder}>These polls were created on{" "}
              {!loading && poll && new Date(poll.createdAt).toLocaleDateString()}</p>
          <div className={styles.pollsWrapper}>
            <div className={styles.content}>
              {loading ? (
                <Loader />
              ) : displayedQuestionStats ? (
                <VoterStats
                  question={displayedQuestionStats.question}
                  options={displayedQuestionStats.options}
                />
              ) : poll ? (
                // Handle the display of poll results based on the type
                <>
                  <ol className={styles.orderedPollQuestions}>
                    {poll.results?.map(
                      ({
                        type,
                        question,
                        pollTextAnswers,
                        options,
                        pollSliderAnswers,
                      }) => {
                        if (type === "text") {
                          return (
                            <li key={question}>
                              {question}
                              {pollTextAnswers.map(({ answer, userName }) => (
                                <TextAnswer
                                  key={answer}
                                  text={answer}
                                  username={userName}
                                />
                              ))}
                            </li>
                          );
                        } else if (type === "single") {
                          return (
                            <li key={question}>
                              {question}
                              {options.map(({ option, votes }) => (
                                <ChoiceAndVote
                                  key={option}
                                  option={option}
                                  vote={votes}
                                />
                              ))}
                              <div className={styles.voteData}>
                                <span
                                  className={styles.voterStats}
                                  onClick={() =>
                                    setDisplayedQuestionStats({
                                      question,
                                      options,
                                    })
                                  }
                                >
                                  Voter stats
                                </span>
                                <span className={styles.vote}>
                                  {options.reduce(
                                    (sum, item) => sum + item.votes,
                                    0
                                  )} votes
                                </span>
                              </div>
                            </li>
                          );
                        } else if (type === "multi") {
                          return (
                            <li key={question}>
                              {question}
                              {options.map(({ option, votes }) => (
                                <ChoiceAndVote
                                  key={option}
                                  option={option}
                                  vote={votes}
                                />
                              ))}
                              <span
                                className={styles.voterStats}
                                onClick={() =>
                                  setDisplayedQuestionStats({
                                    question,
                                    options,
                                  })
                                }
                              >
                                Voter Stats
                              </span>
                            </li>
                          );
                        } else if (type === "slider") {
                          const total = pollSliderAnswers.reduce(
                            (sum, { answer }) => sum + answer,
                            0
                          );
                          const votes = pollSliderAnswers.length;
                          const average = total / votes;
                          const percentage = (average * 10).toFixed(2); // Assuming max slider value is 10

                          return (
                            <li key={question}>
                              {question}
                              <SlideRangeAnswer
                                average={average}
                                value={percentage}
                                votes={votes}
                              />
                            </li>
                          );
                        }
                        return null; // Default return if none of the conditions are met
                      }
                    )}
                  </ol>
                </>
              ) : (
                <NothingToDisplay />
              )}
            </div>
          </div>
        </div>
        {/* <MeetingInfo /> */}
      </div>
    </>
  );
};

export default PollsDetails;
