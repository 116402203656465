
export const Question =
{
    id: 1,
    name: "Ana Smith",
    question: "Question 3",
    firstdescription: "Share stories about sources of motivation. ",
    seconddescription: "Share examples from prior employers and personal stories that have moved you."
};

