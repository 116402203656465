import React, {useState} from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import {ReactComponent as Triangle} from "../../../../../../../assets/images/triangle.svg";

const TableHead = ({ handler }) => {

    const [active, setActive] = useState("");

    const onSort = (type) => {
        setActive(type);
        handler(type);
    };

    return (
        <tr className={styles.row}>
            <td></td>
            <td>
                <span
                    className={`${active === "name" && styles.active}`}
                    onClick={() => onSort("name")} onKeyDown={() => onSort("name")} role="button" tabIndex={0}>
                    Name
                    <Triangle/>
                </span>
            </td>
        </tr>
    );
};

export default TableHead;
TableHead.propTypes = {
    handler: PropTypes.func
};
