/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as Globe } from "assets/images/globe-icon.svg";
import styles from "./style.module.scss";

function TextLink({ title, paragraph, link, icon, onClick }) {
    return (

        <div className={styles.inviteCreate}>
            <div className={styles.iconText}>
                {icon &&
                    <div className={styles.globeStyle}>
                        <Globe />
                    </div>
                }
                <div>
                    <h5 className={styles.whiteParagraph}>{title}</h5>
                    <p>{paragraph}</p>
                </div>
            </div>
            <div>
                <p className={styles.createLink} onClick={onClick}>{link}</p>
            </div>
        </div>
    );
}
export default TextLink;
TextLink.propTypes = {
    title: PropTypes.string,
    paragraph: PropTypes.string,
    link: PropTypes.string,
    icon: PropTypes.bool,
    onClick: PropTypes.func,
};
