import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./SearchInput.module.scss";
import SearchIcon from "assets/icons/SearchIcon";
import { ReactComponent as Close } from "assets/icons/close.svg";

const SearchInput = ({ onChange, value }) => {
  const [inputValue, setInputValue] = useState(value || '');
  const [showClearButton, setShowClearButton] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    if (onChange) {
      onChange(e);
    }
    setShowClearButton(Boolean(newValue));
  };

  const handleDeleteClick = () => {
    if (onChange) {
      onChange({ target: { value: '' } });
    }
    setShowClearButton(false);
  };

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowClearButton(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);


  return (
    <div className={styles.searchBox}>
      <input
        type="text"
        className={`${styles.searchInput} ${showClearButton ? styles.showButton : ''}`}
        placeholder="search"
        onChange={handleInputChange}
        value={inputValue}
        ref={inputRef}
      />
      <button className={styles.searchButton}>
        <SearchIcon extraClass={styles.magnifierIcon} />
      </button>
      {showClearButton && (
        <button className={styles.clearButton} onClick={handleDeleteClick}>
          <Close />
        </button>
      )}
    </div>
  );
};

SearchInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string
};

export default SearchInput;
