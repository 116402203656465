import RightMenu from "components/ui/atoms/RightMenu";
import SharedBanner from "components/ui/atoms/SharedBanner";
import Container, {ITEMS_KEYS} from "components/ui/molecules/Container";
import { homeMenu } from "constants/homeMenu";
import { personalLibraryData } from "constants/personalLibraryData";
import styles from "../MeetingRepository/styles.module.scss";
import image from "assets/images/archivebanner.webp";
function PersonalLibrary() {
  return (
    <>
        <SharedBanner
        title="Archive"
        extraClass="archivebanner"
        description="All your archived videos will be saved here."
      />
      <div className={styles.bottom}>
       <Container
        image
        list={personalLibraryData}
        viewType={ITEMS_KEYS.MY_ARCHIVES}
      />
      </div>
      <RightMenu bgImage={image} list={homeMenu} buttons />
    </>
  );
}

export default PersonalLibrary;
