import slide1 from "assets/images/slide-1.webp";
import slide2 from "assets/images/slide-2.webp";
import slide3 from "assets/images/slide-3.webp";
import streamingview4 from "assets/images/streamingview4.webp";
import streamingview3 from "assets/images/streamingview3.webp";
import streamingview2 from "assets/images/streamingview2.webp";
import streamingview6 from "assets/images/streamingview6.webp";
import streamingview5 from "assets/images/streamingview5.webp";

export const guideData = [
    {
        index:1,
        image:slide1
    },
    {
        index:2,
        image:slide2
    },
    {
        index:3,
        image:slide3
    },
    {
        index:4,
        image:streamingview4
    },
    {
        index:5,
        image:streamingview3
    },
    {
        index:6,
        image:streamingview2
    },
    {
        index:7,
        image:streamingview6
    },
    {
        index:8,
        image:streamingview5
    },
    {
        index:9,
        image:streamingview3
    },
    {
        index:10,
        image:streamingview2
    },
    {
        index:11,
        image:streamingview6
    },
    {
        index:12,
        image:streamingview5
    }
];