import React from "react";
import PropTypes from "prop-types";
import TopNavigation from "components/ui/atoms/TopNavigation/index";

function InterviewPanel({ children }) {
  return (
    <>
        <TopNavigation  interview  title="Sales Manager"/>
        { children }
    </>
  );
}
export default InterviewPanel;
InterviewPanel.propTypes = {
  children: PropTypes.node
};