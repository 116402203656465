import {
    VIDEO,
    GET_VIDEOS,
    UPDATE_VIDEO,
    DELETE_VIDEO,
    DOWNLOAD_VIDEO,
    FAVORITE_VIDEO,
    ARCHIVE_VIDEO,
    GET_ARCHIVED_VIDEOS,
    GET_FAVORITE_VIDEOS,
    GET_SHARED_LINK,
    DUPLICATE_VIDEO,
    GET_PAGINATION_DATA,
    VIDEOUUID,
    GET_SHARED_WITH_ME_VIDEOS
} from "../actionTypes";

export const getVideosInit = () => {
  return {
    type: GET_VIDEOS.INIT,
  };
};

export const getVideosSuccess = videos => {
  return {
    type: GET_VIDEOS.SUCCESS,
    payload: videos,
  };
};

export const getVideosError = error => {
  return {
    type: GET_VIDEOS.ERROR,
    payload: error,
  };
};

export const videoInit = () => {
  return {
    type: VIDEO.INIT,
  };
};

export const videoSuccess = payload => {
  return {
    type: VIDEO.SUCCESS,
    payload,
  };
};

export const videoError = error => {
  return {
    type: VIDEO.ERROR,
    payload: error,
  };
};

export const videoUUIDInit = () => {
  return {
    type: VIDEOUUID.INIT,
  };
};

export const videoUUIDSuccess = payload => {
  return {
    type: VIDEOUUID.SUCCESS,
    payload,
  };
};

export const videoUUIDError = error => {
  return {
    type: VIDEOUUID.ERROR,
    payload: error,
  };
};

export const updateVideoInit = () => ({
  type: UPDATE_VIDEO.INIT,
});

export const updateVideoSuccess = payload => ({
  type: UPDATE_VIDEO.SUCCESS,
  payload,
});

export const updateVideoError = error => ({
  type: UPDATE_VIDEO.ERROR,
  error,
});

export const favouriteVideoInit = () => ({
  type: FAVORITE_VIDEO.INIT,
});

export const favouriteVideoSuccess = payload => ({
  type: FAVORITE_VIDEO.SUCCESS,
  payload,
});

export const favouriteVideoError = error => ({
  type: FAVORITE_VIDEO.ERROR,
  error,
});

export const archiveVideoInit = () => ({
  type: ARCHIVE_VIDEO.INIT,
});

export const archiveVideoSuccess = payload => ({
  type: ARCHIVE_VIDEO.SUCCESS,
  payload,
});

export const archiveVideoError = error => ({
  type: ARCHIVE_VIDEO.ERROR,
  error,
});

export const getFavouriteVideosInit = () => ({
  type: GET_FAVORITE_VIDEOS.INIT,
});

export const getFavouriteVideosSuccess = payload => ({
  type: GET_FAVORITE_VIDEOS.SUCCESS,
  payload,
});

export const getFavouriteVideosError = error => ({
  type: GET_FAVORITE_VIDEOS.ERROR,
  error,
});

export const getArchivedVideosInit = () => ({
  type: GET_ARCHIVED_VIDEOS.INIT,
});

export const getArchivedVideosSuccess = payload => ({
  type: GET_ARCHIVED_VIDEOS.SUCCESS,
  payload,
});

export const getArchivedVideosError = error => ({
  type: GET_ARCHIVED_VIDEOS.ERROR,
  error,
});

export const deleteVideoInit = () => ({
  type: DELETE_VIDEO.INIT,
});

export const deleteVideoSuccess = payload => ({
  type: DELETE_VIDEO.SUCCESS,
  payload,
});

export const deleteVideoError = error => ({
  type: DELETE_VIDEO.ERROR,
  error,
});

export const downloadVideoInit = () => ({
  type: DOWNLOAD_VIDEO.INIT,
});

export const downloadVideoSuccess = payload => ({
  type: DOWNLOAD_VIDEO.SUCCESS,
  payload,
});

export const downloadVideoError = error => ({
  type: DOWNLOAD_VIDEO.ERROR,
  error,
});

export const getSharedLinkInit = () => ({
  type: GET_SHARED_LINK.INIT,
});

export const getSharedLinkSuccess = payload => ({
  type: GET_SHARED_LINK.SUCCESS,
  payload,
});

export const getSharedLinkrror = error => ({
  type: GET_SHARED_LINK.ERROR,
  error,
});

export const duplicateVideoInit = () => ({
  type: DUPLICATE_VIDEO.INIT,
});

export const duplicateVideoSuccess = (payload, duplicateLocation) => ({
  type: DUPLICATE_VIDEO.SUCCESS,
  payload,
  duplicateLocation
});

export const duplicateVideoError = error => ({
  type: DUPLICATE_VIDEO.ERROR,
  error,
});

export const getPaginationData = payload => ({
    type: GET_PAGINATION_DATA,
    payload
});

export const getSharedWithMeVideosInit = () => ({
  type: GET_SHARED_WITH_ME_VIDEOS.INIT,
});

export const getSharedWithMeVideosSuccess = payload => ({
  type: GET_SHARED_WITH_ME_VIDEOS.SUCCESS,
  payload,
});

export const getSharedWithMeVideosError = error => ({
  type: GET_SHARED_WITH_ME_VIDEOS.ERROR,
  error,
});

// actions.js
export const setSearchText = (payload) => ({
  type: "SET_SEARCH_TEXT",
  payload
});
