import React from "react";
import AllAplicants from "components/ui/molecules/AllAplicants";
import SharedBanner from "components/ui/atoms/SharedBanner";
import Container from "../../molecules/InterviewsContainer";
import {personalLibraryData} from "../../../../constants/personalLibraryData";

export default function Aplicants() {
  return (
    <div>
      <SharedBanner
        title="Interviews"
        description="You will find all your video recording question from interviwers."
        extraClass1="interview"
      />
      <AllAplicants />
      <Container title="Interviews" list={personalLibraryData}/>
    </div>
  );
}
