import ProtectedPanel from "components/hocs/ProtectedPanel";
import Guides from "components/ui/organisms/Guides";
import Home from "components/ui/organisms/Home/index";
import PersonalLibrary from "components/ui/organisms/PersonalLibrary";
import ArchivesLibrary from "components/ui/organisms/MyArchives";
import FavouritesLibrary from "components/ui/organisms/MyFavourites";
import SharedWithMe from "components/ui/organisms/SharedWithMe.jsx/Index";
import ScheduleMeeting from "components/ui/organisms/ScheduleMeeting";
import MeetingRepository from "../components/ui/organisms/MeetingRepository";
import VideoRecords from "components/ui/organisms/VideoRecords";
import RenamePopup from "components/ui/organisms/RenamePopup";
import DeletePopup from "components/ui/organisms/DeletePopup";
import ArchivePopup from "components/ui/organisms/ArchivePopup";
import DownloadPopup from "components/ui/organisms/DownloadPopup";
import InvitePopup from "components/ui/organisms/InvitePopup";
import SharePopup from "components/ui/organisms/SharePopup";
import AllTeams from "components/ui/organisms/Teams";
import VideoPlayer from "components/ui/organisms/VideoPlayer";
import Aplicants from "components/ui/organisms/Aplicants";
import Dashboard from "components/ui/organisms/Dashboard";
import Applicant from "components/ui/organisms/ApplicantPage";
import CompanyInfo from "components/ui/organisms/CompanyInfo";
import DonePopup from "components/ui/organisms/DonePopup";
import Team from "components/ui/organisms/Team";
import InterviewSetup2 from "components/ui/molecules/InterviewSetup2";
import InterviewSetup3 from "components/ui/molecules/InterviewSetup3";
import SubTeams from "components/ui/organisms/SubTeams";
import GetStarted from "components/ui/organisms/GetStarted";
import GetStartedWithoutVideo from "components/ui/organisms/GetStartedWithoutVideo";
import SetUpInformation from "components/ui/molecules/SetUpInformation";
import InterviewPanel from "components/hocs/InterviewPanel";
import MyVideoDashboard from "components/ui/organisms/MyVideoDashboard";
import InterviewSteps from "components/ui/molecules/InterviewSteps";
import Answers from "components/ui/organisms/Answers";
import PublicPanel from "components/hocs/PublicPanel";
import PageNotFound from "components/ui/atoms/PageNotFound";
import SettingsPage from "components/ui/organisms/SettingsPage";
import MeetingChats from "components/ui/organisms/MeetingFiles/MeetingChats";
import MeetingNotes from "components/ui/organisms/MeetingFiles/MeetingNotes";
import MeetingFiles from "components/ui/organisms/MeetingFiles/MeetingFiles";
import MeetingTranscripts from "components/ui/organisms/MeetingFiles/MeetingTranscripts";
import IsolatedVideoPlayer from "components/ui/organisms/IsolatedVideoPlayer";
import MeetingRecordingList from "components/ui/organisms/MeetingList/RecordingsList/Index";
import Polls from "components/ui/organisms/Polls";
import PollsDetails from "components/ui/organisms/PollDetails";
import SharedVideo from "components/ui/molecules/SharedVideo";
import SubMeetings from "components/ui/organisms/MeetingList/SubMeetings";
import { BackgroundImageProvider } from "components/hocs/BackgroundImage/BackgroundImageProvider";
import MeetingDataList from "components/ui/organisms/MeetingList/DataList";
import SessionsList from "components/ui/organisms/MeetingList/SessionsList";

export const pages = [
  {
    children: [
      {
        path: "/",
        exact: true,
        layout: ProtectedPanel,
        element: Home,
      },
      {
        path: "/schedule-meeting",
        exact: true,
        layout: (props) => (
          <BackgroundImageProvider backgroundImage="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706825622/Templates/Group_40443_wjfibo.png">
            <ProtectedPanel {...props} />
          </BackgroundImageProvider>
        ),
        element: ScheduleMeeting,
      },
      {
        path: "/favourites",
        exact: true,
        layout: (props) => (
          <BackgroundImageProvider backgroundImage="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706825621/Templates/Group_40441_z3vgg1.png">
            <ProtectedPanel {...props} />
          </BackgroundImageProvider>
        ),
        element: FavouritesLibrary,
      },
      {
        path: "/archives",
        exact: true,
        layout: (props) => (
          <BackgroundImageProvider backgroundImage="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706826916/Templates/Group_40442_izwpce.png">
            <ProtectedPanel {...props} />
          </BackgroundImageProvider>
        ),
        element: ArchivesLibrary,
      },
      {
        path: "/shared-with-me",
        exact: true,
        layout: (props) => (
          <BackgroundImageProvider backgroundImage="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706827299/Templates/Group_40444_frhpoz.png">
            <ProtectedPanel {...props} />
          </BackgroundImageProvider>
        ),
        element: SharedWithMe,
      },
      {
        path: "/personal-library",
        exact: true,
        layout: (props) => (
          <BackgroundImageProvider backgroundImage="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706824984/Templates/Group_40440_sxyqwb.png">
            <ProtectedPanel {...props} />
          </BackgroundImageProvider>
        ),
        element: PersonalLibrary,
      },
      {
        path: "/share/video/:id",
        exact: true,
        layout: PublicPanel,
        element: SharedVideo,
      },
      {
        path: "/meeting-repository",
        exact: true,
        layout: (props) => (
          <BackgroundImageProvider backgroundImage="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706827038/Templates/Group_40446_sernqi.png">
            <ProtectedPanel {...props} />
          </BackgroundImageProvider>
        ),
        element: MeetingRepository,
      },
      {
        path: "/meeting-repository/sub-meetings/:meetingId",
        exact: true,
        layout: ProtectedPanel,
        element: SubMeetings,
      },
      {
        path: "/meeting-repository/hostMeetings/:id/:activeRoomId",
        exact: true,
        layout: ProtectedPanel,
        element: MeetingFiles,
      },
      {
        path: "/meeting-repository/hostMeetings/recordings",
        exact: true,
        layout: ProtectedPanel,
        element: MeetingRecordingList, // change this
      },
      {
        path: "/meeting-repository/hostMeetings/data/:id",
        exact: true,
        layout: ProtectedPanel,
        element: MeetingDataList,
      },
      {
        path: "/meeting-repository/hostMeetings/sessions/:id",
        exact: true,
        layout: ProtectedPanel,
        element: SessionsList,
      },
      {
        path: "/meeting-repository/hostMeetings/chats/:id",
        exact: true,
        layout: ProtectedPanel,
        element: MeetingChats,
      },
      {
        path: "/meeting-repository/hostMeetings/notes/:id",
        exact: true,
        layout: ProtectedPanel,
        element: MeetingNotes,
      },
      {
        path: "/meeting-repository/hostMeetings/transcripts/:id",
        exact: true,
        layout: ProtectedPanel,
        element: MeetingTranscripts,
      },
      {
        path: "/meeting-repository/hostMeetings/files/:id",
        exact: true,
        layout: ProtectedPanel,
        element: MeetingFiles,
      },
      {
        path: "/meeting-repository/hostMeetings/video-player/:id",
        exact: true,
        layout: ProtectedPanel,
        element: IsolatedVideoPlayer,
      },
      {
        path: "/settings/*",
        exact: true,
        layout: ProtectedPanel,
        element: SettingsPage,
      },
      {
        path: "/video-records",
        exact: true,
        layout: ProtectedPanel,
        element: VideoRecords,
      },
      {
        path: "/guides",
        exact: true,
        layout: ProtectedPanel,
        element: Guides,
      },
      {
        path: "/rename-popup",
        exact: true,
        layout: ProtectedPanel,
        element: RenamePopup,
      },
      {
        path: "/delete-popup",
        exact: true,
        layout: ProtectedPanel,
        element: DeletePopup,
      },
      {
        path: "/archive-popup",
        exact: true,
        layout: ProtectedPanel,
        element: ArchivePopup,
      },
      {
        path: "/download-popup",
        exact: true,
        layout: ProtectedPanel,
        element: DownloadPopup,
      },
      {
        path: "/invite-popup",
        exact: true,
        layout: ProtectedPanel,
        element: InvitePopup,
      },
      {
        path: "/share-popup",
        exact: true,
        layout: ProtectedPanel,
        element: SharePopup,
      },
      {
        path: "/all-teams",
        exact: true,
        layout: (props) => (
          <BackgroundImageProvider backgroundImage="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706826910/Templates/Group_40440_dlgutw.png">
            <ProtectedPanel {...props} />
          </BackgroundImageProvider>
        ),
        element: AllTeams,
      },
      {
        path: "/all-teams/:teamid",
        exact: true,
        layout: ProtectedPanel,
        element: SubTeams,
      },
      {
        path: "/all-teams/:teamid/:team",
        exact: true,
        layout: ProtectedPanel,
        element: Team,
      },
      {
        path: "/video-player/:id", //since react-router v6 is not supporting optional params I used same path with additional id
        exact: true,
        layout: ProtectedPanel,
        element: VideoPlayer,
      },
      {
        path: "/interview-setup-2",
        exact: true,
        layout: ProtectedPanel,
        element: InterviewSetup2,
      },
      {
        path: "/interview-setup-3",
        exact: true,
        layout: ProtectedPanel,
        element: InterviewSetup3,
      },
      {
        path: "/company-info",
        exact: true,
        layout: InterviewPanel,
        element: CompanyInfo,
      },
      {
        path: "/done-popup",
        exact: true,
        layout: InterviewPanel,
        element: DonePopup,
      },
      {
        path: "/applicants",
        exact: true,
        layout: ProtectedPanel,
        element: Aplicants,
      },
      {
        path: "/dashboard",
        exact: true,
        layout: ProtectedPanel,
        element: Dashboard,
      },
      {
        path: "/applicant",
        exact: true,
        layout: ProtectedPanel,
        element: Applicant,
      },
      {
        path: "/get-started",
        exact: true,
        layout: InterviewPanel,
        element: GetStarted,
      },
      {
        path: "/get-started-without-video",
        exact: true,
        layout: InterviewPanel,
        element: GetStartedWithoutVideo,
      },
      {
        path: "/set-your-information",
        layout: InterviewPanel,
        element: SetUpInformation,
      },
      {
        path: "/my-video",
        layout: InterviewPanel,
        element: MyVideoDashboard,
      },
      {
        path: "/interview-steps",
        layout: ProtectedPanel,
        element: InterviewSteps,
      },
      {
        path: "/answers",
        layout: ProtectedPanel,
        element: Answers,
      },
      {
        path: "/polls",
        exact: true,
        layout: (props) => (
          <BackgroundImageProvider backgroundImage="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706827146/Templates/Group_40447_brngrp.png">
            <ProtectedPanel {...props} />
          </BackgroundImageProvider>
        ),
        element: Polls,
      },
      {
        path: "/polls/session/:id",
        exact: true,
        layout: ProtectedPanel,
        element: Polls,
      },
      {
        path: "/polls/:pollId",
        exact: true,
        layout: ProtectedPanel,
        element: PollsDetails,
      },
      {
        path: "*",
        exact: true,
        layout: PublicPanel,
        element: PageNotFound,
      },
    ],
  },
];
