import React from "react";
import VideoPlayerContainer from "components/ui/molecules/IsolatedVideoContainer/Container";
import VideoContainer from "../IsolatedVideoContainer";

function IsolatedVideoPlayer () {
  const style = {
    width: "100%",
  };
  return (
    <>
      <VideoContainer>
        <div style={style}>
          <VideoPlayerContainer comments={false} speedContainer={true}/>
        </div>
      </VideoContainer>
    </>
  );
}

export default IsolatedVideoPlayer;
