import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoutes from "./index";

export default function RouteContainer() {
  return (
    <Routes>
      <Route
        index
        path="/*"
        element={<PrivateRoutes />}
      />
    </Routes>
  );
}
