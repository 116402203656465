/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BackButton from "../../../../../assets/icons/BackButton";
import TableView from "./table/view/TableView";
import GridView from "components/ui/molecules/MeetingGridView";
import NothingToDisplay from "components/ui/atoms/NothingToDisplay";
// import Pagination from "components/ui/atoms/Pagination";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
// import { getHostMeetingsRecordings } from "../../../../../redux/actions/meetingRepositoryActions";
import styles from "./styles.module.scss";
import SearchInput from "components/ui/atoms/SearchInput/SearchInput";
import { ReactComponent as List } from "../../../../../assets/images/list.svg";
import { ReactComponent as Grid } from "../../../../../assets/images/grid4.svg";
import { parseDateStringTitle } from "components/helpers/utils";
// import { selectFilteredHostMeetingRecordings } from "redux/selectors";

function DataList () {
  // Second level
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [parent, setParent] = useState(0);
  const [history, setHistory] = useState([0]);
  const [filterArray, setFilterArray] = useState([]);
  const [asc, setAsc] = useState(false);
  const [data, setData] = useState(null);
  const { searchText } = useSelector(s => s.SearchReducer);
  const [type, setType] = useState("row");
  const { id: sessionId } = useParams();
  const [sessionTitle, setSessionTitle] = useState('');
  const [roomId, setRoomId] = useState('');

  const {
    user: { email },
  } = useSelector(_state => _state.authReducer);
  const {
    // hostMeetingRecordings: recordings,
    meetingSessions,
    loadingMeetingSessions,
  } = useSelector(state => state.meetingRepository);

//   const recordings = useSelector(selectFilteredHostMeetingRecordings);
  useEffect(() => {

    // Run data queries here
    // dispatch(getHostMeetingsRecordings(email));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const backHandler = () => {
    navigate(`/meeting-repository/hostMeetings/sessions/${roomId}`);
  };

  useEffect(() => {
    return () => {
        dispatch({ type: "CLEAR_SEARCH_TEXT" });
    };
}, [dispatch]);

  // const sortAtoZ = type => {
  //   let sortedArray = [...filterArray];
  //   setAsc(!asc);
  //   sortedArray.sort((a, b) => {
  //     const isReversed = asc ? -1 : 1;
  //     return isReversed * a[type].localeCompare(b[type]);
  //   });
  //   setFilterArray(sortedArray);
  // };

  const setPathFromChild = (index, type) => {
    if (type === "folder") {
      setParent(index);
      let historyArray = [...history, index];
      setHistory(historyArray);
    }
  };

  const filterParentData = tableData => {
    let filter = tableData
      .map(item => ({ ...item, parent: 0 }))
      .filter(a => {
        return a.parent === parent;
      })
      .sort((a, b) => {
        const isReversed = -1;
        if (a.type && b.type) {
          return isReversed * a.type.localeCompare(b.type);
        }
        return 0;
      });
    setFilterArray(filter);
  };

  const handleMoveToFolder = result => {
    const newArray = [...data];

    const arrayToSave = newArray.map(item => {
      if (item.index === parseInt(result.draggableId)) {
        return {
          ...item,
          parent: parseInt(result.destination.droppableId),
        };
      } else {
        return item;
      }
    });

    setData(arrayToSave);

    filterParentData(arrayToSave);
  };

  useEffect(()=> {
    if (meetingSessions.length) {
        const session = meetingSessions.find(meeting => meeting.sessionId === sessionId);
        const parsedDateTitle = parseDateStringTitle(session.createdAt);
        setRoomId(session.roomId);
        setSessionTitle(`${session.roomId} - ${parsedDateTitle}`);
    }
  }, [meetingSessions, loadingMeetingSessions, sessionId]);

  return (
    <Fragment>
      <div className={styles.container}>
        <button
          // disabled={parent === 0}
          className={styles.backButton}
          onClick={() => backHandler()}
        >
          <BackButton onClick={backHandler} />
          <p>Go back</p>
        </button>
        <div className={styles.heading}>
          <div></div>
          <h1 className={styles.title}>{sessionTitle}</h1>
          <div className={styles.icons}>
            <SearchInput
              value={searchText}
              onChange={e =>
                dispatch({ type: "SET_SEARCH_TEXT", payload: e.target.value })
              }
            />
            <div onClick={() => setType("row")}><List /></div>
            <div onClick={() => setType("grid")}><Grid /></div>

          </div>
        </div>
        {/* {loading === false && (
          <>
            {recordings.length > 0 ? (
              <>
                {type === "row" ? (
                  <TableView
                    list={recordings}
                    pathHandler={setPathFromChild}
                    moveToFolder={handleMoveToFolder}
                  />
                ) : (
                  <GridView list={recordings} pathHandler={setPathFromChild} />
                )}
              </>
            ) : (
              <NothingToDisplay />
            )}
          </>
        )} */}
        {
            (!loadingMeetingSessions && sessionId) && (
                // <TableView
                //     list={[]}
                //     pathHandler={setPathFromChild}
                //     moveToFolder={handleMoveToFolder}
                // />
                <>
                    {type === "row" ? (
                        <TableView
                            list={[]}
                            sessionId={sessionId}
                            pathHandler={setPathFromChild}
                            moveToFolder={handleMoveToFolder}
                        />
                    ) : (
                        <GridView list={[]} pathHandler={setPathFromChild} />
                    )}
                </>
            )
        }
      </div>
    </Fragment>
  );
}

DataList.propTypes = {
  type: PropTypes.string,
  recordings: PropTypes.array,
};

export default DataList;
