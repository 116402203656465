import React from "react";
import styles from "./style.module.scss";
import PropTypes from "prop-types";

function SharedButton({children, extraClass, clickEvent, type, disabled, setState, setDeleteMenu}) {
    const  deleteResource = () => {
        if (!setState || !setDeleteMenu) {
            return clickEvent();
        } else {
            clickEvent();
            setDeleteMenu(false);
            return setState(false);
        }
    };

  return (
    <button
      onClick={deleteResource}
      className={`${styles.sharedButton} ${styles[extraClass]}`}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default SharedButton;
SharedButton.propTypes = {
    children: PropTypes.string,
    extraClass: PropTypes.string,
    clickEvent: PropTypes.func,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    setState: PropTypes.func,
    setDeleteMenu: PropTypes.func
};
