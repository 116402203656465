import React from "react";
import RightMenu from "components/ui/atoms/RightMenu";
import Cards from "components/ui/molecules/Home/Cards";
import DashboardIntro from "components/ui/molecules/Home/DashboardIntro";
import { homeMenu } from "constants/homeMenu";
import image from "assets/images/homemenu1.jpg";

function Home() {

  return (
    <>
      <DashboardIntro />
      <Cards />
      <RightMenu list={homeMenu} buttons bgImage={image} />
    </>
  );
}

export default Home;
