import { useEffect } from "react";

const useSetAllComments = (setAllComments, totalComments) => {
    useEffect(() => {
        if (totalComments === 0) {
            setAllComments([]);
        }
    }, [setAllComments, totalComments]);
};

export default useSetAllComments;
