import React, { useState } from "react";
import HomeCard from "components/ui/atoms/HomeCard";
import { homeCards } from "constants/homeCards";
import styles from "./style.module.scss";

function Cards() {
  const [activeCard, setActiveCard] = useState(0);
  return (
    <div className={styles.container}>
      <div className={styles.cardsContainer}>
        {homeCards.map((d, i) => {
          return (
            <HomeCard
              key={i}
              {...d}
              index={i}
              setActiveCard={setActiveCard}
              activeCard={activeCard}
            />
          );
        })}
      </div>
    </div>
  );
}
export default Cards;
