import DashboardCard from "components/ui/atoms/DashboardCard";
import React from "react";
import styles from "./style.module.scss";

export default function DashboardCards() {
  return (
    <div className={styles.cards}>
      <DashboardCard title="Active interviews" subtitle="4" />
      <DashboardCard
        title="Respond rate"
        subtitle="100%"
        extraClass="active"
      />
      <DashboardCard title="Upgrade plan" subtitle="Free" />
      <DashboardCard title="More questions?" subtitle="Contact" />
    </div>
  );
}
