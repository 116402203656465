import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";

function DropdownSelect({ label, onChange, selected, list, disabled }) {
  const [showOptions, setShowOptions] = useState(false);

  const closeAnyDropDown = () => {
    setShowOptions(false);
  };

  useEffect(() => {
    document.body.addEventListener("click", closeAnyDropDown);
    return () => {
    document.body.removeEventListener("click", closeAnyDropDown);
    };
  }, []);

  return (
    <div className={styles.inputGroup}
    >
      <label>{label}</label>
      <div
        className={styles.selectContainer}
        onChange={onChange}
        disabled={disabled}
        aria-hidden
        onClick={(e) => {!disabled && e.stopPropagation(); !disabled && setShowOptions(!showOptions)}}
      >
        <span>{selected}</span>
        <div
          className={styles.dropDownArrow}
        >
          ▼
          <div className={showOptions ? `${styles.optionsList} ${styles.activeOptions}` : styles.optionsList}>
            {
              list.map((option, index) => {
                return (
                  <div
                    className={styles.option}
                    key={index}
                    aria-hidden
                    onClick={() => {
                      onChange(option);
                      // setShowOptions(false); does it from eventListener
                    }}
                  >
                    {option}
                  </div>
                );
              }
              )
            }

          </div>
        </div>
      </div>
    </div>
  );
}
export default DropdownSelect;
DropdownSelect.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  list: PropTypes.array,
  selected: PropTypes.string,
};
