/* eslint-disable no-unused-vars */
import { GET_USER_DATA, UPDATE_USER_DATA } from "../actions/actionTypes";

const INITIAL_DATA = {
  error: false,
  isFetching: true,
  profile: null,
  account: null,
  emailPrefs: null,
  contact: null,
  notifications: null,
  billings: null,
  meetings: null,
  errorMessage: "",
};

const userDataReducer = (state = INITIAL_DATA, { type, payload, error }) => {
  switch (type) {
    case GET_USER_DATA.INIT:
      return { ...state, isFetching: true };

    case GET_USER_DATA.SUCCESS:
      if(payload.profile){
        const { _id, updatedAt, wizr_user_id, __v, createdAt, ...rest } =
        payload.profile;
        payload.profile = rest;
      }
      return { ...state, ...payload, isFetching: false };

    case GET_USER_DATA.ERROR:
      return {
        ...state,
        error: true,
        isFetching: false,
        message: payload.message,
      };
    case UPDATE_USER_DATA.INIT:
      return { ...state, isFetching: true };

    case UPDATE_USER_DATA.SUCCESS:
      if(payload.profile){
        const { _id, updatedAt, wizr_user_id, __v, createdAt, ...rest } =
        payload.profile;
        payload.profile = rest;
      }
      return { ...state, ...payload, isFetching: false };

    case UPDATE_USER_DATA.ERROR:
      return {
        ...state,
        error: true,
        isFetching: false,
        message: payload.message,
      };
    default:
      return state;
  }
};

export default userDataReducer;
