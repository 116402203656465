import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import "./styles.scss";
import { UniversalMenu } from "@master-wizr/universal-menu";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateUniversalMenuState } from "../../../../redux/actions/actionCreators/universalMenuActionCreators";
import UniversalMenuLogo from "../../../../assets/icons/UniversalMenuLogo";
import { logoutUser } from "../../../../redux/actions/authenticationActions";

const initialValues = {
  mode: "dark",
};

const UniversalMenuComponent = () => {
  const dispatch = useDispatch();
  const [props, setProps] = useState(initialValues);
  const { view } = useSelector((state) => state.universalMenu);

  const { selectedProfile, currentCompanyInfo } = useSelector((state) => state.profilesReducer);

  const toggleLight = () => {
    setProps((prevProps) => ({
      ...prevProps,
      mode: "light"
    }));
  };

  const navigate = useNavigate();
  const toggleDark = () => {
    setProps((prevProps) => ({
      ...prevProps,
      mode: "dark"
    }));
  };

  const openMenu = () => {
    dispatch(updateUniversalMenuState(true));
  };
  const closeMenu = () => {
    dispatch(updateUniversalMenuState(false));
  };

  const handleCreateShowroom = () => { };
  const handleCreateCoverPages = () => { };
  const logout = () => {
    dispatch(logoutUser());
  };

  return (
    <>
    {
        (selectedProfile && view) &&
        <div
          className={
            view ? "universal__wrapper" : "universal__wrapper closedMenu"
          }
        >
          <UniversalMenu
            {...{
              ...props,
              logout,
              toggleLight,
              toggleDark,
              toggleMenu: closeMenu,
              _redirect: navigate, // uses react router dom useNavigate hook to handle route navigation
              handleCreateShowroom,
              handleCreateCoverPages,
              profile: selectedProfile,
              orgName: currentCompanyInfo?.name ? currentCompanyInfo?.name : undefined,
              redirectURL: "videodashboard",
              hiddenIcons: 'wizrup',
              VIDEODASHBOARD_URL: process.env.REACT_APP_URL,
              ENV: process.env.REACT_APP_DEV_STATE || "prod",
              accessedPortals: selectedProfile?.accessedPortals || [],
              CURRENT_ORG: `${selectedProfile ? selectedProfile.accountId : ""}-`,
              activeProfile: Cookies.get("activeProfile"),

            }}
          />
        </div>
      }
      {!view && (
        <button className="menu_logo_btn" onClick={openMenu}>
          <UniversalMenuLogo />
        </button>
      )}
    </>
  );
};

export default UniversalMenuComponent;
