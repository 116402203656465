import { toast } from "react-toastify";

export const successToast = (message) => toast.success(message, {
    
    hideProgressBar: true,
    style: {
        backgroundColor: "#1d1d1da8",
        backdropFilter: "blur(15px)",
        color: "white",
        right: "-1.1em",
        top: "-1em"
    }
});

export const errorToast = (error) => toast.error(error, {hideProgressBar: true});

export const warningToast = (error) => toast.warn(error, {hideProgressBar: true});

export const infoToast = (info) => toast.info(info, {hideProgressBar: true});