import {SCHEDULE_MEETING} from "../actionTypes";

export const scheduleMeetingInit = () => ({
    type: SCHEDULE_MEETING.INIT
});

export const scheduleMeetingSuccess = (payload) => ({
    type: SCHEDULE_MEETING.SUCCESS,
    payload
});

export const scheduleMeetingError = (error) => ({
    type: SCHEDULE_MEETING.ERROR,
    error,
});