import React from "react";
import styles from "./style.module.scss";
import ApplicantCard from "components/ui/atoms/ApplicantCard";
import SharedButton from "components/ui/atoms/SharedButton";
import InvitePopup from "../../organisms/InvitePopup";
import RenamePopup from "components/ui/organisms/RenamePopup";

export default function AllAplicants() {
  const titles = ["Invited:", "To review: ", "Accepted:", "Rejected:"];
  const desc = ["0", "4", "2", "0"];

  const titles2 = ["Started:", "Responded:", "Start:", "End:"];
  const desc2 = [
    "6 applicants",
    "2 applicants (100%)",
    "2 March 2022",
    "No end date",
  ];
  return (
    <div className={styles.allaplicants}>
      <div className={styles.cardContainer}>
        <ApplicantCard descList={desc} titlesList={titles} />
        <ApplicantCard descList={desc2} titlesList={titles2} />
        <div className={styles.buttons}>
          <InvitePopup />
          <RenamePopup />
          <SharedButton extraClass="grayBtn1">Create</SharedButton>
        </div>
      </div>
    </div>
  );
}
