import { print } from "graphql/index";
import {
  scheduleMeetingInit,
  scheduleMeetingSuccess,
  scheduleMeetingError,
} from "./actionCreators/scheduleMeetingActionCreators";
import { successToast } from "components/ui/atoms/Toasts/toasts";
import axiosInstance from "utils/axiosInstance";
import { SEND_MAIL_VIDEO_DASHBOARD } from "../../graphql/sendMailQuery";
import Cookies from "js-cookie";

export const scheduleMeeting = (body, callBack) => async dispatch => {
  dispatch(scheduleMeetingInit());
  try {
    const response = await axiosInstance.post(`/api/v1/schedule-meeting`, {
      ...body,
      passcode: false,
      waitingRoom: false,
      meetingId: "auto",
      recurring: false,
    });

    // const linkResponse = await axiosInstance.post('https://wizrconnect.com/api/create-wizrlink', {
    //   email: body.userEmail,
    //   userId: body.userId,
    //   name: body.userName
    // });

    dispatch(scheduleMeetingSuccess({ ...response.data.data }));
    callBack && callBack(body, response.data.data);
    // const meetingLink = linkResponse.data.data;
    // callBack(body);
    successToast("Meeting scheduled successfully");

    const mailData = body.email.map(email => ({
      mail: email,
      username: email?.split("@")[0],
      isScheduled: true,
      link: response.data.data.meetingLink,
    }));

    try {
      // const authToken = localStorage.getItem("authToken").trim();
      const authToken = Cookies.get("authCode");
      // const { REACT_APP_SERVER_URL } = process.env;
      const serverUrl = process.env.REACT_APP_SERVER_URL;
      console.log(serverUrl, "-----surl----");
      const { data } = await axiosInstance.post(serverUrl, {
        query: print(SEND_MAIL_VIDEO_DASHBOARD),
        headers: { "Content-Type": "application/json", Authorization: authToken },
        variables: { input: mailData },
      });

      console.log(data, "----data------");


      // if (data.sendMailVideoDashboard.success) {
      //   dispatch(scheduleMeetingSuccess(data.sendMailVideoDashboard.message));
      // } else {
      //   dispatch(scheduleMeetingError(data.sendMailVideoDashboard.message));
      // }
    } catch (error) {
      console.log("------------", error);
      // debugger;
      // console.log(error?.message, "Error message");
    }
  } catch (error) {
    dispatch(scheduleMeetingError(error));
  }
};
