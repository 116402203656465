import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "../Modal";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { saveRecording } from "../../../../../redux/actions/recordingActions";
import { TailSpin } from "react-loader-spinner";

function SavingFileModal({
  click,
  url,
  videoName,
  stopVideo,
  blobFile,
  close,
}) {
  const formData = new FormData();
  const { addStatus, error } = useSelector(state => state.recordingReducer);
  const {
    user: { email },
  } = useSelector(_state => _state.authReducer);
  const dispatch = useDispatch();

  const [savingLocally, setsavingLocally] = useState(true);
  const [uploadLoading, setUploadLoading] = useState(false);

  return (
    <Modal>
      <div className={styles.saveFileModal}>
        <div className={styles.header}>
          <h2>Saving your record</h2>
          <button onClick={close} className={styles.closeBtn}>X</button>
        </div>
        <div className={styles.content}>
          {/* <p>
            {savingLocally
              ? "The recording will be saved on your personal computer."
              : "The recording will be saved to the cloud"}
          </p> */}
          <div className={styles.buttons}>
            {/* <button
              onClick={
                savingLocally ? () => setsavingLocally(false) : stopVideo
              }
              disabled={addStatus}
            >
              Cancel
            </button> */}

              <a
                href={url}
                download={`${videoName}.mp4`}
                onClick={() => setTimeout(() => setsavingLocally(false), 200)}
              >
                Download to PC
              </a>

              <button
                className={uploadLoading ? `${styles.uploadLoading}` : null}
                onClick={() => {
                  formData.append("file", blobFile, videoName);
                  setUploadLoading(true);
                  dispatch(saveRecording(formData, () => {click(); setUploadLoading(false)}));
                }}
                disabled={uploadLoading}
              >
                {uploadLoading ? (
                  <TailSpin color="orange" />
                ) : (
                  "Upload to cloud"
                )}
              </button>

          </div>
          <p>{!addStatus && error && error.message}</p>
        </div>
      </div>
    </Modal>
  );
}
SavingFileModal.propTypes = {
  click: PropTypes.func,
  url: PropTypes.string,
  videoName: PropTypes.string,
  stopVideo: PropTypes.func,
  dataURL: PropTypes.string,
  blobFile: PropTypes.object,
  close: PropTypes.func,
};

export default SavingFileModal;
