import React, {useState, useRef} from "react";
import styles from "./style.module.scss";
import PropTypes from "prop-types";
import { ReactComponent as Plus } from "assets/images/plus.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import useClickOutside from "components/helpers/useClickOutside";

function QuestionCards({ question, description, add, index }) {
    const [activeMenu, setActiveMenu] = useState(false);
    const openMenu = e => {
        e.preventDefault();
        setActiveMenu(index === activeMenu ? false : index);
    };
    const teamRef = useRef(null);
    useClickOutside(teamRef, () => setActiveMenu(false));

    return add ? (
        <div className={`${styles.addQuestion} ${styles.plusIcon}`}>
            <div className={styles.plusIcon}>
                <Plus />
            </div>
            <p>{description}</p>
        </div>
    ) : (
        <div className={styles.questionContainer} ref={teamRef}>
            <div className={styles.addQuestion}>
                <h3>{question}</h3>
                <p>{description}</p>
            </div>
            <div aria-hidden={true} role="button"  className={styles.edit} onClick={(e) => openMenu(e)}>
                <Edit />
                <button >Edit actions</button>
            </div>
            {index === activeMenu && (
                <div className={`${styles.dropdown} ${styles.showDropdown}`}>
                    <button>Edit question</button>
                    <button>Set timer</button>
                    <button>Number of retakes</button>
                    <button>Delete</button>
                </div>
            )}
        </div>
    );
}

QuestionCards.propTypes = {
    question: PropTypes.string,
    description: PropTypes.string,
    add: PropTypes.bool,
    index: PropTypes.number,
};
export default QuestionCards;