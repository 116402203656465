import React from "react";
import { Routes, Route} from "react-router-dom";
import styles from "./style.module.scss";
import FixedInteractives from "./Components/organisms/FixedInteractives";
import SettingsSidebar from "./Components/organisms/SettingsSidebar";
import SettingsMainContent from "./Components/organisms/SettingsMainContent";

// Route components imports
import Account from "./Components/RouteComponents/Account";
import EmailPrefs from "./Components/RouteComponents/EmailPrefs";
import BillingPlans from "./Components/RouteComponents/BillingPlans";
import Contact from "./Components/RouteComponents/Contact";
import Meetings from "./Components/RouteComponents/Meetings";
import Notifications from "./Components/RouteComponents/Notifications";
import Profile from "./Components/RouteComponents/Profile";
import NotFound from "./Components/RouteComponents/NotFound";
import { useSelector } from "react-redux";


export default function SettingPage() {
  const { account, billings, contact, emailPrefs, meetings, notifications, profile, isFetching } = useSelector(state => state.userData);
  const { _id, email, fullname } = useSelector(state => state.authReducer.user);

  return (
      <div className={styles.settingsMain}>
        <div className={styles.auxiliaryBackground} />
        <div className={styles.mainDashboard}>
          <FixedInteractives name={fullname}/>
          <SettingsSidebar />
          <SettingsMainContent>
            <Routes>
              <Route index exact element={<Profile profile={profile} isFetching={isFetching} />} />
              <Route path="account" exact element={<Account />} />
              <Route path="email-preferances" exact element={<EmailPrefs />} />
              <Route path="contact" exact element={<Contact />} />
              <Route path="notifications" exact element={<Notifications />} />
              <Route path="billings" exact element={<BillingPlans />} />
              <Route path="meetings" exact element={<Meetings />} />
              <Route path="*" exact element={<NotFound />} />
            </Routes>
          </SettingsMainContent>
        </div>
      </div>
  );
}
