import React from "react";
import FileViewer from "react-file-viewer";
import Close from "assets/icons/Close";
import styles from "./style.module.scss";

// eslint-disable-next-line react/prop-types
const FileViewerPopup = ({ file, fileType, isOpen, onRequestClose, title }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <div className={styles.Overlay}>
      <div className={styles.Modal}>
        <div className={styles.closeButton}>
          <Close onClick={onRequestClose} height='15' width='15' />
        </div>
        {title && <div className={styles.fileTitle}><p>{decodeURIComponent(title)}</p></div>}
        <FileViewer fileType={fileType} filePath={file} />
      </div>
    </div>
  );
};

export default FileViewerPopup;
