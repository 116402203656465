import React from "react";
import {homeMenu} from "constants/homeMenu";
import RightMenu from "components/ui/atoms/RightMenu";
import Container from "components/ui/molecules/GuidesContainer";

function Guides() {
  return (
    <>
      <Container/>
      <RightMenu list={homeMenu} buttons/>
    </>
  );
}

export default Guides;
