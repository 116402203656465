import React from "react";
import SecondBanner from "components/ui/molecules/secondBanner";
import Carousel from "../../molecules/Carousel";
import DashboardHead from "../../atoms/DashboardHead";

export default function index() {
  return (
    <div>
      <SecondBanner />
      <DashboardHead
        name="Ana Smith"
        subtitle="Review Dashboard"
        date="14/2/2022"
      />
      <Carousel />
    </div>
  );
}
