import { useState } from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import {ReactComponent as ArrowDown} from "../../../Assets/arrow-dwn.svg";


export default function SettingElement({
  header,
  description,
  togglerName,
  childAsFunction,
  placement,
  uploadChanges,
}) {
  const defaultVal = {
    transform: "scaleY(1)",
    zIndex: placement,
  };

  const [style, setStyle] = useState(defaultVal);
  const [editing, setEditing] = useState(false);
  const [submittableData, setSubmittableData] = useState({});

  const toggleSettings = () => {
    if (style.transform === "scaleY(1)") {
      setStyle({transform: "scaleY(0)", zIndex: placement});
    } else {
      setStyle(defaultVal);
    }
  };

  const handleEditing = () => {
    setEditing(!editing);
    if (style.transform === "scaleY(0)") {
      setStyle({transform: "scaleY(1)", zIndex: placement});
    }
  };

  return (
    <div className={styles.SettingElementContainer}>
      <div className={styles.header}>
        <button type="button"
        onClick={toggleSettings}
        ><ArrowDown className={style?.transform === "scaleY(1)" ? styles.default : styles.rotatedRight}/>
        </button>
        <h3>{header}</h3>
      </div>
      <div className={styles.detailsAndToggler}>
        <p className={styles.description}>{description}</p>
        <p
          className={styles.toggler}
          aria-hidden
          onClick={!editing ? handleEditing : () => {uploadChanges(); handleEditing()}}
        >
          {!editing ? togglerName  : "Save"}
        </p>
      </div>
      <div className={styles.childrenWrapper} style={style}>
        {style.transform === "scaleY(1)" && childAsFunction(editing, setSubmittableData)}
      </div>
    </div>
  );
}

SettingElement.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  togglerName: PropTypes.string,
  childAsFunction: PropTypes.func,
  placement: PropTypes.number,
  uploadChanges: PropTypes.func,
};
