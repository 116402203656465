import { useSelector } from "react-redux";

const useGetAuthenticatedUser = () => {
    const authenticatedUser = useSelector((state) => state?.authReducer?.user);
        return {
            userId: authenticatedUser?._id,
            author: authenticatedUser?.fullName,
        };
};

export default useGetAuthenticatedUser;
