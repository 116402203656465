import React, { useState } from "react";

import PropTypes from "prop-types";

import styles from "./styles.module.scss";
// import avatar from "../../../../assets/images/profile-picture.webp";
import { ReactComponent as File } from "../../../../assets/images/file.svg";
import { ReactComponent as Folder } from "../../../../assets/images/folder.svg";
import DropDownMenu from "../DropDownMenu";

const TableRow = ({
  title,
  roomId,
  _id,
  sizeInBytes,
  type,
  pathHandler,
  docs = [],
  activeMenu,
  setActiveMenu,
  dropDown
}) => {
  const [freePosition, setFreePosition] = useState();
  const handleContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveMenu(_id);
    setFreePosition({
      posX: e.target.offsetLeft
    });
  };
  return (
    <tr className={styles.row} onClick={() => pathHandler(docs[0]?.roomId, type, docs[0]?.activeRoom)} onContextMenu={(e) => {handleContextMenu(e)}} >
        {dropDown && _id === activeMenu && (
        <DropDownMenu
        id={_id}
        activeMenu={activeMenu}
        setActiveMenu={setActiveMenu}
        freePosition={freePosition}
        />
      )}
      <td>
      </td>
      <td>
        <span
          className={styles.name}
          onKeyDown={() => pathHandler(_id, type)}
          role="button"
          tabIndex={0}
        >
          {type === "file" ? <File /> : <Folder />} {title || roomId || _id}
        </span>
      </td>

        <td>{sizeInBytes}</td>
    </tr>
  );
};

TableRow.propTypes = {
  roomId: PropTypes.string,
  title: PropTypes.string,
  _id: PropTypes.string,
  type: PropTypes.string,
  pathHandler: PropTypes.func,
  activeMenu: PropTypes.string,
  setActiveMenu: PropTypes.func,
  dropDown: PropTypes.bool,
  docs: PropTypes.any,
  sizeInBytes: PropTypes.number
};

TableRow.defaultProps = {
  sizeInBytes: "..."
};

export default TableRow;
