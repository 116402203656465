/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { google, office365, yahoo, outlook } from "calendar-link";
import { toast } from "react-toastify";
import styles from "./style.module.scss";
// import { ReactComponent as Lock } from "assets/images/lock.svg";
import SharedButton from "components/ui/atoms/SharedButton";
import Input from "components/ui/atoms/Inputs/Input";
// import CheckInput from "components/ui/atoms/Inputs/CheckInput";
import { SharedOptions } from "./SharedOptions";
import { scheduleMeeting } from "../../../../redux/actions/scheduleMeetingActions";
import { scheduleMeetingError } from "../../../../redux/actions/actionCreators/scheduleMeetingActionCreators";
// import { ReactComponent as UserID } from "assets/images/userId.svg";
import { ReactComponent as Calendar } from "assets/images/calendar.svg";
import nullFieldChecker from "utils/nullFieldChecker";
import NotificationIcon from "assets/icons/Notification";
// import formatDate from "utils/formatDate";
import CopyIcon from "assets/icons/Copy";
import MultiEmailsSharing from "components/ui/atoms/MultiEmailsSharing";
import { networkErrorHandler } from "../../../helpers/networkErrorHandler";
import OptionInput from "components/ui/atoms/Inputs/OptionInput";
import SharedBanner from "components/ui/atoms/SharedBanner";
import Loader from "components/ui/atoms/Loader";
import TimeInput from "./timeInput/TimeInput";

function Container () {
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);
  const {
    user: { email, _id },
  } = useSelector(state => state.authReducer);
  const [startTimeResponse, setStartTimeResponse] = useState(null);
  const [formData, setFormData] = useState({
    topic: null,
    start: null,
    email: [],
    // recurring: false,
    duration: null,
    calendar: null,
  });
  const [errors, setErrors] = useState({
    topic: null,
    start: null,
    email: [],
    // recurring: null,
    duration: null,
    calendar: null,
  });

  console.log(formData, "formData");

  const clearFormData = () => {
    setFormData({
      topic: "",
      start: "",
      email: [],
      duration: "",
      calendar: null,
    });
    setHour(0);
    setMinute(0);
    setSecond(0);
  };

  const [wrongInput, setWrongInput] = useState(false);
  // const [guests, setGuests] = useState([]);

  const setGuests = emails => {
    setFormData(prevState => ({
      ...prevState,
      email: emails,
    }));
    if (emails) {
      setErrors(prevErrors => ({
        ...prevErrors,
        email: null,
      }));
    }
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
    // If a value is entered, remove the error message
    if (value) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  const resetRadioInputs = () => {
    const radioInputs = document.querySelectorAll("input[type=radio]");
    radioInputs.forEach(input => {
      input.checked = false;
    });
  };

  const validate = () => {
    if (hour < 0 || hour > 23 || hour === "") {
      setErrors(prevErrors => ({
        ...prevErrors,
        duration: "Hour must be between 00 and 23.",
      }));
      return false;
    }

    if (minute < 0 || minute > 59 || minute === "") {
      setErrors(prevErrors => ({
        ...prevErrors,
        duration: "Minute must be between 00 and 59.",
      }));
      return false;
    }

    if (second < 0 || second > 59 || second === "") {
      setErrors(prevErrors => ({
        ...prevErrors,
        duration: "Second must be between 00 and 59.",
      }));
      return false;
    }
    return true;
  };


  const callBack = (body, response) => {
    const eventData = {
      title: body.topic,
      description: response.meetingLink,
      start: body.start,
      duration: [body.duration, "hour"],
      guests: body.email,
    };
    setStartTimeResponse(eventData.start);
    // console.log(calendar, "calendar");
    if (body.calendar.toLowerCase() === "office") {
      window.open(office365(eventData), "_blank");
    } else if (body.calendar.toLowerCase() === "outlook") {
      window.open(outlook(eventData), "_blank");
    } else if (body.calendar.toLowerCase() === "google") {
      window.open(google(eventData), "_blank");
    } else if (body.calendar.toLowerCase() === "yahoo") {
      window.open(yahoo(eventData), "_blank");
    }
    // clearState();
    clearFormData();
    resetRadioInputs();
  };

  const { data, loading } = useSelector(state => state.scheduleMeetingReducer);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    let validationErrors = {};

    const dur = `${hour}:${minute}:${second}`;
    console.log(dur,"dur");
    setFormData(prevState => ({
      ...prevState,
      duration: dur,
    }));

    if (!formData.topic) validationErrors.topic = "Topic is required.";
    if (!formData.start) validationErrors.start = "Start date is required.";
    if (!formData.email.length) validationErrors.email = "Email is required.";
    // if (!formData.recurring)
    //   validationErrors.recurring = "Recurring is required.";
    console.log(formData.duration, "formData.duration");

    if (!dur) validationErrors.duration = "Duration is required.";
    if (!formData.calendar)
      validationErrors.calendar = "Calendar option is required.";



    if(hour < 1 && minute < 30) {
      validationErrors.duration = "Meeting should have a minimum duration of 30 minutes";
    }

    setErrors(validationErrors);
    validate();



    if (Object.keys(validationErrors).length === 0) {

      dispatch(
        scheduleMeeting(
          { ...formData, userEmail: email, userName: email, userId: _id, duration: dur },
          callBack
        )
      );

    }
  };

  // const { user } = useSelector(_state => _state.authReducer);

  // const clearState = () => {
  //   setMore(false);
  //   setTopic("");
  //   setStart("");
  //   setGuests([]);
  //   setRecurring(false);
  //   setDuration("");
  //   // setPasscode(false);
  //   // setWaitingRoom(false);
  //   // setWaitingRoom(false);
  //   // setCalendar("");
  //   // setLinkGeneration("");
  //   resetRadioInputs();
  // };

  const scheduledItems = [
    // {
    //   icon: <UserID />,
    //   title: "Meeting ID",
    //   onChange: setLinkGeneration,
    //   id: 1,
    //   options: ["Generate Automatically", "Personal Meeting ID"],
    // },
    {
      icon: <Calendar />,
      title: "Calendar",
      name: "calendar",
      value: formData.calendar,
      onChange: handleInputChange,
      id: 2,
      options: ["Outlook", "Google", "Yahoo"],
    },
  ];

  // const isAllDataAvailable = nullFieldChecker(body);

  // const isInitialDataAvailable = nullFieldChecker(initialData);

  // const handleSubmit = () => {
  //   if (!isAllDataAvailable) {
  //     dispatch(scheduleMeetingError("Please fill in the required fields"))
  //   }
  //   dispatch(scheduleMeeting(body, callBack))
  //   networkErrorHandler(error, message)
  // }

  const copyLink = async text => {
    try {
      await navigator.clipboard.writeText(text);
      toast("Meeting link copied successfully", {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    } catch (error) {
      console.log(error);
      toast("Error occured while copying meeting link", {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }
  };

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  let yyyy = today.getFullYear();
  let hh = String(today.getHours()).padStart(2, "0");
  let min = String(today.getMinutes()).padStart(2, "0");

  today = yyyy + "-" + mm + "-" + dd + "T" + hh + ":" + min;
  console.log(errors, "errors");

  const preventTyping = e => {
    e.preventDefault();
  };

  return (
    <>
      <SharedBanner
        extraClass='scheduleEventBanner'
        extraClass1='recordings'
        title='Schedule meeting'
        description='Save time scheduling your day with the power of our tool.'
      />
      <div className={styles.container}>
        <div className={styles.frameOptions}>
          {/* <button
            className={more ? styles.activeBtn : ``}
            onClick={() => setMore(true)}
            disabled={loading || isInitialDataAvailable}
          >
            Advanced
          </button> */}
        </div>
        <div className={styles.frame}>
          {data && (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <NotificationIcon />
                <p
                  style={{
                    color: "#1AD0A8",
                    wordWrap: "break-word",
                    paddingLeft: "5px",
                  }}
                >
                  {data?.message}
                </p>
              </div>
              <div className={styles.truncateContainer}>
                <div className={styles.textAndCopy}>
                  <p className={styles.truncate}>
                    <a href={data?.meetingLink}>{data?.meetingLink}</a>
                  </p>
                  <CopyIcon onClick={() => copyLink(data?.meetingLink)} />
                </div>
              </div>
              <div>
                <p className={styles.startTime}>
                  Start Time:{" "}
                  {moment(startTimeResponse).format("MMMM D, YYYY, h:mm A")}
                </p>
              </div>
              <br />
              <br />
            </>
          )}

          <div className={styles.basicCont} style={{ display: "flex" }}>
            <div className={styles.inputs}>
              <Input
                label='Topic'
                placeholder='Add Topic'
                type='text'
                required
                onChange={handleInputChange}
                name='topic'
                value={formData.topic}
              />
              {errors.topic && (
                <p className={styles.formError}>{errors.topic}</p>
              )}
              <div>
                <Input
                  label='Start'
                  placeholder='From, e.g Monday, 15 Feb 10:30am'
                  type='datetime-local'
                  required
                  onChange={handleInputChange}
                  value={formData.start}
                  name='start'
                  min={today}
                  onKeyDown={preventTyping}
                />
                {errors.start && (
                  <p className={styles.formError}>{errors.start}</p>
                )}

                {/* <Input
                  label='Duration'
                  placeholder='Duration (hours)'
                  type='number'
                  name='duration'
                  min={0}
                  required
                  onChange={handleInputChange}
                  value={formData.duration}
                /> */}
                <p className={styles.mutiEmailLabel}>Duration (hh:mm:ss)</p>
                <TimeInput hour={hour} minute={minute} second={second} setHour={setHour} setMinute={setMinute} setSecond={setSecond}/>
                {errors.duration && (
                  <p className={styles.formError}>{errors.duration}</p>
                )}
                {/* <OptionInput
                  label='Recurring'
                  description=''
                  name="recurring"
                  onChange={handleInputChange}
                />
                {errors.recurring && (
                  <p className={styles.formError}>{errors.recurring}</p>
                )} */}
              </div>
              <p className={styles.mutiEmailLabel}>Emails</p>
              <MultiEmailsSharing
                label='Add guests'
                withButton={false}
                placeholder='Email, separate with comma'
                mailList={formData.email}
                mailListSetter={setGuests}
                error={wrongInput}
                setError={setWrongInput}
              />
              {!formData.email.length && (
                <p className={styles.formError}>{errors.email}</p>
              )}
            </div>
            <div className={styles.inlineOptions}>
              {scheduledItems.map((d, i) => {
                return (
                  <>
                    {" "}
                    <SharedOptions
                      id={d.id}
                      list={d.options}
                      {...d}
                      key={i}
                      onChange={d.onChange}
                      value={d.value}
                      name={d.name}
                    />
                    {errors[d.name] &&
                      (d.name === "calendar" ? (
                        <p
                          className={styles.formError}
                          style={{ position: "absolute", paddingLeft: "110px" }}
                        >
                          {errors[d.name]}
                        </p>
                      ) : (
                        <p className={styles.formError}>{errors[d.name]}</p>
                      ))}
                  </>
                );
              })}
            </div>
          </div>

          {/* <div
            className={styles.advancedPart}
            style={{ display: more ? "block" : "none" }}
          >
            <div className={styles.heading}>
              <Lock /> <p>Security</p>
            </div>
            <div className={styles.check}>
              <CheckInput
                label='Passcode'
                description='Users with the invite link or passcode can join the meeting'
                value={passcode}
                onChange={e => setPasscode(e.target.value)}
              />
              <CheckInput
                label='Waiting Room'
                description='Users admitted by the host can join the meeting'
                value={waitingRoom}
                onChange={e => setWaitingRoom(e.target.value)}
              />
            </div>
            <div className={styles.checkPart}>
              <div className={styles.heading}>
                <Lock /> <p>Video</p>
              </div>
              <div className={styles.check}>
                <CheckInput
                  label='Host'
                  description='You(host) join the meeting with video turned on'
                />
                <CheckInput
                  label='Participants'
                  description='All participants to join the meeting with video turned on'
                />
              </div>
            </div>
          </div> */}

          <div>
            <div className={styles.btns}>
              {/* <SharedButton clickEvent={() => console.log("might need a click event")} extraClass="grayBtn">Cancel</SharedButton> */}
              <SharedButton
                clickEvent={() => handleSubmit()}
                disabled={loading}
              >
                {!loading ? "Schedule" : "Scheduling..."}
              </SharedButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Container;
