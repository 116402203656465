import axiosInstance from "utils/axiosInstance";
import * as actions from "./actionCreators/videoActionCreators";
import {
  successToast,
  errorToast,
} from "../../components/ui/atoms/Toasts/toasts";
import axios from "axios";

export const getVideo = async (dispatch, params) => {
  dispatch(actions.videoInit());
  try {
    const res = await axiosInstance.get(`/api/v1/video/${params.id}`);
    dispatch(actions.videoSuccess(res.data.data.video));
  } catch (error) {
    dispatch(actions.videoError(error));
  }
};

export const getVideobyUUID = async (dispatch, params) => {
  dispatch(actions.videoUUIDInit());
  try {
    const res = await axiosInstance.get(`/api/v1/share/video/${params.id}`);
    dispatch(actions.videoUUIDSuccess(res.data.data.share.video));
  } catch (error) {
    dispatch(actions.videoUUIDError(error));
  }
};

export const getVideos = ({limit = 10, page = 1}) => async dispatch => {
  console.log({limit, page});
  dispatch(actions.getVideosInit());
  try {
    const res = await axiosInstance.get(`/api/v1/video/get-all-videos?limit=${limit}&page=${page}`);
    dispatch(actions.getVideosSuccess(res.data));
  } catch (error) {
    dispatch(actions.getVideosError(error));
  }
};

export const createComment = async (dispatch, data) => {
  dispatch(actions.videoInit());
  try {
    const res = await axiosInstance.post(`/api/v1/comment/createComment`, data);
    dispatch(actions.videoSuccess(res.data));
    return successToast("The comment has been successfully created.");
  } catch (error) {
    dispatch(actions.videoError(error));
    return errorToast("An error occurred. The comment was not created.");
  }
};

export const deleteComment = async (dispatch, params) => {
  dispatch(actions.videoInit());
  try {
    const res = await axiosInstance.delete(
      `/api/v1/comment/deleteComment/${params._id}`
    );
    dispatch(actions.videoSuccess(res.data));
  } catch (error) {
    dispatch(actions.videoError(error));
  }
};
export const updateComment = async (dispatch, id, data) => {
  dispatch(actions.videoInit());
  try {
    const res = await axiosInstance.put(`/api/v1/comment/editComment/${id}`, data);
    dispatch(actions.videoSuccess(res.data));
  } catch (error) {
    dispatch(actions.videoError(error));
  }
};



export const getfavoriteVideos = ({limit = 10, page = 1}) => async dispatch => {
  dispatch(actions.getFavouriteVideosInit());
  try {
    const response = await axiosInstance.get(`/api/v1/video/favourite?limit=${limit}&page=${page}`);
    dispatch(actions.getFavouriteVideosSuccess(response.data));
  } catch (error) {
    dispatch(actions.getFavouriteVideosError(error));
  }
};

export const getArchivedVideos = (limit = 10, page = 1) => async dispatch => {

  dispatch(actions.getArchivedVideosInit());
  try {
    const response = await axiosInstance.get(`/api/v1/video/archive?limit=${limit}&page=${page}`);
    dispatch(actions.getArchivedVideosSuccess(response.data));
  } catch (error) {
    dispatch(actions.getArchivedVideosError(error));
  }
};

export const updateVideo = (id, body, callBack) => async dispatch => {
  dispatch(actions.updateVideoInit());
  try {
    const response = await axiosInstance.put(`/api/v1/video/updateVideo/${id}`, body);

    dispatch(actions.updateVideoSuccess(response.data.data));
    callBack && callBack();
    successToast("Video updated successfully");
  } catch (error) {
    dispatch(actions.updateVideoError(error));
  }
};

export const archiveVideo = (id, body, callBack) => async dispatch => {
  dispatch(actions.archiveVideoInit());
  try {
    const response = await axiosInstance.put(`/api/v1/video/updateVideo/${id}`, body);
    dispatch(actions.archiveVideoSuccess(response.data.data));
    callBack && callBack();
    successToast("Video archived successfully");
  } catch (error) {
    dispatch(actions.archiveVideoError(error));
  }
};

export const deleteVideo = (id, callBack) => async dispatch => {
  dispatch(actions.deleteVideoInit());
  try {
    const response = await axiosInstance.delete(`/api/v1/video/${id}`);
    console.log(response.data.data.video._id, "response.data.data.video._id");
    dispatch(actions.deleteVideoSuccess(response.data.data.video._id));
    callBack && callBack();
    successToast("Video deleted successfully");
  } catch (error) {
    dispatch(actions.deleteVideoError(error));
    errorToast("Video could not be deleted!");
  }
};

export const downloadVideo = (id, callBack) => async dispatch => {
  dispatch(actions.downloadVideoInit());
  try {
    const response = await axiosInstance.get(`/api/v1/video/download-video/${id}`, { responseType: "blob" });
    dispatch(actions.downloadVideoSuccess({}));
    callBack && callBack(response);
    successToast("Video download started");
  } catch (error) {
    dispatch(actions.downloadVideoError(error));
    errorToast("Video could not be downloaded");
  }
};

export const getSharedUrl =
  ({ payload, callBack, withToast, toastTxt, errTxt }) =>
  async dispatch => {
    dispatch(actions.getSharedLinkInit());
    try {

      const response = await axiosInstance.post(`/api/v1/share/video/`, payload);
      dispatch(actions.getSharedLinkSuccess(response.data.data));
      callBack && callBack(response.data.data.share.shareableUrl);
      withToast && successToast(toastTxt ? toastTxt : "Shareable link created and copied to clipboard!");
    } catch (error) {
      dispatch(actions.getSharedLinkrror(error));
      withToast && errorToast(errTxt ? errTxt : "Failed to create shareable link!");
    }
  };

export const duplicateVideo = (id, callBack, duplicateLocation) => async dispatch => {
  dispatch(actions.duplicateVideoInit());
  try {
    const response = await axiosInstance.post(`/api/v1/video/duplicate/${id}`);
    dispatch(actions.duplicateVideoSuccess(response.data.data, duplicateLocation));
    callBack && callBack();
    successToast("Video duplicated!");
  } catch (error) {
    dispatch(actions.duplicateVideoError(error));
    errorToast("Failed to duplicate video!");
  }
};

export const getSharedWithMeVideos = () => async dispatch => {
  dispatch(actions.getSharedWithMeVideosInit());
  try {
    const { data } = await axiosInstance.get(`/api/v1/share/videos/shared/personal`);
    return dispatch(actions.getSharedWithMeVideosSuccess(data.data.videos));
  } catch (error) {
      if (error.response) {
          dispatch(actions.getSharedWithMeVideosError(error));
      }
  }
};
