import { useState, useEffect, useRef } from "react";
import RightMenu from "components/ui/atoms/RightMenu";
import SharedBanner from "components/ui/atoms/SharedBanner";
import Container from "components/ui/molecules/Container";
import { homeMenu } from "constants/homeMenu";
import styles from "../MeetingRepository/styles.module.scss";
import cameraStyles from "./cameraStyles.module.scss";
import image from "assets/images/homemenu1.jpg";
import SettingsPopup from "components/ui/molecules/VideoRecords/SettingsPopup";
import RecordTabs from "components/ui/molecules/VideoRecords/RecordTabs";
import SaveRecordingPopup from "components/ui/molecules/VideoRecords/SaveRecordingPopup";
import SavingFileModal from "components/ui/molecules/VideoRecords/SaveFileModal";
import { successToast } from "../../atoms/Toasts/toasts";

var stream = null;
var audio = null;
var chunks = [];
var recorder = null;

function PersonalLibrary() {
  const [showPopupConfig, setShowPopupConfig] = useState(false);
  const [recording, setRecording] = useState(false);
  const [finishedVideoRecord, setFinishedVideoRecord] = useState(false);
  const [paused, setPaused] = useState(false);
  const [audioActive, setAudioActive] = useState(true);
  const [urlDonwloadFile, setUrlDonwloadFile] = useState("");
  const [blobFile, setBlobFile] = useState(null);
  const [recordingSavingOptions, SetRecordingSavingOptions] = useState(false);
  const [videoName, setVideoName] = useState("");
  const [settings, setSettings] = useState({
    audio: true,
    video: true,
    camera: true,
    formOfCamera: "roundedSqaure",
    postionOfCamera: "bottomRight",
    start: false,
    flex: false,
    selectedCamera: ""
  });

  const changeAudioSettingToTrue = () => setSettings(prev => ({ ...prev, audio: true }));
  const [time, setTime] = useState({
    minute: "00",
    second: "00",
  });
  const elemRef = useRef(null);
  const dragProps = useRef();

  function handlePopup() {
    setShowPopupConfig(!showPopupConfig);
  }

  // function requestPermissions() {
  //   navigator.mediaDevices
  //     .getUserMedia({ video: true, audio: true })
  //     .then(stream => {
  //       stream.getTracks().forEach(track => track.stop()); // Immediately stop all tracks once permissions are granted
  //     })
  //     .catch(error => {
  //       console.error("Error accessing media devices.", error);
  //     });
  // }

  // useEffect(() => {
  //   requestPermissions();
  // });

  function exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }
  }

  useEffect(() => {
    exitFullscreen();
  }, []);

  // if camera is on after settings selection show it on the UI
  useEffect(() => {
    let video = document.querySelector("#video");
    if (recording && settings.camera) {
      video.srcObject = audio ? audio : null;
    } else {
      video.srcObject = null;
    }
  }, [recording, settings.camera]);

  async function setupStream() {
    try {
      console.log("Setting up stream...");

      // let stream; let audio;
      if (settings.video) {
        console.log("Trying to get display media...");
        stream = await navigator.mediaDevices.getDisplayMedia({
          video: {
            width: 1280,
            height: 720,
          },
        });
        console.log("Display media obtained:", stream);

        audio = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });

        if (settings.audio === false) {
          // Mute the audio tracks in the stream
          const audioTracks = audio.getAudioTracks();
          // eslint-disable-next-line no-return-assign
          audioTracks.forEach(track => track.enabled = false);
          setAudioActive(false);

          // const track = audio.getAudioTracks();
          // track[0].enabled = !audioActive;
        }

        // Here you might want to start processing or using the stream,
        // like setting it to a video element to preview it, etc.
      }

      if (settings.audio || settings.camera) {
        console.log("Trying to get user media...", "Testing camera");
        // audio = await navigator.mediaDevices.getUserMedia({
        //     audio: settings.audio ? {
        //         echoCancellation: true,
        //         noiseSuppression: true,
        //         sampleRate: 44100,
        //     } : false,
        //     video: settings.camera ? true : false,
        // });
        audio = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: settings.camera ? true : false,
        });

        if (settings.audio === false) {
          // Mute the audio tracks in the stream
          const audioTracks = audio.getAudioTracks();
          // eslint-disable-next-line no-return-assign
          audioTracks.forEach(track => track.enabled = false);
          setAudioActive(false);

          // const track = audio.getAudioTracks();
          // track[0].enabled = !audioActive;
        }

        console.log("User media obtained:", audio);

        // Similarly, you might want to start processing or using the audio stream here.
      }
    } catch (err) {
      console.error("Error encountered:", err);
      if (err.name === "NotAllowedError") {
        console.log("error while getting permissions", err);
        throw new Error("Permissions not granted");
      }
    }
  }



  async function startRecording() {
    await setupStream();
    let mixedStream = null;

    if (audio || stream) {
      if (audio && stream) {
        mixedStream = new MediaStream([
          ...stream.getVideoTracks(),
          ...audio.getTracks(),
        ]);
      } else if (stream) {
        mixedStream = new MediaStream([...stream.getVideoTracks()]);
      } else if (audio) {
        mixedStream = new MediaStream([...audio.getTracks()]);
      } else {
        mixedStream = new MediaStream();
      }
      recorder = new MediaRecorder(mixedStream);
      recorder.ondataavailable = handleDataAvailable;
      recorder.onstop = handleStop;
      recorder.start(1000);
      document.querySelector("#root").requestFullscreen();
      setRecording(true);
      setShowPopupConfig(false);
      console.log("Recording started");
    } else {
      console.warn("No stream available.");
    }
  }

  function stopRecording() {
    recorder.stop();
    setRecording(false);
    setFinishedVideoRecord(true);
  }

  function handleDataAvailable(e) {
    chunks.push(e.data);
  }

  function handleStop() {
    const blob = new Blob(chunks, { type: "video/webm" });
    chunks = [];
    let downloadButton = null;
    downloadButton = URL.createObjectURL(blob);
    setUrlDonwloadFile(downloadButton);
    setBlobFile(blob);
    stream && stream.getTracks().forEach(track => track.stop());
    audio && audio.getTracks().forEach(track => track.stop());
    console.log("Recording stopped");
    exitFullscreen();
  }

  function pauseAndResumeRecording() {
    if (!recorder) return;
    if (recorder.state === "recording") {
      recorder.pause();
      setPaused(true);
    } else if (recorder.state === "paused") {
      recorder.resume();
      setPaused(false);
    }
  }

  function muteAndUnmute() {
    console.log(audioActive, "audioActive");
    const track = audio.getAudioTracks();
    track[0].enabled = !audioActive;
    setAudioActive(!audioActive);
  }

  function settingsData(e) {
    setSettings({
      audio: e.audio,
      video: e.screen,
      camera: e.camera,
      formOfCamera: e.formOfCamera,
      postionOfCamera: e.positionCamera,
      start: true,
      flex: e.flex ? e.flex : false,
      selectedCamera: e.selectedCamera,
      selectedAudioDevice: e.selectedAudioDevice
    });
  }

  const startDragging = ({ clientX, clientY }) => {
    elemRef.current.style.transform = `translate(${dragProps.current.dragStartLeft + clientX - dragProps.current.dragStartX
      }px, ${dragProps.current.dragStartTop + clientY - dragProps.current.dragStartY
      }px)`;
  };

  const stopDragging = () => {
    window.removeEventListener("mousemove", startDragging, false);
    window.removeEventListener("mouseup", stopDragging, false);
  };

  const initialiseDrag = event => {
    const { target, clientX, clientY } = event;
    const { offsetTop, offsetLeft } = target;
    const { left, top } = elemRef.current.getBoundingClientRect();

    dragProps.current = {
      dragStartLeft: left - offsetLeft,
      dragStartTop: top - offsetTop,
      dragStartX: clientX,
      dragStartY: clientY,
    };
    window.addEventListener("mousemove", startDragging, false);
    window.addEventListener("mouseup", stopDragging, false);
  };

  return (
    <div className={cameraStyles.personalLibContainer}>
      {!recording && (
        <>
          <SharedBanner
            title='Personal Library'
            extraClass='personalbanner'
            description='All your own files from WiZR Connect saved here.'
            button
            clickEvent={handlePopup}
          />
          <div className={styles.bottom}>
            <Container
              image
              viewType='personal-library'
            />
          </div>
          <RightMenu bgImage={image} list={homeMenu} buttons />
        </>
      )}
      <div className={cameraStyles.wrapper}>
        <div
          className={cameraStyles.videoRecords}
          style={{
            display: "flex",
            justifyContent: "center",
            userSelect: "none",
          }}
        >
          <video
            onMouseDown={settings.flex ? initialiseDrag : null}
            ref={settings.flex ? elemRef : null}
            className={`${cameraStyles[settings.postionOfCamera]} ${cameraStyles[settings.formOfCamera]
              } ${cameraStyles.flex ? cameraStyles.video : null}`}
            draggable={false}
            id='video'
            autoPlay
            muted
          ></video>

          {showPopupConfig && (
            <SettingsPopup
              settings={e => settingsData(e)}
              startRecord={startRecording}
              close={() => setShowPopupConfig(false)}
            />
          )}
          {recording && (
            <RecordTabs
              stopRecording={(minute, second) => {
                setTime({ minute: minute, second: second });
                stopRecording();
              }}
              recording={recording}
              pauseAndResumeRecording={pauseAndResumeRecording}
              paused={paused}
              audio={muteAndUnmute}
              audioSettings={settings.audio ? audio : null}
              changeAudioSettingToTrue={changeAudioSettingToTrue}
              audioActive={audioActive}
              audioStream={audio}
              cancelRecording={() => {
                recorder.stop();
                setRecording(false);
              }}
            />
          )}
          {finishedVideoRecord && (
            <SaveRecordingPopup
              close={() => {
                setFinishedVideoRecord(false);
              }}
              urlVideoFile={urlDonwloadFile}
              click={() => {
                setFinishedVideoRecord(false);
                SetRecordingSavingOptions(true);
              }}
              changeText={e => setVideoName(e)}
              videoName={videoName}
              time={time}
            />
          )}
          {recordingSavingOptions && (
            <SavingFileModal
              videoName={videoName ? videoName : "video"}
              blobFile={blobFile}
              url={urlDonwloadFile}
              stopVideo={() => SetRecordingSavingOptions()}
              click={() => {
                SetRecordingSavingOptions(false);
                setVideoName(null);
                successToast("Recording saved successfully");
              }}
              close={() => {
                // clear out all data
                setBlobFile(null);
                setVideoName(null);
                SetRecordingSavingOptions(false);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default PersonalLibrary;