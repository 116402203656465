import React from "react";
import SharedBanner from "components/ui/atoms/SharedBanner";
import DashboardCards from "components/ui/molecules/DashboardCards";
import DashboardChart from "components/ui/molecules/DashboardChart";

export default function Dashboard() {
  return (
    <div>
      <SharedBanner
        title="Welcome to your dashboard"
        description="Here are some key stats and links to help you get the mos out of connections today."
        extraClass1="interview"
        extraClass="interviewBanner"
      />
      <DashboardCards />
      <DashboardChart />
    </div>
  );
}
