import RightMenu from "components/ui/atoms/RightMenu";
import Container from "components/ui/molecules/ScheduleContainer";
import {rightMenuData} from "constants/schedule";
import image from "assets/images/bannerrecord.jpeg";
function ScheduleMeeting() {
  return (
    <>
      <Container/>
      <RightMenu bgImage={image} list={rightMenuData}/>
    </>
  );
}

export default ScheduleMeeting;
