import React from "react";

import PropTypes from "prop-types";

import styles from "./style.module.scss";
import OptionInput from "components/ui/atoms/Inputs/OptionInput";

export function SharedOptions({title, list, id, onChange, value, name}) {
  return (
    <div className={styles.sharedOptions}>
      <div className={styles.heading}>
        <p>{title}</p>
      </div>
      <div className={styles.options}>
        <OptionInput list={list} id={id} onChange={onChange} value={value} name={name} />
      </div>
    </div>
  );
}


SharedOptions.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  list: PropTypes.array,
  id: PropTypes.number,
  onChange: PropTypes.func,
  currentVal: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
};
