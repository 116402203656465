/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "components/ui/atoms/Loader";
import TableView from "components/ui/molecules/MeetingListTableView/MeetingListTableView";
import GridView from "components/ui/molecules/SubMeetingGridView";
import NothingToDisplay from "components/ui/atoms/NothingToDisplay";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { getSubHostMeetings } from "redux/actions/meetingRepositoryActions";
import styles from "./subMeetingStyles.module.scss";
import SearchInput from "components/ui/atoms/SearchInput/SearchInput";
import { ReactComponent as List } from "assets/images/list.svg";
import { ReactComponent as Grid } from "assets/images/grid4.svg";
import BackButton from "assets/icons/BackButton";
import {selectFilteredSubHostMeetings} from "redux/selectors";
// import { selectFilteredSubHostMeetings } from "redux/selectors";
// import Pagination from "components/ui/atoms/Paginate/Index";

function SubMeetings () {
  const { meetingId } = useParams();
  const dispatch = useDispatch();
  const { loadingSubHostMeetings } = useSelector(
    state => state.meetingRepository
  );

  const subHostMeetings = useSelector(selectFilteredSubHostMeetings);

  const {
    user: { email },
  } = useSelector(state => state.authReducer);


  useEffect(() => {
    dispatch(getSubHostMeetings(meetingId, email));
  }, []);

  useEffect(() => {
    return () => {
        dispatch({ type: "CLEAR_SEARCH_TEXT" });
    };
  }, [dispatch]);

  const [parent, setParent] = useState(0);
  const [history, setHistory] = useState([0]);
  const [filterArray, setFilterArray] = useState([]);
  const [asc, setAsc] = useState(false);
  const [type, setType] = useState("row");
  const [_data, setData] = useState(null);
  const { searchText } = useSelector(s => s.SearchReducer);

  const navigate = useNavigate();

  const sortAtoZ = type => {
    let sortedArray = [...filterArray];
    setAsc(!asc);
    sortedArray.sort((a, b) => {
      const isReversed = asc ? -1 : 1;
      return isReversed * a[type].localeCompare(b[type]);
    });
    setFilterArray(sortedArray);
  };

  const filterHandler = type => {
    switch (type) {
      case "roomId":
        // case "createdAt":
        // case "author":
        sortAtoZ(type);
        break;
      default:
        return filterArray;
    }
  };

  const setPathFromChild = (index, type, activeRoomId) => {
    if (type === "folder") {
      setParent(index);
      let historyArray = [...history, index];
      setHistory(historyArray);
    } else {
      navigate(`/meeting-repository/hostMeetings/${index}/${activeRoomId}`);
    }
  };

  const filterParentData = tableData => {
    let filter = tableData
      .map(item => ({ ...item, parent: 0 }))
      .filter(a => {
        return a.parent === parent;
      })
      .sort((a, b) => {
        const isReversed = -1;
        if (a.type && b.type) {
          return isReversed * a.type.localeCompare(b.type);
        }
        return 0;
      });
    setFilterArray(filter);
  };

  useEffect(() => {
    if (!loadingSubHostMeetings && subHostMeetings?.length) {
      filterParentData(subHostMeetings);
    }
  }, [parent, subHostMeetings]);

  const handleMoveToFolder = result => {
    const newArray = [..._data];

    const arrayToSave = newArray.map(item => {
      if (item.index === parseInt(result.draggableId)) {
        return {
          ...item,
          parent: parseInt(result.destination.droppableId),
        };
      } else {
        return item;
      }
    });

    setData(arrayToSave);

    filterParentData(arrayToSave);
  };

  const backHandler = () => navigate(-1);

  return (
    <Fragment>
        <div
      className={`${styles.container}`}
    >
      <button
          // disabled={parent === 0}
          className={styles.backButton}
          onClick={() => backHandler()}
        >
          <BackButton />
          <p>Go back</p>
        </button>
        <div className={styles.heading}>
          <div></div>
          <h1 className={styles.title}>{meetingId}</h1>
          <div className={styles.icons}>
            <SearchInput
              value={searchText}
              onChange={e =>
                dispatch({ type: "SET_SEARCH_TEXT", payload: e.target.value })
              }
            />
            <div onClick={() => setType("row")}><List /></div>
            <div onClick={() => setType("grid")}><Grid /></div>

          </div>
        </div>
      {loadingSubHostMeetings ? (
        <Loader />
      ) : (
        !loadingSubHostMeetings &&
        (subHostMeetings.length > 0 ? (
          <>
            {type === "row" ? (
              <TableView
                filterHandler={filterHandler}
                list={filterArray}
                showRecordings={false}
                pathHandler={setPathFromChild}
                moveToFolder={handleMoveToFolder}
                dropDown={false}
              />
            ) : (
              <GridView
                list={filterArray}
                pathHandler={setPathFromChild}
                dropDown={false}
              />
            )}
          </>
        ) : (
          <NothingToDisplay />
        ))
      )}
      {/* <Pagination
        data={data}
        currentPage={currentPage}
        totalPages={totalPages}
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        onPageChange={page => dispatch(getHostMeetings(email, 10, page))}
      /> */}
      </div>
    </Fragment>
  );
}

SubMeetings.propTypes = {
  type: PropTypes.string,
};

export default SubMeetings;
