import React from "react";
import VideoContent from "components/ui/molecules/VideoContent";
import SecondBanner from "components/ui/molecules/secondBanner";

export default function CompanyInfo() {
  const listitems = [
    "Welcome Greeting",
    "About 50+",
    "Videos made simple",
    "Why Show Rooms",
  ];
  return (
    <div>
      <SecondBanner />
      {/* <InterviewSteps /> */}
      <VideoContent
                // extraClass="unorderedList"
                // unorderedList
                defaultList
                list={listitems}
                // extraClass1="withoutBanner"
                title="Welcome to 50+ seeking Sales Manager"
                desc="From HR: We are particularly excited to offer this job position. We invite you to give it your best and apply by the 10th of March. We will review qualified candidates within 2 weeks. "
                listTitle="Video Tutorials"
                listDesc="Explore the provided information. Before you start your application process you may will receive additional information."
                subtitle="Opportunity to explain the next steps"
            />
    </div>
  );
}
