import React, { useState } from "react";
import { teamsData } from "constants/AllTeams";
import { useParams } from "react-router-dom";
import Container from "../Container";
import SearchContainer from "components/ui/molecules/SearchContainer";
import TeamsCardsContainer from "components/ui/molecules/TeamsCardsContainer";
import SharedBanner from "components/ui/atoms/SharedBanner";

function SubTeams() {
  const [search, setSearch] = useState("");
  const teamsKeys = ["name", "members"];
  let params = useParams();

  const searchResult = data => {
    return data.filter(team =>
      teamsKeys.some(key =>
        team[key]
          .toString()
          .toLowerCase()
          .includes(search.toLocaleLowerCase())
      )
    );
  };

  return (
    <>
      <SharedBanner
        title="Team"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      />
      <Container extraClassName="teamsContainer">
        <SearchContainer
          subTeam={teamsData[params.teamid].name}
          setSearch={setSearch}
        />
        <TeamsCardsContainer
          invite={true}
          searchData={searchResult(teamsData[params.teamid].subgroup)}
        />
      </Container>
    </>
  );
}

export default SubTeams;
