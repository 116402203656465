import React from "react";
import styles from "./style.module.scss";
import PropTypes from "prop-types";
import QuestionsCard from "../../atoms/QuestionCards";
import { Questions } from "constants/Questions";
import TwoButtons from "../../atoms/TwoButtons";

function InterviewSetup2() {

    return (
        <div className={styles.interviewSetup2} >
            <h1>Make Questions</h1>
            <div className={styles.questionContainer}>
                {Questions.map((question, i) => {
                    return (
                        <QuestionsCard
                            key={i}
                            index={i}
                            {...question}
                        />
                    );
                })}
                <QuestionsCard description="Add your question" add />
            </div>
            <div className={styles.buttons}>
                <TwoButtons firsttext="Back" secondtext="Save" extraClass="lightGrayBtn" />
            </div>
        </div>
    );
}

export default InterviewSetup2;
InterviewSetup2.propTypes = {
    searchData: PropTypes.array,
    setOpenDashboard: PropTypes.func,
    invite: PropTypes.bool,
};
