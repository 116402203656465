/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
import { ReactComponent as Logo } from "assets/images/videoDashboardLogo.svg";
// import profile from "assets/images/profile-picture.webp";
import SharedButton from "../SharedButton";
import PropTypes from "prop-types";
import useGetAuthenticatedUser from "components/ui/molecules/Authentication/useHooks/useGetAuthenticatedUser";
import Avatar from "../Avatar";
import { useDispatch } from "react-redux";
import { logoutUser } from "redux/actions/authenticationActions";
function TopNavigation ({ title, interview }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useGetAuthenticatedUser();
  const [dropdown, setDropdown] = useState(false);
  if (user?.author) {
    localStorage.setItem("activeUser" ,user?.author);
  }

  return (
    <div className={styles.topNavigation}>
      {interview === true ? (
        <>
          <div className={styles.logoTitle}>
            <div className={styles.logo}>
              <Logo />
              <h2>
                VIDEO <span>DASHBOARD</span>
              </h2>
            </div>
            <p>
              Interview - <span>{title}</span>
            </p>
          </div>
          <div className={styles.info}>
            <SharedButton children='Sign Up' />
          </div>
        </>
      ) : (
        <>
          <div className={styles.logo} onClick={() => navigate("/")}>
            <Logo />
            <h2>
              VIDEO <span>DASHBOARD</span>
            </h2>
          </div>
          <div className={styles.info}>
            <div className={styles.plan}>
              <div onClick={() => setDropdown(!dropdown)}>
                <Avatar name={user?.author} image={null} />
              </div>
            </div>
            {dropdown && (
              <div className={styles.dropdown}>
                <SharedButton
                  clickEvent={() => dispatch(logoutUser())}
                  children='Log Out'
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default TopNavigation;
TopNavigation.propTypes = {
  title: PropTypes.string,
  interview: PropTypes.bool,
};
