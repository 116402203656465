import React, { useState } from "react";
import styles from "./style.module.scss";
import { ReactComponent as First } from "../../../../assets/images/first.svg";
import { ReactComponent as Second } from "../../../../assets/images/second.svg";
import { ReactComponent as Third } from "../../../../assets/images/third.svg";
import VideoContent from "../VideoContent";
import Input from "components/ui/atoms/Inputs/Input";
import InterviewSetup2 from "../InterviewSetup2";
import InterviewSetup3 from "../InterviewSetup3";

export default function InterviewSteps() {
  const [index, setIndex] = useState(0);
  const listitems = [
    "Welcome Greeting",
    "About 50+",
    "Videos made simple",
    "Why Show Rooms",
  ];
  return (
    <div className={styles.main}>
      <div className={styles.steps}>
        <button
          type="button"
          className={index === index ? styles.active : ``}
          onClick={() => setIndex(0)}
        >
          <First className={styles.icon} />
          Set up
        </button>
        <button
          type="button"
          className={index != 0 ? styles.active : ``}
          onClick={() => setIndex(1)}
        >
          <Second className={styles.icon} />
          Questions
        </button>
        <button
          type="button"
          className={index === 2 ? styles.active : ``}
          onClick={() => setIndex(2)}
        >
          <Third className={styles.icon} />
          Share
        </button>
      </div>
      {index == 0 && (
        <div className={styles.form}>
          <div className={styles.formHolder}>
            <h2>Fill the form!</h2>
            <label htmlFor="interview">Interview title</label>
            <Input placeholder="Sales Manager" id="interview" />
            <label htmlFor="company">Company</label>
            <Input placeholder="Master WiZR" id="company" />
            <div className={styles.videointro}>
              <div className={styles.switch}>
                <span>Record my own</span>
                <div className={styles.switchbtn}>
                  <div className={styles.circle}></div>
                </div>
              </div>
              <label htmlFor="video">Video Intro</label>
            </div>
            <Input placeholder="URL video link" id="video" />
            <label htmlFor="description">Description</label>
            <textarea
              placeholder="Description of job application"
              rows="5"
            ></textarea>
          </div>
          <VideoContent
            extraClass="unorderedList"
            unorderedList
            list={listitems}
            extraClass1="withoutBanner1"
            listTitle="Record a personal video intro"
            subtitle="Opportunity to explain the next steps"
          />
        </div>
      )}
      {index == 1 && (
        <div>
          <InterviewSetup2/>
        </div>
      )}
      {index == 2 && (
        <div>
          {/* <VideoContent
            list={listitems}
            extraClass1="withoutBanner"
            listTitle="Video Tutorials"
          /> */}
          <InterviewSetup3/>
        </div>
      )}
    </div>
  );
}
