import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getSingleHostMeetingsRecordings } from "redux/actions/meetingRepositoryActions";
import WizrVideoPlayer from "../../WizrPlayer/WizrPlayer";
import { TailSpin } from "react-loader-spinner";
import BackButton from "assets/icons/BackButton";

function VideoPlayerContainer ({ extraClass }) {
  const { id } = useParams();
  const { singleHostMeetingRecordingStatus, singleHostMeetingRecordings } =
    useSelector(state => state.meetingRepository);

  const dispatch = useDispatch();

  const {
    user: { email },
  } = useSelector(_state => _state.authReducer);

  useEffect(() => {
    dispatch(getSingleHostMeetingsRecordings(id, email));
  }, []);

  const navigate = useNavigate();

  return (
    <div className={`${styles.container} ${styles[extraClass]}`}>
      {singleHostMeetingRecordingStatus ? (
        <div className={styles.spinner}>
          <TailSpin />
        </div>
      ) : (
        <div>
          <button
          // disabled={parent === 0}
          className={styles.backButton}
          onClick={() => navigate(-1)}
        >
          <BackButton />
          <p>Go back</p>
        </button>
          <h2 className={styles.title}>{id}</h2>
        <WizrVideoPlayer src={singleHostMeetingRecordings?.location} />
        </div>
      )}
    </div>
  );
}

export default VideoPlayerContainer;
VideoPlayerContainer.propTypes = {
  extraClass: PropTypes.string,
  route: PropTypes.any,
  videoItem: PropTypes.object,
  comments: PropTypes.bool,
  speedContainer: PropTypes.bool,
  sharing: PropTypes.bool,
  id: PropTypes.string,
};
