import React, { useState } from "react";
import { useSelector } from "react-redux";
import TwoButtons from "components/ui/atoms/TwoButtons";
import Popup from "../../atoms/Popup";
import PopupButton from "../../atoms/Popup/PopupButton";
import PropTypes from "prop-types";

function RenamePopup ({
  title,
  color,
  text,
  firsttext = "Yes",
  secondtext = "No",
  handleFunction,
  id,
  loading,
  previousTitle,
}) {
  const [popup, setPopup] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const allVideos = useSelector(state => state?.video?.videos?.data);
  const {
    data: { videos: allFavouriteVideos },
  } = useSelector(state => state.video.favouriteVideos);
  console.log(allFavouriteVideos, "allFavouriteVideos--32-302392039");
  const allArchivedVideos = useSelector(
    state => state.video.archivedVideos.data
  );

  const extractTitles = videos => videos?.map(v => v.title) || [];

  const allTitles = [
    ...extractTitles(allVideos),
    ...extractTitles(allFavouriteVideos),
    ...extractTitles(allArchivedVideos),
  ];

  const hidepopup = () => {
    setPopup(false);
  };
  const openpopup = () => {
    setPopup(true);
  };

  return (
    <>
      <PopupButton title={title} extraClass={color} openPopup={openpopup} />
      <Popup removePopup={hidepopup} title={title} state={popup}>
        {text ? (
          <p>{text}</p>
        ) : (
          <>
            <h4>Title</h4>{" "}
            <input
              type='text'
              placeholder={previousTitle}
              onChange={e => setNewTitle(e.target.value)}
            />
          </>
        )}

        {allTitles.includes(newTitle) ? (
          <p style={{ color: "#ec7600" }}>
            A video with this name already exists
          </p>
        ) : (
          <TwoButtons
            handleFunction={() => handleFunction(id, newTitle, hidepopup)}
            handleCancel={hidepopup}
            firsttext={firsttext}
            secondtext={!loading ? secondtext : "Submitting..."}
            extraClass='grayBtn'
            disabled={loading}
          />
        )}
      </Popup>
    </>
  );
}

RenamePopup.propTypes = {
  handleFunction: PropTypes.func,
  title: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
  firsttext: PropTypes.string,
  secondtext: PropTypes.string,
  id: PropTypes.any,
  loading: PropTypes.bool,
  previousTitle: PropTypes.string,
};

export default RenamePopup;
