/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import Items from "../Items";
import ArchivedItems from "../Items/ArchivedItems";
import FavouriteItems from "../Items/FavouriteItems";
import SharedWithMeItems from "../Items/SharedWithMeItems";
import styles from "./style.module.scss";
// import profile from "assets/images/profile-picture.webp";
import { ReactComponent as GridLogo } from "../../../../assets/images/grid4.svg";
import { ReactComponent as RowLogo } from "../../../../assets/images/list.svg";
import Avatar from "components/ui/atoms/Avatar";
import useGetAuthenticatedUser from "../Authentication/useHooks/useGetAuthenticatedUser";
import MeetingList from "components/ui/organisms/MeetingList/MeetingList";
import SearchInput from "components/ui/atoms/SearchInput/SearchInput";
import { setSearchText } from "redux/actions/actionCreators/videoActionCreators";
import Polls from "components/ui/organisms/Polls/Polls";
import PollsList from "components/ui/organisms/PollDetails/PollsDetails";

export const ITEMS_KEYS = {
  PERSONAL_LIBRARY: "personal-library",
  MY_ARCHIVES: "my-archives",
  MY_FAVOURIES: "my-favourites",
  SHARED_WITH_ME: "shared-with-me",
  MEETINGS: "meetings",
  POLLS: "polls",
  POLLS_DETAIL_PAGE: "pollsDetails",
};
function Container ({ title, viewType }) {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch({ type: "CLEAR_SEARCH_TEXT" });
    };
  }, [dispatch]);

  const preferredViewType = localStorage.getItem("preferredViewType"); 

  const [type, setType] = useState(preferredViewType || "row");

  const handleSearch = event => {
    dispatch(setSearchText(event.target.value));
  };

  // const user = useGetAuthenticatedUser();

  const TitleSection = () => (
    <div className={styles.title}>
      {/* <Avatar name={user?.author} image={null} /> */}
      <h1>{title}</h1>
    </div>
  );

  const ButtonWithLogo = ({
    currentType,
    setTypeFunc,
    typeValue,
    LogoComponent,
  }) => (
    <button
      className={currentType === typeValue ? styles.active : ""}
      onClick={() => {
        setTypeFunc(typeValue);
        localStorage.setItem("preferredViewType", typeValue);
      }}
    >
      <LogoComponent />
    </button>
  );

  const Heading = () => {
    const { searchText } = useSelector(state => state.SearchReducer);
    return (
      <div className={styles.heading}>
        <TitleSection />
        <div className={styles.buttons}>
          <div className={styles.leftBtnGroup}>
            <div className={styles.searchInput}>
              <SearchInput onChange={handleSearch} value={searchText} />
            </div>
            <ButtonWithLogo
              currentType={type}
              setTypeFunc={setType}
              typeValue='grid'
              LogoComponent={GridLogo}
            />
            <ButtonWithLogo
              currentType={type}
              setTypeFunc={setType}
              typeValue='row'
              LogoComponent={RowLogo}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {viewType !== ITEMS_KEYS.POLLS_DETAIL_PAGE ? <Heading /> : null}
        {viewType === ITEMS_KEYS.PERSONAL_LIBRARY && <Items type={type} />}
        {viewType === ITEMS_KEYS.MY_FAVOURIES && <FavouriteItems type={type} />}
        {viewType === ITEMS_KEYS.MY_ARCHIVES && <ArchivedItems type={type} />}
        {viewType === ITEMS_KEYS.SHARED_WITH_ME && (
          <SharedWithMeItems type={type} />
        )}
        {viewType === ITEMS_KEYS.MEETINGS && <MeetingList type={type} />}
        {viewType === ITEMS_KEYS.POLLS && <Polls type={type} />}
        {viewType === ITEMS_KEYS.POLLS_DETAIL_PAGE && <PollsList type={type} />}
      </div>
    </div>
  );
}

export default Container;
Container.propTypes = {
  title: PropTypes.string,
  image: PropTypes.bool,
  list: PropTypes.array,
  viewType: PropTypes.string,
};
