import React, { useState } from "react";
import Popup from "../../atoms/Popup";
import styles from "./style.module.scss";
import TwoButtons from "components/ui/atoms/TwoButtons";
import PopupButton from "../../atoms/Popup/PopupButton";
import PropTypes from "prop-types";
import Loader from "components/ui/atoms/Loader";

function DownloadPopup ({
  color,
  firsttext,
  secondtext,
  loading,
  handleFunction,
  size
}) {
  const [popup, setPopup] = useState(false);

  const hidePopup = () => {
    setPopup(false);
  };
  const openPopup = () => {
    setPopup(true);
  };
  return (
    <>
      <PopupButton title='Download' openPopup={openPopup} extraClass={color} />
      <Popup removePopup={hidePopup} title='Download' state={popup}>
        <div className={styles.originalSize}>
          <div>
            <h4>Original size</h4>
            <div>
              <p>{size}</p>
            </div>
          </div>
          <TwoButtons
            firsttext={firsttext}
            secondtext={secondtext}
            loading={loading}
            handleFunction={handleFunction}
            handleCancel={hidePopup}
          />
        </div>
      </Popup>
    </>
  );
}

DownloadPopup.propTypes = {
  color: PropTypes.string,
  firsttext: PropTypes.string,
  secondtext: PropTypes.string,
  loading: PropTypes.string,
  handleFunction: PropTypes.func,
  size: PropTypes.string,
};

export default DownloadPopup;
