import axiosInstance from "../../utils/axiosInstance";
import { successToast, errorToast } from "../../components/ui/atoms/Toasts/toasts";

import {
    getAllCommentsError, createComment, createCommentError,
    deleteComment, deleteCommentError, getEditedCommentError, getEditedComment
} from "./actionCreators/commentActionCreators";
import dispatchGetAllComments from "./utils/functions/dispatchGetAllComments";
import * as actions from "./actionCreators/videoActionCreators";

export const startCreateComment = (newComment) => {
    return async (dispatch) => {
        try {
            const url = `/api/v1/comment/createComment`;
            const { data } = await axiosInstance.post(url, newComment);
            dispatch(createComment(data.data.comment));
            return successToast(data.message);
        } catch (error) {
            if (error.response) {
                dispatch(createCommentError(error.response.data.message));
                return errorToast(error.response.data.response);
            }
        }
    };
};

export const startGetAllComments = (id, limit = 10, page = 0) => {
    return async (dispatch) => {
        try {
            const url = `/api/v1/comment/getComments/video/${id}?limit=${limit}&page=${page}`;
            const { data } = await axiosInstance.get(url);
            dispatch(actions.getPaginationData(data));
            return dispatchGetAllComments(dispatch, data);
        } catch (error) {
            if (error.response) {
                dispatch(getAllCommentsError(error.response.data.message));
            }
        }
    };
};


export const startEditComment = (id, editedComment) => {
    return async (dispatch) => {
        try {
            const url = `/api/v1/comment/editComment/${id}`;
            const { data } = await axiosInstance.put(url, editedComment);
            dispatch(getEditedComment(data.data.comment));
            return successToast("The comment has been successfully edited.");
        } catch (error) {
            if (error.response) {
                dispatch(getEditedCommentError(error.response.data.message));
                return errorToast("An error has occurred! The comment was not edited.");
            }
        }
    };
};

export const startDeleteComment = (id) => {
    return async (dispatch) => {
        try {
            const url = `/api/v1/comment/deleteComment/${id}`;
            const { data } =  await axiosInstance.delete(url);
            dispatch(deleteComment(data?.data?.comment));
            return successToast("The comment has been successfully deleted!");
        } catch (error) {
            if (error.response) {
                dispatch(deleteCommentError(error.response.data.message));
                return errorToast("An error has occurred! The comment was not deleted.");
            }
        }
    };
};
