import * as React from "react";
import PropTypes from "prop-types";

const SvgComponent = props => (
  <svg
    width={props.width}
    height={props.height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.182 3.228a2.733 2.733 0 0 0-4.005 0l-.176.187-.177-.187a2.737 2.737 0 0 0-4.006 0 3.067 3.067 0 0 0 0 4.17l3.923 4.138a.357.357 0 0 0 .505.013l.014-.013 3.922-4.137a3.067 3.067 0 0 0 0-4.17Z"
      fill={props.color}
    />
  </svg>
);

SvgComponent.defaultProps = {
    color: "#C1C1C1",
    width: 14,
    height: 14,
};

SvgComponent.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    style: PropTypes.object
};

export default SvgComponent;
