import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import styles from "./styles.module.scss";
import TableHead from "../../atoms/MeetingListTableHead/MeetingListTableHead";
import TableRow from "../../atoms/MeetingListTableRow/MeetingListTableRow";

const TableView = ({
  // data,
  filterHandler,
  list,
  pathHandler,
  moveToFolder,
  meetingList,
  loadingMeetingList,
  dropDown
}) => {
  const [activeMenu, setActiveMenu] = useState("");

  const closeAnyMenu = () => {
    setActiveMenu(false);
  };

  useEffect(() => {
    document.body.addEventListener("click", closeAnyMenu);
    document.body.addEventListener("contextmenu", closeAnyMenu);

    return () => {
      document.body.removeEventListener("click", closeAnyMenu);
      document.body.removeEventListener("contextmenu", closeAnyMenu);
    };
  }, []);

  const meetingsListMap = () => {
    return meetingList.map(meeting => {
      console.log(meeting, "meeting data twp first");
      return <Link to= {`/meeting-repository/hostMeetings/sessions/${meeting.roomId}`}>
        <TableRow
          {...{ title: meeting.roomId, type: "Folder" }}
          pathHandler={pathHandler}
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
        />
      </Link>;
    });
  };

  return (
    <table className={styles.tableContainer}>
      <TableHead handler={filterHandler} />
      { !loadingMeetingList && meetingList &&  meetingList.length ? (
        <div className={styles.recordingsRow}>
         {meetingsListMap()}
        </div>
      ) : null}

      <DragDropContext onDragEnd={result => moveToFolder(result)}>
        {list.map((item, index) => {
          return (
            <Droppable
              key={item._id}
              droppableId={item._id}
            >
              {(provided, snapshot) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...(item.type === "folder"
                      ? { ...provided.droppableProps }
                      : {})}
                    style={{
                      ...(item.type === "folder" && {
                        padding: 4,
                        margin: 5,
                        opacity: snapshot.isDraggingOver ? 0.5 : 1,
                        border: snapshot.isDraggingOver
                          ? "1px solid gray"
                          : "none",
                      }),
                    }}
                  >
                    <Draggable
                      index={index}
                      key={item._id}
                      draggableId={item._id}
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...(item.type === "file"
                              ? {
                                  ...provided.dragHandleProps,
                                  ...provided.draggableProps,
                                }
                              : {})}
                            style={{
                              userSelect: "none",
                              ...(item.type === "file" && {
                                ...provided.draggableProps.style,
                              }),
                            }}
                          >
                            <TableRow
                              {...item}
                              pathHandler={pathHandler}
                              activeMenu={activeMenu}
                              setActiveMenu={setActiveMenu}
                              dropDown={dropDown}
                            />
                          </div>
                        );
                      }}
                    </Draggable>
                  </div>
                );
              }}
            </Droppable>
          );
        })}
      </DragDropContext>
    </table>
  );
};

export default TableView;
TableView.propTypes = {
  filterHandler: PropTypes.func,
  list: PropTypes.array,
  pathHandler: PropTypes.func,
  moveToFolder: PropTypes.func,
  recordingsList: PropTypes.array,
  loadingHostRecordings: PropTypes.bool,
  dropDown: PropTypes.bool,
  showRecordings: PropTypes.bool,
  meetingList: PropTypes.array,
  loadingMeetingList: PropTypes.bool
};

TableView.defaultProps = {
  showRecordings: false,
  loadingHostRecordings: false
};