import RightMenu from "components/ui/atoms/RightMenu";
import Container, {ITEMS_KEYS} from "components/ui/molecules/Container";
import { homeMenu } from "constants/homeMenu";
import { personalLibraryData } from "constants/personalLibraryData";
import SharedBanner from "components/ui/atoms/SharedBanner";

function SharedWithMe() {
  return (
    <>
    <SharedBanner
        title="Shared with me"
        description="All your files that are shared with you will be saved here."
        extraClass="shareWithMeBanner"
      />
      <Container
        title="Shared with me"
        image
        list={personalLibraryData}
        viewType={ITEMS_KEYS.SHARED_WITH_ME}
      />
      <RightMenu list={homeMenu} buttons />
    </>
  );
}

export default SharedWithMe;
