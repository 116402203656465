import React from "react";

export default function index({width, height, color}) {
  return (
    <svg
      width={width || "21"}
      height={height || "20"}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5003 3.95621C11.6954 3.95621 12.8637 4.31061 13.8574 4.97457C14.8511 5.63854 15.6256 6.58222 16.083 7.68636C16.5404 8.79051 16.6601 10.0055 16.427 11.1777C16.1939 12.3498 15.6185 13.4266 14.7735 14.2717C13.9284 15.1169 12.8517 15.6924 11.6796 15.9257C10.5075 16.1589 9.2925 16.0394 8.1883 15.5821C7.08411 15.1249 6.14023 14.3504 5.47615 13.3568C4.81206 12.3632 4.45751 11.1949 4.45737 9.99978C4.45916 8.39759 5.09637 6.86155 6.22922 5.72856C7.36206 4.59558 8.89811 3.9582 10.5003 3.95621V3.95621ZM10.5003 2.85693C9.08757 2.85693 7.70655 3.27588 6.53191 4.06075C5.35728 4.84561 4.44179 5.96119 3.90117 7.26637C3.36054 8.57155 3.21906 10.0077 3.49466 11.3933C3.77027 12.7789 4.45058 14.0516 5.44953 15.0505C6.44847 16.0495 7.72119 16.7298 9.10677 17.0054C10.4923 17.281 11.9285 17.1395 13.2337 16.5989C14.5388 16.0583 15.6545 15.1428 16.4393 13.9682C17.2242 12.7935 17.6431 11.4125 17.6431 9.99978C17.6431 8.10538 16.8905 6.2886 15.551 4.94906C14.2114 3.60952 12.3947 2.85693 10.5003 2.85693V2.85693Z"
        fill={color || "white"}
      />
      <path
        d="M10.5005 13.9428C12.678 13.9428 14.4433 12.1776 14.4433 9.99998C14.4433 7.8224 12.678 6.05713 10.5005 6.05713C8.32289 6.05713 6.55762 7.8224 6.55762 9.99998C6.55762 12.1776 8.32289 13.9428 10.5005 13.9428Z"
        fill={color || "white"}
      />
    </svg>
  );
}
