import { useParams, useNavigate } from "react-router-dom";
import Chats from "components/ui/molecules/Chats/Chats";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import {getMeetingChats} from "redux/actions/meetingRepositoryActions";
import Loader from "components/ui/atoms/Loader";
import BackButton from "assets/icons/BackButton";

const MeetingFiles = ({ containerPadding = true }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    meetingChats,
    loadingMeetingChats
  } = useSelector(state => state.meetingRepository);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMeetingChats(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !loadingMeetingChats ? (
    <React.Fragment>
      <button
          // disabled={parent === 0}
          className={styles.backButton}
          onClick={() => navigate(`/meeting-repository/hostMeetings/data/${id}`)}
        >
          <BackButton />
          <p>Go back</p>
        </button>
    <div
      className={`${styles.container} ${containerPadding && styles.containerPadding
        }`}
    >
      <Chats chatHistory={meetingChats || []} />
      <div className={styles.infoMeeting}>
        <h1>Chat</h1>
        <span>Everytime you join the meeting and chat it will be showing here. <br /> <br /> Pay attention: the chats will be saved for one week only. </span>
      </div>
    </div>
    </React.Fragment>
  ) : (
    <div className={styles.loader}>
      <Loader />
    </div>
  );
};

MeetingFiles.propTypes = {
  containerPadding: PropTypes.bool,
};

export default MeetingFiles;
