import React, { useState } from "react";
import Popup from "../../atoms/Popup";
import TwoButtons from "components/ui/atoms/TwoButtons";
import PopupButton from "../../atoms/Popup/PopupButton";
import PropTypes from "prop-types";

function ArchivePopup({ handleFunction, loading }) {
  const [popup, setPopup] = useState(false);

  const hidepopup = () => {
    setPopup(false);
  };
  const openpopup = () => {
    setPopup(true);
  };
  return (
    <>
      <PopupButton
        // extraClass="grayBtn1"
        title="Archive"
        openPopup={openpopup}
      />
      <Popup removePopup={hidepopup} title="Archive" state={popup}>
        <p>
          When you click to Archive option, you immediately save this video to
          your Archive folder.
        </p>
        <TwoButtons
          firsttext="Cancel"
          secondtext={loading ? "Archiving..." : "Archive"}
          extraClass="grayBtn"
          handleCancel={hidepopup}
          handleFunction={handleFunction}
          loading={loading}
        />
      </Popup>
    </>
  );
}

ArchivePopup.propTypes = {
  handleFunction: PropTypes.func,
  loading: PropTypes.bool,
};

export default ArchivePopup;
