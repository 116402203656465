import axiosInstance from "utils/axiosInstance";
import {
  recordingInit,
  recordingSuccess,
  recordingError,
} from "./actionCreators/recordingActionCreators";
import { getVideos } from "../actions/videoActions";

export const saveRecording = (data, callBack) => async dispatch => {
  dispatch(recordingInit());
  try {
    const response = await axiosInstance.post(`/api/v1/video`, data, {headers: { "Content-Type": "multipart/form-data" }});
    dispatch(recordingSuccess(response.data.data.video));
    callBack && callBack();
    // get videos
    dispatch(getVideos({limit: 10}));
  } catch (error) {
    dispatch(recordingError(error));
  }
};
