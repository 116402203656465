/* eslint-disable react/prop-types */
import React, { useState } from "react";
import styles from "./TimeInput.module.scss";

function TimeInput({ hour, setHour, minute, setMinute, second, setSecond}) {

  const handleHourChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 0 && value <= 10) {
      setHour(value);
    } else {
      setHour(10); // Set to 10 if value is greater than 10
    }
  };

  const handleMinuteChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 0 && value < 60) {
      // Check if the total duration exceeds 10 hours
      const totalDuration = value / 60 + hour;
      if (totalDuration <= 10) {
        setMinute(value);
      } else {
        // If total duration exceeds 10 hours, set minute to the remaining duration
        setMinute(Math.floor((10 - hour) * 60));
      }
    } else {
      setMinute(0); // Set to 0 if value is invalid
    }
  };

  const handleSecondChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 0 && value < 60) {
      setSecond(value);
    } else {
      setSecond(0); // Set to 0 if value is invalid
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles["time-input"]}>
        <input
          type="number"
          value={hour}
          onChange={handleHourChange}
          placeholder="hh"
          maxLength="2"
        />
        :
        <input
          type="number"
          value={minute}
          onChange={handleMinuteChange}
          placeholder="mm"
          maxLength="2"
        />
        :
        <input
          type="number"
          value={second}
          onChange={handleSecondChange}
          placeholder="ss"
          maxLength="2"
        />
      </div>
    </div>
  );
}

export default TimeInput;
