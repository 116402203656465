import { GET_POLLS, GET_POLL } from "../actionTypes";

export const getPollsInit = () => ({
  type: GET_POLLS.INIT,
});

export const getPollsSuccess = payload => ({
  type: GET_POLLS.SUCCESS,
  payload,
});

export const getPollsError = error => ({
  type: GET_POLLS.ERROR,
  error,
});

export const getPollInit = () => ({
  type: GET_POLL.INIT,
});

export const getPollSuccess = payload => ({
  type: GET_POLL.SUCCESS,
  payload,
});

export const getPollError = error => ({
  type: GET_POLL.ERROR,
  error,
});
