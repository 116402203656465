import React from "react";
import styles from "./style.module.scss";

export default function index() {
  return (
    <div className={styles.ContanctContainer}>
      ContanctContainer
    </div>
  );
}
