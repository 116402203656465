import React, { useRef } from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
// import { ReactComponent as Favourite } from "assets/images/favourite.svg";
import FavouriteIcon from "assets/icons/Favourite";
import ArchiveIcon from "assets/icons/ArchiveIcon";
import { ReactComponent as Folder } from "../../../../assets/images/folder.svg";
import image from "../../../../assets/images/video-card-1.webp";
import useClickOutside from "components/helpers/useClickOutside";
import DropDownMenu from "../DropDownMenu";
import altImage from "assets/images/videointerview.jpg";

function LibraryCard({
  roomId,
  pollTopic,
  title,
  _id,
  id,
  createdAt,
  size,
  type,
  pathHandler,
  favourite,
  archived,
  activeMenu,
  setActiveMenu,
  dropDown
}) {
  // const [fav, setFav] = useState(false);

  const openMenu = e => {
    e.stopPropagation();
    e.preventDefault();
    setActiveMenu(_id === activeMenu ? false : _id);
  };
  const teamRef = useRef(null);
  // useClickOutside(teamRef, () => setActiveMenu(false));


  return (
    <div className={styles.videoCard}>
      <div
        className={styles.imagePart}
        onClick={() => pathHandler(id, type)}
        onKeyDown={() => pathHandler(id, type)}
        role="button"
        tabIndex={0}
      >
        {type === "file" ? (
          <img src={image} alt="library" onError={(e) => {e.target.src = altImage}} />
        ) : (
          <Folder style={{ width: "80%", height: "150px" }} />
        )}
        <div className={styles.bottom}>
          <div>{favourite && <FavouriteIcon color="red" />}</div>
          <div>{archived && <ArchiveIcon />}</div>
          {/* <div>
            <p>{size} mb</p>
          </div> */}
        </div>
      </div>
      <div className={styles.heading} ref={teamRef}>
        <span
          onClick={() => pathHandler(id, type)}
          onKeyDown={() => pathHandler(id, type)}
          role="button"
          tabIndex={0}
        >
          {pollTopic}
        </span>
      </div>
      <p className={styles.dateParagraph}>{createdAt ? new Date(createdAt).toLocaleDateString(): ""}</p>

      {dropDown && _id === activeMenu && (
        <DropDownMenu
        id={_id}
        activeMenu={activeMenu}
        setActiveMenu={setActiveMenu}
        />
      )}
    </div>
  );
}
export default LibraryCard;
LibraryCard.propTypes = {
  title: PropTypes.string,
  roomId: PropTypes.string,
  _id: PropTypes.number,
  createdAt: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  dropdownItem: PropTypes.number,
  dropdown: PropTypes.func,
  listIndex: PropTypes.number,
  pathHandler: PropTypes.func,
  pollTopic: PropTypes.string,
  favourite: PropTypes.bool,
  archived: PropTypes.bool,
  activeMenu: PropTypes.any,
  setActiveMenu: PropTypes.func,
  dropDown: PropTypes.bool,
  id: PropTypes.string,
};
