import React from "react";
import styles from "./style.module.scss";
import PropTypes from "prop-types";

function Container({children, extraClassName}) {
  return <div className={`${styles.container} ${styles[extraClassName]}`}>
    <div className={styles.center}>
      {children}
    </div>
  </div>;
}

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  extraClassName:PropTypes.string
};

export default Container;
