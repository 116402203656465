import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import GridView from "../GridView";
import TableView from "../TableView";
import { useNavigate } from "react-router-dom";
import { getArchivedVideos } from "../../../../redux/actions/videoActions";
import Loader from "../../atoms/Loader";
import NothingToDisplay from "../../atoms/NothingToDisplay";
import Pagination from "components/ui/atoms/Paginate/Index";
import {selectArchivedFilteredVideos} from "redux/selectors";

const Items = ({ type }) => {

  const { isFetching } = useSelector(state => state?.video);
  const {
    data,
    totalPages,
    page,
    perPage,
    totalArchivedVideos
  } = useSelector(selectArchivedFilteredVideos);

  const [_data, setData] = useState(null);
  const [filterArray, setFilterArray] = useState([]);
  const [asc, setAsc] = useState(false);
  const [parent, setParent] = useState(0);
  const [history, setHistory] = useState([0]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getArchivedVideos(perPage, page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page]);

  const filterParentData = tableData => {
    let filter = tableData.map(item => ({...item, parent: 0}))
      .filter(a => {
        return a.parent === parent;
      })
      .sort((a, b) => {
        const isReversed = -1;
        return isReversed * a.type.localeCompare(b.type);
      });
    setFilterArray(filter);
  };

  useEffect(() => {
    if (!isFetching && data.length) {
      filterParentData(data);
    }
  }, [parent, data]);

  const sortAtoZ = type => {
    let sortedArray = [...filterArray];
    setAsc(!asc);
    sortedArray.sort((a, b) => {
      const isReversed = asc ? -1 : 1;
      return isReversed * a[type].localeCompare(b[type]);
    });
    setFilterArray(sortedArray);
  };

  const filterHandler = type => {
    switch (type) {
      case "title":
      case "createdAt":
      case "author":
        sortAtoZ(type);
        break;
      case "size":
        let sizeArray = [...filterArray];
        setAsc(!asc);
        sizeArray.sort((a, b) => {
          if (a.size > b.size) return asc ? -1 : 1;
          if (a.size < b.size) return asc ? 1 : -1;
          return 0;
        });
        setFilterArray(sizeArray);
        break;
      default:
        return filterArray;
    }
  };

  const setPathFromChild = (index, type) => {
    if (type === "folder") {
      setParent(index);
      let historyArray = [...history, index];
      setHistory(historyArray);
    } else {
      navigate(`/video-player/${index}`);
    }
  };

  const handleMoveToFolder = result => {
    const newArray = [..._data];

    const arrayToSave = newArray.map((item) => {
      if (item.index === parseInt(result.draggableId)) {
        return {
          ...item,
          parent: parseInt(result.destination.droppableId),
        };
      } else {
        return item;
      }
    });

    setData(arrayToSave);

    filterParentData(arrayToSave);
  };

  return (
    <Fragment>
      {isFetching ? <Loader /> : !isFetching && (
        data.length > 0 ?
          <>
            {type === "row" ? (<TableView
                filterHandler={filterHandler}
                list={filterArray}
                pathHandler={setPathFromChild}
                moveToFolder={handleMoveToFolder}
                dropDown={true}
                duplicateLocation="archivedVideos"
                disabledItems={["archives"]}
              />
            ) : (
              <GridView list={filterArray} pathHandler={setPathFromChild} dropDown={true} duplicateLocation="archivedVideos" />
            )}

            <Pagination currentPage={page} itemsPerPage={perPage} onPageChange={(page) => dispatch(getArchivedVideos(perPage, page))} totalItems={totalArchivedVideos} totalPages={totalPages} data={data} />

          </> : <NothingToDisplay />
      )}

    </Fragment>
  );
};

export default Items;
Items.propTypes = {
  type: PropTypes.string,
};
