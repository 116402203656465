import styles from "./style.module.scss";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { ReactComponent as CloseIcon } from "../../../../assets/images/close.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/images/info.svg";

function RightMenu({ list, bgImage, buttons, extraClass, openedMenu }) {
  const [index, setIndex] = useState(0);
  const [show, setShow] = useState(openedMenu);

  return (
    <div>
      <div
        className={styles.open}
        aria-hidden="true"
        role="button"
        onClick={() => setShow(true)}
      >
        <InfoIcon />
      </div>
      <div
        className={
          show
            ? `${styles.menuContainer}`
            : `${styles.menuContainer} ${styles.hideMenu}`
        }
      >
        <div
          aria-hidden="true"
          role="button"
          onClick={() => setShow(false)}
          className={styles.iconContainer}
        >
          <CloseIcon />
        </div>
        {list.map((menu, i) => {
          return (
            <div key={i}>
              {menu.index === index ? (
                <div
                  className={`${styles.menuContent} ${styles[menu.extraClass]}`}
                >
                  <div
                    className={`${styles.imagePart} ${styles[extraClass]}`}
                    style={{ backgroundImage: `url(${bgImage})` }}
                  />
                  <h2>{menu.Heading}</h2>
                  <p>{menu.Paragraph}</p>
                  {menu.subHeading && <h3>{menu.subHeading}</h3>}
                  {menu.subParagraph && <p>{menu.subParagraph}</p>}
                  {menu.items.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className={`${styles.menulist} ${
                          styles[item.extraClassItem]
                        }`}
                      >
                        {item.title && <h4>{item.title}</h4>}
                        <div>
                          {item.item && (
                            <div className={styles.circle}>
                              <p>{item.item}</p>
                            </div>
                          )}
                        </div>
                        {item.description && (
                          <p id={styles.desc}>{item.description}</p>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default RightMenu;

RightMenu.propTypes = {
  list: PropTypes.array,
  buttons: PropTypes.bool,
  bgImage: PropTypes.string,
  extraClass: PropTypes.string,
  openedMenu: PropTypes.bool, 
};

RightMenu.defaultProps = {
  openedMenu: false,
};
