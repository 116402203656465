import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
const BackgroundImageContext = createContext();

export const useBackgroundImage = () => useContext(BackgroundImageContext);

export const BackgroundImageProvider = ({ children, backgroundImage }) => {
  return (
    <BackgroundImageContext.Provider value={backgroundImage}>
      {children}
    </BackgroundImageContext.Provider>
  );
};

BackgroundImageProvider.propTypes = {
    children: PropTypes.node,
    backgroundImage: PropTypes.string,
  };
