import React, { useEffect, useState, useRef } from "react";

import PropTypes from "prop-types";
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import styles from "./styles.module.scss";
import TableHead from "../../atoms/TableHead";
import TableRow from "../../atoms/TableRow";

const TableView = ({
  filterHandler,
  list,
  pathHandler,
  moveToFolder,
  dropDown,
  disabledItems,
  duplicateLocation,
  showContextMenu,
}) => {
  const myRef = useRef(null);
  const [activeMenu, setActiveMenu] = useState("");

  const handleClickOutside = event => {
    if (myRef.current && !myRef.current.contains(event.target)) {
      setActiveMenu(false);
    }
};

useEffect(() => {
    // Attach the listeners to the document
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    // Cleanup the listeners on component unmount
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("touchstart", handleClickOutside);
    };
}, []);

  return (
    <table className={styles.tableContainer}>
      <TableHead handler={filterHandler} />
      {list.map(item => {
        return (
          <div
            style={{
              padding: 4,
              margin: 5,
              ...(item.type === "folder" && {
                padding: 4,
                margin: 5,
                border: "none",
              }),
            }}
          >
            <div>
              <TableRow
                {...item}
                pathHandler={pathHandler}
                activeMenu={activeMenu}
                setActiveMenu={setActiveMenu}
                dropDown={dropDown}
                disabledItems={disabledItems}
                duplicateLocation={duplicateLocation}
                myRef={myRef}
                showContextMenu={showContextMenu}
              />
            </div>
          </div>
        );
      })}
    </table>
  );
};

export default TableView;

TableRow.defaultProps = {
  showContextMenu: true,
};

TableView.propTypes = {
  filterHandler: PropTypes.func,
  list: PropTypes.array,
  pathHandler: PropTypes.func,
  moveToFolder: PropTypes.func,
  dropDown: PropTypes.bool,
  duplicateLocation: PropTypes.string,
  disabledItems: PropTypes.array,
  showContextMenu: PropTypes.bool
};
