import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";

function TextArea({ label, placeholder, required, onChange, value, disabled, resizable }) {
  return (
    <div className={styles.inputGroup}>
      <label>{label}</label>
      <textarea
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        value={value}
        disabled={disabled}
        style={{ resize: resizable ? "both" : "none" }}
      />
    </div>
  );
}
export default TextArea;
TextArea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  resizable: PropTypes.bool,
};
