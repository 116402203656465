import React, { useState } from "react";

import PropTypes from "prop-types";

import styles from "./styles.module.scss";
// import avatar from "../../../../assets/images/profile-picture.webp";
import { ReactComponent as File } from "../../../../../../../assets/images/file.svg";
import { ReactComponent as Folder } from "../../../../../../../assets/images/folder.svg";


import DropDownMenu from "components/ui/atoms/DropDownMenu";

const TableRow = ({
  title,
  roomId,
  roomIdKey,
  _id,
  type,
  pathHandler,
  activeMenu,
  setActiveMenu,
}) => {

  const [freePosition, setFreePosition] = useState();
  const handleContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveMenu(_id);
    setFreePosition({
      posX: e.target.offsetLeft
    });
  };

  const parts = 'roomIdKey.split("/")'; // Split the string by '/'
  let newRoomId = parts[parts.length - 1].replace(/^Room-/, "");

  // Remove "Room-" from the last word

  return (
    <tr className={styles.row} onClick={() => pathHandler(newRoomId, type)} onContextMenu={(e) => {handleContextMenu(e)}} >
        {_id === activeMenu && (
        <DropDownMenu
        id={_id}
        activeMenu={activeMenu}
        setActiveMenu={setActiveMenu}
        freePosition={freePosition}
        />
      )}
      <td></td>
      <td>
        <span
          className={styles.name}
          onKeyDown={() => pathHandler(_id, type)}
          role="button"
          tabIndex={0}
        >
          {type === "file" ? <File /> : <Folder />} {title}
        </span>
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  roomId: PropTypes.string,
  title: PropTypes.string,
  _id: PropTypes.string,
  type: PropTypes.string,
  pathHandler: PropTypes.func,
  activeMenu: PropTypes.string,
  setActiveMenu: PropTypes.func,
  roomIdKey: PropTypes.string,
};

export default TableRow;
