import pf1 from "../assets/images/pf1.png";
import pf2 from "../assets/images/pf2.png";
import pf3 from "../assets/images/pf3.png";
import pf4 from "../assets/images/erol2.jpg";

export const teamsData = [
  {
    name: "Video Solution Team",
    subgroup: [
      {
        name: "Front-end Developers",
        subgroup: 4,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf4,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Demo",
          },
        ],
      },
      {
        name: "Back-end Developers",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Designers Team",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Publishing Team",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Publishing Team",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Publishing Team",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Publishing Team",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Publishing Team",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
    ],
    members: [
      {
        name: "Alisa",
        img: pf1,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Arber",
        img: pf2,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Erol",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Arber",
        img: pf2,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Erol",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Rinor",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
    ],
  },
  {
    name: "Publishing Team",
    subgroup: [
      {
        name: "Front-end Developers",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Back-end Developers",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Designers Team",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Publishing Team",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
    ],
    members: [
      {
        name: "Erol",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Arber",
        img: pf2,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Erol",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Erol",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
    ],
  },
  {
    name: "Sales Team",
    subgroup: [
      {
        name: "Front-end Developers",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Back-end Developers",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Designers Team",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Publishing Team",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
    ],
    members: [
      {
        name: "Erol",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Arber",
        img: pf2,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Erol",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
    ],
  },
  {
    name: "Product Design Team",
    subgroup: [
      {
        name: "Front-end Developers",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Back-end Developers",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Designers Team",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Publishing Team",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
    ],
    members: [
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
    ],
  },
  {
    name: "Visual Design Team",
    subgroup: [
      {
        name: "Front-end Developers",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Back-end Developers",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Designers Team",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Publishing Team",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
    ],
    members: [
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
    ],
  },
  {
    name: "UX/UI Design Team",
    subgroup: [
      {
        name: "Front-end Developers",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Back-end Developers",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Designers Team",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Publishing Team",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
    ],
    members: [
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
    ],
  },
  {
    name: "Marketing Team",
    subgroup: [
      {
        name: "Front-end Developers",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Back-end Developers",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Designers Team",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Publishing Team",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
    ],
    members: [
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
    ],
  },
  {
    name: "Happy Anniversary Team",
    subgroup: [
      {
        name: "Front-end Developers",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Back-end Developers",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Designers Team",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
      {
        name: "Publishing Team",
        subgroup: 5,
        members: [
          {
            name: "Alisa",
            img: pf1,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Arber",
            img: pf2,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          {
            name: "Erol",
            img: pf3,
            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
        ],
      },
    ],
    members: [
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        name: "Seat",
        img: pf3,
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
    ],
  },
];
