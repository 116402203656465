
export const parseDateStringTitle = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    };
    const formattedDateTime = date.toLocaleString("en-US", options);
    return formattedDateTime;
};