import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18"
      fill="none"
      viewBox="0 0 17 18"
    >
      <path
        fill="#777"
        d="M15.891 2.7c-.128-.321-.385-.579-.771-.579H11.2v-.964C11.2.514 10.684 0 10.043 0H6.379c-.642 0-1.156.514-1.156 1.157v.964H1.238a.808.808 0 00-.771.579l-.45 1.221c-.065.258.064.515.257.579a.487.487 0 00.257.129h1.157l.964 12.15c.064.707.642 1.221 1.285 1.221h8.355c.707 0 1.286-.514 1.286-1.221l.963-12.15h1.157c.129 0 .193 0 .258-.065.192-.128.32-.385.192-.578L15.891 2.7zM6.444 1.286h3.534v.835H6.444v-.835z"
      ></path>
    </svg>
  );
}

export default Icon;