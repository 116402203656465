import SearchContainer from "components/ui/molecules/SearchContainer";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Container from "../Container";
import { teamsData } from "constants/AllTeams";
import MembersCardsContainer from "components/ui/molecules/MembersCardContainer";
import Dashboard from "components/ui/molecules/Dashboard";
import SharedBanner from "components/ui/atoms/SharedBanner";

function Team() {
  const [search, setSearch] = useState("");
  const [openDashboard, setOpenDashboard] = useState(false);
  const teamsKeys = ["name", "info"];
  const params = useParams();

  const searchResult = data => {
    return data.filter(member =>
      teamsKeys.some(key =>
        member[key]
          .toString()
          .toLowerCase()
          .includes(search.toLocaleLowerCase())
      )
    );
  };
  return (
    <>
      <SharedBanner
        title="Team"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        extraClass="teamsBanner"
      />
      <Container extraClassName="teamsContainer">
        <SearchContainer
          subTeam={teamsData[params.teamid].name}
          team={teamsData[params.teamid].subgroup[params.team].name}
          setSearch={setSearch}
        />
        <MembersCardsContainer
          invite={false}
          searchData={searchResult(teamsData[params.teamid].subgroup[params.team].members)}
          setOpenDashboard={setOpenDashboard}
        />
      </Container>
      {openDashboard && <Dashboard setOpenDashboard={setOpenDashboard} />}
    </>
  );
}

export default Team;
