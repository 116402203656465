import React from "react";
import PropTypes from "prop-types";

const LinkShareIcon = ({ onClick }) => {
  console.log(`react snippet works!`);

  return (
    <svg
      onClick={onClick}
      width='31'
      height='19'
      viewBox='0 0 31 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.5063 15.5591C17.3795 15.4323 17.2988 15.2479 17.2988 15.0519C17.2988 14.66 17.6158 14.343 18.0077 14.343L21.5812 14.343C22.8723 14.3545 24.0827 13.8473 25.0049 12.9251C26.8954 11.0346 26.8896 7.96252 25.0049 6.07779C24.0884 5.16136 22.8723 4.65991 21.5812 4.65991L18.0077 4.65991C17.6158 4.65991 17.2988 4.34291 17.2988 3.95098C17.2988 3.55904 17.6158 3.24204 18.0077 3.24204L21.5812 3.24204C23.2469 3.23627 24.8204 3.88758 26.0078 5.0749C28.4516 7.51872 28.4516 11.4957 26.0078 13.9395C24.8204 15.1268 23.2469 15.7781 21.5697 15.7724H17.9962C17.806 15.7666 17.6331 15.6859 17.5063 15.5591Z'
        fill='#A4A5A6'
      />
      <path
        d='M5.36782 13.9338C2.924 11.49 2.924 7.51301 5.36782 5.06919C6.55515 3.88186 8.12864 3.23056 9.80588 3.23633L13.3794 3.23633C13.7713 3.23633 14.0883 3.55333 14.0883 3.94527C14.0883 4.3372 13.7713 4.6542 13.3794 4.6542L9.80589 4.6542C8.51481 4.64268 7.30443 5.14988 6.38224 6.07208C4.49174 7.96258 4.4975 11.0346 6.38224 12.9194C7.29867 13.8358 8.51481 14.3372 9.80589 14.3372L13.3794 14.3373C13.7713 14.3372 14.0883 14.6543 14.0883 15.0462C14.0883 15.4381 13.7713 15.7551 13.3794 15.7551L9.80589 15.7551C8.12864 15.7724 6.55515 15.1211 5.36782 13.9338Z'
        fill='#A4A5A6'
      />
      <path
        d='M9.82248 10.0084C9.69568 9.88164 9.61498 9.6972 9.61498 9.50123C9.61498 9.1093 9.93199 8.7923 10.3239 8.7923L21.056 8.7923C21.4479 8.7923 21.7649 9.1093 21.7649 9.50123C21.7649 9.89317 21.4479 10.2102 21.056 10.2102L10.3239 10.2102C10.1222 10.2159 9.94928 10.1352 9.82248 10.0084Z'
        fill='#A4A5A6'
      />
    </svg>
  );
};

LinkShareIcon.propTypes = {
  onClick: PropTypes.func,
};

export default LinkShareIcon;
