import { RECORDING } from "../actions/actionTypes";

const initialState = {
  addStatus: false,
  recordings: [],
  error: null,
};

const recordingReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECORDING.INIT:
      return {
        ...state,
        addStatus: true,
      };

    case RECORDING.SUCCESS:
      return {
        ...state,
        addStatus: false,
        recordings: [action.payload, ...state.recordings],
      };

    case RECORDING.ERROR:
      return {
        ...state,
        addStatus: false,
        error: action.error,
      };

    default:
      return initialState;
  }
};

export default recordingReducer;
