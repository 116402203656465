import { useParams, useNavigate } from "react-router-dom";
import Transcripts from "components/ui/molecules/Transcripts/Transcripts";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import {getMeetingTranscripts} from "redux/actions/meetingRepositoryActions";
import Loader from "components/ui/atoms/Loader";
import BackButton from "assets/icons/BackButton";

const MeetingTranscripts = ({ containerPadding = true }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    meetingTranscripts,
    loadingMeetingTranscripts
  } = useSelector(state => state.meetingRepository);

  const {
    user: { _id: userId },
} = useSelector(state => state.authReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMeetingTranscripts(id,userId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !loadingMeetingTranscripts ? (
    <React.Fragment>
      <button
          // disabled={parent === 0}
          className={styles.backButton}
          onClick={() => navigate(`/meeting-repository/hostMeetings/data/${id}`)}
        >
          <BackButton />
          <p>Go back</p>
        </button>
    <div
      className={`${styles.container} ${containerPadding && styles.containerPadding
        }`}
    >

      <Transcripts transcriptsHistory={meetingTranscripts || []} />

      <div className={styles.infoMeeting}>
        <h1>Transcripts</h1>
        <span>Everytime you join the meeting and have transcripts it will be showing here.</span>
      </div>

    </div>
    </React.Fragment>
  ) : (
    <div className={styles.loader}>
      <Loader />
    </div>
  );
};

MeetingTranscripts.propTypes = {
  containerPadding: PropTypes.bool,
};

export default MeetingTranscripts;
